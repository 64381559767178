import { ExclamationCircleOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { masks } from "@functions/mascaras";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { useDadosTermoVistoria } from "@hooks/useDadosTermoVistoria";
import { useForms } from "@hooks/useForms";
import { useMunicipio } from "@hooks/useMunicipio";
import { useServidores } from "@hooks/useServidores";
import { IAssinante } from "@interfaces/IAssinante";
import { IMunicipio } from "@interfaces/IMunicipio";
import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { TitleTermo } from "@styles/TitleTermo";
import { transformStringToNumber } from "@utils/transformStringToNumber";
import { Button, Divider, Form, message, Modal, Radio, Result } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import confirm from "antd/lib/modal/confirm";
import fs from "file-saver";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { AssinaturaVistoria } from "../components/AssinaturaVistoria";
import { FiscalizadoVistoria } from "../components/FiscalizadoVistoria";
import { PerguntasVistoria } from "../components/PerguntasVistoria";
import { ServidorVistoria } from "../components/ServidorVistoria";
import { getPerguntasEnumeradas } from "../functions/getPerguntasEnumeradas";
import { getRespostasVistoria } from "../functions/getRespostasVistoria";
import { useActionsTermoVistoria } from "../hooks/useActionsTermoVistoria";

export function EditTermoVistoria() {
  const { numTermo } = useParams();
  const {
    submitTermo,
    validateTermo,
    atualizarTermo,
    removerTermo,
    listaTermos,
  } = useActionsTermoVistoria();
  const termoVistoria = listaTermos.find(
    (termo) => termo.numero === numTermo?.replaceAll("_", "/")
  );
  const { getMunicipioPorId } = useMunicipio();
  const { getServidorPorMatricula } = useServidores();
  const { messageSubmitFailed } = useForms();

  const { listaFormsPerguntaTermoVistoria } = useDadosTermoVistoria();

  const [formEditVistoria] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const refServidor = useRef<any>(null);
  const { cpfCnpjMask, coordMask, telefoneMask } = masks;

  const [perguntas, setPerguntas] = useState<IPerguntaTermoVistoria[]>([]);
  const [municipiosOptions, setMuncipiosOptions] = useState<IMunicipio[]>([]);
  const [isValidDocument, setIsValidDocument] = useState(true);
  const [fiscalizado, setFiscalizado] = useState<IAssinante>({
    assinatura: null,
    cpfAssinante: "",
    funcaoAssinante: "",
    nomeAssinante: "",
  });
  const [isServidorSelected, setIsServidorSelected] = useState(true);
  const [statusAssinatura, setStatusAssinatura] = useState<CheckboxValueType[]>(
    []
  );
  const [assinaturaServidor, setAssinaturaServidor] = useState("");
  const [isSavingTermo, setIsSavingTermo] = useState(false);

  const { data } = useQuery({
    initialData: listaFormsPerguntaTermoVistoria,
    queryFn: () => apiService.listagemFormularios(),
    queryKey: "FORMULARIO_PERGUNTAS_TERMO_VISTORIA",
  });

  const preencherPerguntas = (perguntas: IPerguntaTermoVistoria[]) => {
    if (!termoVistoria) return;

    perguntas.forEach((perg) => {
      if (["AMBOS", "ALTERNATIVA"].includes(perg.pergunta.tipoResposta)) {
        formEditVistoria.setFieldValue(
          `resposta_alternativa_${perg.pergunta.id}`,
          termoVistoria.respostas.filter(
            (res) => res.pergunta.id === perg.pergunta.id
          )[0].alternativa?.id
        );
      }

      termoVistoria.respostas.forEach((res) => {
        if (res.pergunta.id === perg.pergunta.id) {
          res.campos.forEach(
            (campo: { campo: string; resposta: string }, ind) => {
              formEditVistoria.setFieldValue(
                `resposta_campo_${ind}_${perg.pergunta.id}`,
                campo.resposta
              );
            }
          );
        }
      });

      if (["AMBOS", "DESCRITIVA"].includes(perg.pergunta.tipoResposta)) {
        formEditVistoria.setFieldValue(
          `resposta_descritiva_${perg.pergunta.id}`,
          termoVistoria.respostas.filter(
            (res) => res.pergunta.id === perg.pergunta.id
          )[0].descricao
        );
      }
    });
  };

  const modalConfirm = () => {
    confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        formEditVistoria.resetFields();
        navigate("/termo-vistoria/listagem");
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  const getFormSelected = (formId: number) => {
    return data?.filter((pergunta) => pergunta.id === formId)[0];
  };

  const handleSubmit = async (values: any) => {
    const keyMessage = "termo-vistoria";

    if (!termoVistoria) return;

    //FAZER VALIDACOES
    const dadosValidacao = {
      assinaturaServidor,
      dadosFiscalizado: fiscalizado,
      statusAssinatura,
    };

    if (!validateTermo(dadosValidacao)) return;

    let formValues: ITermoVistoria = {
      localArmazenamentoProdutoAgrotoxico: values.localArmazenamento,
      cpfCnpjFiscalizado: values.cnpjFiscalizado.replace(/[^\d]+/g, ""),
      nomeFiscalizado: values.nomeFiscalizado,
      emailFiscalizado: values.emailFiscalizado,
      telefoneFiscalizado: values.telefoneFiscalizado.replace(/[^\d]+/g, ""),
      cepFiscalizado: values.cepFiscalizado,
      respostas: getRespostasVistoria(perguntas, values),
      municipioFiscalizado: getMunicipioPorId(values.municipioFiscalizado),
      formulario: getFormSelected(values.tipoEstabelecimento) || null,
      assinaturaFiscalizado:
        statusAssinatura.length === 0
          ? fiscalizado?.assinatura?.getTrimmedCanvas().toDataURL() || ""
          : "",
      nomeAssinanteFiscalizado: fiscalizado.nomeAssinante,
      cpfAssinanteFiscalizado: fiscalizado.cpfAssinante.replace(/[^\d]+/g, ""),
      funcaoAssinanteFiscalizado: fiscalizado.funcaoAssinante,
      assinaturaServidor: assinaturaServidor,
      servidor: getServidorPorMatricula(values.matriculaServidor) || null,
      numero: termoVistoria.numero,
      statusAssinaturaFiscalizado: getStatusAssinatura(statusAssinatura),
      statusAssinaturaServidor: getStatusAssinatura(statusAssinatura),
      enderecoFiscalizado: values.enderecoFiscalizado,
      latGrauFiscalizado:
        transformStringToNumber(values.latGrauFiscalizado || "") || 0,
      latMinFiscalizado:
        transformStringToNumber(values.latMinFiscalizado || "") || 0,
      latSegFiscalizado:
        transformStringToNumber(values.latSegFiscalizado || "") || 0,
      longGrauFiscalizado:
        transformStringToNumber(values.longGrauFiscalizado || "") || 0,
      longMinFiscalizado:
        transformStringToNumber(values.longMinFiscalizado || "") || 0,
      longSegFiscalizado:
        transformStringToNumber(values.longSegFiscalizado || "") || 0,
      dataCadastro: `${moment().format("YYYY-MM-DD[T]HH:mm:ss")}Z`,
      inscricaoEstadualFiscalizado: "",
    };

    if (navigator.onLine) {
      setIsSavingTermo(true);
      message.loading({
        key: keyMessage,
        duration: 0,
        content: "Salvando Termo...",
      });

      await apiService.termoVistoria
        .enviar(formValues)
        .then((res) => {
          atualizarTermo(res.data);
          message.destroy(keyMessage);
          setIsSavingTermo(false);
          openNotification({
            type: "success",
            message: "Termo de Vistoria Atualizado com sucesso",
            description: "Termo de Vistoria salvo no banco de dados!",
          });
          navigate("/termo-vistoria/listagem");
        })
        .catch((erro) => {
          console.log(erro);
          message.destroy(keyMessage);
          setIsSavingTermo(false);
          openNotification({
            type: "error",
            message: "Não foi possivel salvar alterações do Termo de Vistoria",
            description: erro.response.data.detail,
          });
        });
    } else {
      try {
        removerTermo(termoVistoria.numero);
        submitTermo(formValues);

        modal.info({
          title: `Gostaria de baixar um backup do Termo de Vistoria?`,
          icon: <ExclamationCircleOutlined />,
          okText: "Sim",
          onOk: () => {
            let blob = new Blob([JSON.stringify(formValues)], {
              type: "text/plain;charset=utf-8",
            });
            fs.saveAs(blob, `Termo de Vistoria ${formValues.numero}.txt`, {
              autoBom: true,
            });
            openNotification({
              type: "success",
              message: "Termo de Vistoria Atualizado com sucesso!",
              description:
                "Salvamento da edição do Termo de Vistoria pendente, para quando aplicativo houver conexão com a internet.",
            });
            navigate("/termo-vistoria/listagem");
          },
        });
      } catch (error) {
        console.log(error);
        openNotification({
          type: "error",
          message: `Não foi possivel Atualizar Termo de Vistoria`,
          description: `Erro: ${error}`,
        });
      }
    }
  };

  useEffect(() => {
    if (termoVistoria) {
      const perguntasTermo = getPerguntasEnumeradas(termoVistoria);
      setPerguntas(perguntasTermo);
      preencherPerguntas(perguntasTermo);
      formEditVistoria.setFieldsValue({
        numTermo: termoVistoria.numero,
        cnpjFiscalizado: cpfCnpjMask(termoVistoria.cpfCnpjFiscalizado || ""),
        nomeFiscalizado: termoVistoria.nomeFiscalizado,
        cepFiscalizado: termoVistoria.cepFiscalizado,
        enderecoFiscalizado: termoVistoria.enderecoFiscalizado,
        telefoneFiscalizado: telefoneMask(
          termoVistoria.telefoneFiscalizado || ""
        ),
        emailFiscalizado: termoVistoria.emailFiscalizado,
        ufFiscalizado: termoVistoria.municipioFiscalizado?.uf.id,
        municipioFiscalizado: termoVistoria.municipioFiscalizado?.id,
        latGrauFiscalizado: coordMask(
          termoVistoria.latGrauFiscalizado.toString() || ""
        ),
        latMinFiscalizado: coordMask(
          termoVistoria.latMinFiscalizado.toString() || ""
        ),
        latSegFiscalizado: coordMask(
          termoVistoria.latSegFiscalizado.toString() || ""
        ),
        longGrauFiscalizado: coordMask(
          termoVistoria.longGrauFiscalizado.toString() || ""
        ),
        longMinFiscalizado: coordMask(
          termoVistoria.longMinFiscalizado.toString() || ""
        ),
        longSegFiscalizado: coordMask(
          termoVistoria.longSegFiscalizado.toString() || ""
        ),
        localArmazenamento: termoVistoria.localArmazenamentoProdutoAgrotoxico,
        matriculaServidor: termoVistoria.servidor?.matricula,
        nomeServidor: termoVistoria.servidor?.pessoa.nome,
        tipoEstabelecimento: termoVistoria.formulario?.id,
      });
    }

    //eslint-disable-next-line
  }, []);

  if (!termoVistoria) {
    return (
      <>
        <Result
          status="error"
          title="Erro ao buscar Termo de Vistoria"
          subTitle="Por favor tente novamente, se o erro persistir entre em contato com o suporte."
        />
      </>
    );
  }

  return (
    <Fragment>
      {contextHolder}
      <TitleTermo>Termo de Vistoria</TitleTermo>
      <Divider />

      <Form
        id="form-vistoria"
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "200px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        form={formEditVistoria}
        onFinish={handleSubmit}
        onReset={modalConfirm}
        onFinishFailed={messageSubmitFailed}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <FiscalizadoVistoria
          formVistoria={formEditVistoria}
          municipiosOptions={municipiosOptions}
          setMunicipiosOptions={setMuncipiosOptions}
          isValidDocument={isValidDocument}
          setIsValidDocument={setIsValidDocument}
          setFiscalizado={setFiscalizado}
        />

        <Form.Item
          label="Local de armazenamento dos produtos agrotóxicos"
          name="localArmazenamento"
          labelCol={{ span: 6 }}
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value="PREDIO_PROPRIO"> Prédio próprio</Radio>
            <Radio value="OUTRO_LOCAL">Outro local</Radio>
          </Radio.Group>
        </Form.Item>

        <Divider />

        <PerguntasVistoria
          perguntas={perguntas}
          setPerguntas={setPerguntas}
          formVistoria={formEditVistoria}
          listaPerguntas={data || []}
        />

        <Divider />
        <ServidorVistoria
          form={formEditVistoria}
          setIsServidorSelected={setIsServidorSelected}
        />
      </Form>

      <Divider />

      <AssinaturaVistoria
        ativarModalInformarNumTermo={() => {}}
        fiscalizado={fiscalizado}
        setFiscalizado={setFiscalizado}
        form={formEditVistoria}
        assinaturaServidor={assinaturaServidor}
        setAssinaturaServidor={setAssinaturaServidor}
        setStatusAssinatura={setStatusAssinatura}
        statusAssinatura={statusAssinatura}
        isServidorSelected={isServidorSelected}
        refServidor={refServidor}
      />

      <ButtonFooterContainer>
        <Button form="form-vistoria" htmlType="reset" disabled={isSavingTermo}>
          Cancelar
        </Button>
        <Button
          type="primary"
          form="form-vistoria"
          htmlType="submit"
          disabled={isSavingTermo}
        >
          Salvar
        </Button>
      </ButtonFooterContainer>
    </Fragment>
  );
}
