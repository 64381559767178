import { FilterOutlined } from "@ant-design/icons";
import { ButtonAplicarFiltro } from "@components/ButtonAplicarFiltro";
import { openNotification } from "@components/Notifications";
import { apiService } from "@services/api";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Tooltip,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import { useActionsTID } from "../../../hooks/useActionsTID";
import { OPTIONS_TID_FILTER } from "../../constants/OPTIONS_TID_FILTER";

export function FiltroTID({
  formFiltro,
  setPageControl,
}: {
  formFiltro: FormInstance;
  setPageControl: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filtroSelect, setFiltroSelect] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { aplicarFiltro, listaFiltroTID, removerFiltro } = useActionsTID();

  const showDrawer = () => {
    setIsModalOpen(true);
  };

  const onClose = () => {
    setIsModalOpen(false);
    // setFilterSelect("");
    setFiltroSelect([]);
  };

  const handleChangeFiltro = (values: any[]) => {
    setFiltroSelect(values);
  };

  const handleSubmitFilter = async (values: any) => {
    // console.log(values);
    const params = new URLSearchParams();
    params.append("size", "20");
    for (const filtro in values) {
      params.append(filtro, values[filtro].toUpperCase().trim());
    }
    setIsLoading(true);
    await apiService.tid
      .listar(params.toString())
      .then((data) => {
        removerFiltro();

        aplicarFiltro([
          {
            filtros: filtroSelect,
            params: params.toString(),
            termos: data.content,
            numberPages: data.totalPages,
          },
        ]);

        setPageControl(1);
        if (data.content.length > 0) {
          openNotification({
            type: "success",
            message:
              "Termo(s) de Interdição/Desinterdição encontrado(s) com sucesso!",
          });
        } else {
          openNotification({
            type: "info",
            message:
              "Não foi possivel encontrar Termo(s) de Interdição/Desinterdição",
            description: "Nenhum Termo foi encontrado na base de dados.",
          });
        }

        onClose();
      })
      .catch((erro) => {
        console.log(erro.response);
        openNotification({
          type: "error",
          message:
            "Não foi possivel encontrar Termo(s) de Interdição/Desinterdição",
          description: erro.response?.data?.detail,
        });
        // window.location.reload();
      });
    setIsLoading(false);
    return;
  };

  useEffect(() => {
    if (listaFiltroTID.length > 0) {
      setFiltroSelect(listaFiltroTID[0].filtros);

      const params = new URLSearchParams(listaFiltroTID[0].params);

      listaFiltroTID[0].filtros.map((filtro) => {
        formFiltro.setFieldValue(filtro, params.get(filtro));
        return filtro;
      });
    } else {
      setFiltroSelect([]);
      formFiltro.resetFields();
    }
  }, [isModalOpen, listaFiltroTID, formFiltro]);

  return (
    <Fragment>
      <Tooltip title="Filtro">
        <Button
          onClick={showDrawer}
          disabled={!navigator.onLine}
          style={{ boxShadow: "5px 5px 5px rgba(0,0,0,0.2)" }}
        >
          <FilterOutlined />
        </Button>
      </Tooltip>

      <Form
        id="form-filtro"
        layout="vertical"
        labelWrap
        form={formFiltro}
        onFinish={handleSubmitFilter}
        onFinishFailed={(e) => {
          openNotification({
            type: "error",
            message: "Campos em branco",
            description: "Termine de preencher todos os campos obrigatórios",
          });
        }}
      >
        <Drawer
          title="Filtro de Termo(s) de Interdição/Desinterdição"
          placement="right"
          closable={false}
          onClose={onClose}
          open={isModalOpen}
          key={"right"}
          width={350}
          extra={
            <Space>
              <Button onClick={onClose}>Fechar</Button>
            </Space>
          }
        >
          <Checkbox.Group onChange={handleChangeFiltro} value={filtroSelect}>
            <Space direction="vertical">
              {OPTIONS_TID_FILTER.map((filtro) => {
                return (
                  <Checkbox key={filtro.value} value={filtro.value}>
                    {filtro.label}
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>

          <Divider />

          {filtroSelect.includes("numeroTIF") && (
            <Form.Item
              label="Digite o Número do TIF"
              name="numeroTIF"
              rules={[
                {
                  required: filtroSelect.includes("numeroTIF"),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

          {filtroSelect.includes("numero") && (
            <Form.Item
              label="Digite o Número do Termo"
              name="numero"
              rules={[
                {
                  required: filtroSelect.includes("numero"),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

          {filtroSelect.includes("nomeFiscalizado") && (
            <Form.Item
              label="Digite o Nome do Fiscalizado"
              name="nomeFiscalizado"
              rules={[
                {
                  required: filtroSelect.includes("nomeFiscalizado"),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

          {filtroSelect.includes("cpfCnpjFiscalizado") && (
            <Form.Item
              label="Digite o CPF/CNPJ do Fiscalizado"
              name="cpfCnpjFiscalizado"
              rules={[
                {
                  required: filtroSelect.includes("cpfCnpjFiscalizado"),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

          {filtroSelect.includes("municipioFiscalizacao") && (
            <Form.Item
              label="Digite o Municipio da Fiscalização"
              name="municipioFiscalizacao"
              rules={[
                {
                  required: filtroSelect.includes("municipioFiscalizacao"),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

          <Row justify="center">
            <Space direction="vertical" size={10}>
              <ButtonAplicarFiltro
                isDisabled={filtroSelect.length === 0}
                isLoading={isLoading}
              />
              <Col />
            </Space>
          </Row>
        </Drawer>
      </Form>
    </Fragment>
  );
}
