import { IMunicipio } from "@interfaces/IMunicipio";
import { downloadMunicipiosAction } from "@store/actions/downloadMunicipioAction";
import { municipiosAction } from "@store/actions/municipiosAction";
import {
  getDownloadMunicipios,
  getExpireDownloadMunicipio,
} from "@store/feature/downloadMunicipioSlice";
import {
  getMunicipios,
  getMunicipiosMT,
  getUfs,
} from "@store/feature/municipiosSlice";
import { useDispatch, useSelector } from "react-redux";

export const useMunicipio = () => {
  const listaMunicipiosBrasil = useSelector(getMunicipios);
  const listaMunicipiosMt = useSelector(getMunicipiosMT);
  const listaMunicipiosSincronizados = useSelector(getDownloadMunicipios);
  const timeExpireMunicipioSincronizados = useSelector(
    getExpireDownloadMunicipio
  );
  const listaUfs = useSelector(getUfs);
  const appDispatch = useDispatch();
  const { addMunicipios, removeMunicipio } = downloadMunicipiosAction;
  const { addMunicipios: addMunicipiosBrasil } = municipiosAction;

  const adicionarMunicipiosSincronizados = (
    municipios: IMunicipio[],
    createdAt: number
  ) => {
    appDispatch(addMunicipios(municipios, createdAt));
  };

  const adicionarListaComTodosMunicipios = (municipios: IMunicipio[]) => {
    appDispatch(addMunicipiosBrasil(municipios));
  };

  const removerMunicipioSincronizado = (municipioId: number) => {
    appDispatch(removeMunicipio(municipioId));
  };

  const getMunicipioPorId = (municipioId: number | undefined | "") => {
    if (!municipioId) return null;

    const municipio = listaMunicipiosBrasil.find(
      (municipio) => municipio.id === municipioId
    );

    if (!municipio) return null;
    return municipio;
  };

  return {
    municipiosMt: listaMunicipiosMt,
    municipiosBrasil: listaMunicipiosBrasil,
    municipioSincronizados: listaMunicipiosSincronizados,
    listaUfs,
    adicionarMunicipiosSincronizados,
    adicionarListaComTodosMunicipios,
    timeExpireMunicipioSincronizados,
    removerMunicipioSincronizado,
    getMunicipioPorId,
  };
};
