import { IEstadioFenologico } from "@interfaces/IEstadioFenologico";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IEstadioFenologicoSlice {
  items: IEstadioFenologico[];
  persistedAt: number;
}

const initialState: IEstadioFenologicoSlice = {
  items: [],
  persistedAt: 0,
};

const estadioFenologicoSlice = createSlice({
  name: "ESTADIO_FENOLOGICO",
  initialState,
  reducers: {
    addEstadioFenologico: (state, action) => {
      const { estadioFenologico, dateTimeExpire } = action.payload;
      state.items = estadioFenologico;
      state.persistedAt = dateTimeExpire;
    },
    removeEstadioFenologico: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default estadioFenologicoSlice.reducer;

export const getEstadioFenologico = (state: RootState) =>
  state.estadioFenologico.items;
