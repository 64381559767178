import { openNotification } from "@components/Notifications";
import { ITID } from "@interfaces/ITid";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface ITidSlice {
  items: ITID[];
}

const initialState: ITidSlice = {
  items: [],
};

const tidSlice = createSlice({
  name: "TID",
  initialState,
  reducers: {
    addTermo: (state, action) => {
      const { tidJson } = action.payload;

      state.items.push(tidJson);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },

    updateTermo: (state, action) => {
      const { tidJson } = action.payload;
      state.items = state.items.map((item) => {
        if (item.numero === tidJson.numero) return tidJson;

        return item;
      });
    },
    removeTermo: (state, action) => {
      const { numTermo } = action.payload;

      state.items = state.items.filter((item) => item.numero !== numTermo);
    },
    addTermoCompleto: (state, action) => {
      const { termo } = action.payload;
      state.items.push(termo);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },

    updateAssinatura: (state, action) => {
      const { dadosEdit, termoId } = action.payload;

      state.items = state.items.map((termo) => {
        if (termo.id === termoId) {
          return {
            ...termo,
            ...dadosEdit,
          };
        }

        return termo;
      });
    },

    updateAssinaturaRollback: (state, action: any) => {
      const { tid }: { tid: ITID } = action.meta;

      state.items = state.items.map((item) => {
        if (item.id === tid.id) return tid;
        return item;
      });

      const tipoTermoFormatado =
        tid.tipoTermo === "INTERDICAO" ? "Interdição" : "Desinterdição";

      openNotification({
        type: "error",
        message: `Não foi possivel atualizar Termo de ${tipoTermoFormatado} ${tid.numero}`,
        description: action.payload.response.data.detail,
      });
    },
  },
});

export default tidSlice.reducer;

export const getTIDs = (state: RootState) => {
  const novo = state.tid.items.map((form, ind, lista) => {
    return lista[lista.length - ind - 1];
  });

  // console.log(novo);
  return novo;
};
