import { ITabelaTesteLasgal } from "@interfaces/ITabelaTesteLasgal";
import { Button } from "antd";
import { VALOR_PADRAO } from "../../constants";

export function ButtonConfirmaOutrasCultivares({
  hasOutrasCultivares,
  dataTable,
  armazenarDadosOutrasCultivares,
}: {
  hasOutrasCultivares: boolean;
  dataTable: ITabelaTesteLasgal[];
  armazenarDadosOutrasCultivares: (value: ITabelaTesteLasgal[]) => void;
}) {
  const onChange = () => {
    const auxDataTable = [
      dataTable[0],
      {
        ...dataTable[1],
        outraCultivares: hasOutrasCultivares ? VALOR_PADRAO : "",
      },
    ];
    armazenarDadosOutrasCultivares(auxDataTable);
  };

  return (
    <Button
      type={hasOutrasCultivares ? "primary" : "default"}
      style={{ width: 250 }}
      onClick={onChange}
    >
      {hasOutrasCultivares
        ? "Não fez outras cultivares"
        : "Fez outras cultivares"}
    </Button>
  );
}
