import { LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";

export function ButtonAplicarFiltro({
  isLoading,
  isDisabled,
}: {
  isLoading: boolean;
  isDisabled: boolean;
}) {
  return (
    <Button
      form="form-filtro"
      type="primary"
      htmlType="submit"
      disabled={isDisabled}
    >
      {isLoading ? (
        <>
          Aplicando filtro...{" "}
          <Spin
            indicator={<LoadingOutlined spin style={{ color: "#FFFF" }} />}
          />
        </>
      ) : (
        "Aplicar"
      )}
    </Button>
  );
}
