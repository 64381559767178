import { IEstadioFenologico } from "@interfaces/IEstadioFenologico";
import { estadioFenologicoAction } from "@store/actions/estadioFenologicoAction";
import { getEstadioFenologico } from "@store/feature/estadioFenologicoSlice";
import { useDispatch, useSelector } from "react-redux";

export const useEstadioFenologico = () => {
  const listaEstadioFenologico = useSelector(getEstadioFenologico);
  const { addEstadioFenologico, removeEstadioFenologico } =
    estadioFenologicoAction;
  const appDispatch = useDispatch();

  const adicionarEstadios = (
    estadios: IEstadioFenologico[],
    persistedAt: number
  ) => {
    appDispatch(addEstadioFenologico(estadios, persistedAt));
  };

  const removerEstadios = () => {
    appDispatch(removeEstadioFenologico());
  };

  return { listaEstadioFenologico, removerEstadios, adicionarEstadios };
};
