import { IContentListagemTID } from "@interfaces/IListagemTID";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IFiltroSlice {
  items: {
    filtros: string[];
    params: string;
    termos: IContentListagemTID[];
    numberPages: number;
  }[];
}

const initialState: IFiltroSlice = {
  items: [],
};

const filtroTidSlice = createSlice({
  name: "FILTRO_TID",
  initialState,
  reducers: {
    addFiltro: (state, action) => {
      const { tids } = action.payload;

      state.items = tids;
    },
    removeFiltro: (state) => {
      state.items = [];
    },
  },
});

export default filtroTidSlice.reducer;

export const getFiltroTid = (state: RootState) => state.filtroTID.items;
