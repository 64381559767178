import { openNotification } from "@components/Notifications";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IItemsTermoVistoria extends ITermoVistoria {
  error?: boolean;
}

interface ITermoVistoriaSlice {
  items: IItemsTermoVistoria[];
  persistedAtItemsSync: number;
}

const initialState: ITermoVistoriaSlice = {
  items: [],
  persistedAtItemsSync: 0,
};

const termoVistoriaSlice = createSlice({
  name: "TERMO_VISTORIA",
  initialState,
  reducers: {
    addTermo: (state, action) => {
      const { termo } = action.payload;

      state.items.push(termo);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },

    addByFilter: (state, action) => {
      const { termo } = action.payload;
      state.items.push(termo);

      if (state.items.length > 40) {
        state.items.shift();
      }
    },

    removeTermo: (state, action) => {
      const { numTermo } = action.payload;

      const termos = state.items.filter((item) => item.numero === numTermo);
      //VERIFICAR SE POSSUI MAIS DE UM TERMO COM ESSE NUMERO
      if (termos.length > 1) {
        state.items = state.items.filter((termo) => {
          if (termo.numero === numTermo && !termo.id) return null;

          return termo;
        });

        return;
      }

      state.items = state.items.filter((item) => item.numero !== numTermo);
    },

    updateTermo: (state, action) => {
      const { termo } = action.payload;

      state.items = state.items.map((item) => {
        if (item.numero === termo.numero) return termo;

        return item;
      });
    },
    updateAssinatura: (state, action) => {
      const { dadosEdit, termoId } = action.payload;
      state.items = state.items.map((termo) => {
        if (termo.id === termoId) {
          return {
            ...termo,
            ...dadosEdit,
          };
        }
        return termo;
      });
    },

    updateAssinaturaRollback: (state, action: any) => {
      const { termo }: { termo: ITermoVistoria } = action.meta;

      state.items = state.items.map((item) => {
        if (item.id === termo.id) return termo;
        return item;
      });

      openNotification({
        type: "error",
        message: `Não foi possivel atualizar Termo de Vistoria ${termo.numero}`,
        description: action.payload.response.data.detail,
      });
    },

    addTimePersistedAtItems: (state, action) => {
      const { createdAt } = action.payload;
      state.persistedAtItemsSync = createdAt;
    },

    removeTimePersistedAtItems: (state, action) => {
      state.persistedAtItemsSync = 0;
    },
  },
});

export default termoVistoriaSlice.reducer;

export const getTermosVistoria = (state: RootState) => {
  const novo = state.termosVistoria.items.map((form, ind, lista) => {
    return lista[lista.length - ind - 1];
  });

  return novo;
};

export const getPersistedAtTermoVistoria = (state: RootState) =>
  state.termosVistoria.persistedAtItemsSync;
