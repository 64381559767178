import { ModalAssinatura } from "@components/ModalAssinatura";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { INITIAL_VALUES_ASSINATURA } from "@constants/INITIAL_VALUES_ASSINATURA";
import { masks } from "@functions/mascaras";
import { useServidores } from "@hooks/useServidores";
import { IAssinante } from "@interfaces/IAssinante";
import { IServidorForm } from "@interfaces/IServidorForm";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { Button, Checkbox, FormInstance, Input, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useEffect, useRef, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";

interface IAssinaturasTermoApreensaoProps {
  fiscalizadoSignature: IAssinante;
  setFiscalizadoSignature: React.Dispatch<React.SetStateAction<IAssinante>>;
  motivoNaoAssinaturaFiscalizado: CheckboxValueType[];
  setMotivoNaoAssinaturaFiscalizado: React.Dispatch<
    React.SetStateAction<CheckboxValueType[]>
  >;
  servidores: IServidorForm[];
  setServidores: React.Dispatch<React.SetStateAction<IServidorForm[]>>;
  formApreensao: FormInstance;
  isProprio: boolean;
  depositarioSignature: IAssinante;
  setDepositarioSignature: React.Dispatch<React.SetStateAction<IAssinante>>;
  motivoNaoAssinaturaDepositario: CheckboxValueType[];
  setMotivoNaoAssinaturaDepositario: React.Dispatch<
    React.SetStateAction<CheckboxValueType[]>
  >;
  checkBoxAssinaturaFisica: CheckboxValueType[];
  setCheckBoxAssinaturaFisica: React.Dispatch<
    React.SetStateAction<CheckboxValueType[]>
  >;
  setIsAssinadoFisicamente: React.Dispatch<React.SetStateAction<boolean>>;
  isAssinadoFisicamente: boolean;
  ativarModalInformarNumTermo: () => void;
}

export function AssinaturasTermoApreensaoLiberacao({
  setFiscalizadoSignature,
  fiscalizadoSignature,
  motivoNaoAssinaturaFiscalizado,
  setMotivoNaoAssinaturaFiscalizado,
  servidores,
  setServidores,
  formApreensao,
  isProprio,
  depositarioSignature,
  motivoNaoAssinaturaDepositario,
  setDepositarioSignature,
  setMotivoNaoAssinaturaDepositario,
  checkBoxAssinaturaFisica,
  setCheckBoxAssinaturaFisica,
  setIsAssinadoFisicamente,
  isAssinadoFisicamente,
  ativarModalInformarNumTermo,
}: IAssinaturasTermoApreensaoProps) {
  const { servidoresSelecionados } = useServidores();
  const refAssinante = useRef(null);
  const refDepositarioAssinante = useRef(null);
  const refServidores = useRef<any>();
  const [isOpenModalFiscalizado, setIsOpenModalFiscalizado] = useState(false);
  const [isOpenModalDepositario, setIsOpenModalDepositario] = useState(false);
  const [isOpenMOdalServidores, setIsOpenModalServidores] = useState(false);
  const [pointsArray, setPointsArray] = useState(null);
  const [idServidorSelected, setIdServidorSelected] = useState<number>();
  const { cpfCnpjMask } = masks;

  const handleCloseModalServidores = async () => {
    setIsOpenModalServidores(false);

    const aux = await servidores.map((serv) => {
      if (serv.servidor.id === idServidorSelected) {
        return {
          ...serv,
          assinatura: refServidores.current.isEmpty()
            ? ""
            : refServidores.current.getTrimmedCanvas().toDataURL("image/png"),
        };
      }

      return serv;
    });

    setServidores(aux);

    cleanSignatureFields(refServidores.current);
  };

  const cleanSignatureFields = (
    signature: ReactSignatureCanvas | null | undefined
  ) => {
    if (signature !== null && signature !== undefined) {
      signature.clear();
    }
  };

  const handleChangeMotivoNaoAssinatura = (
    checkedValue: CheckboxValueType[],
    tipo?: string
  ) => {
    if (tipo === "AMBOS" && checkedValue.length > 0) {
      setMotivoNaoAssinaturaDepositario([]);
      setMotivoNaoAssinaturaFiscalizado([]);
      setCheckBoxAssinaturaFisica(checkedValue);
      ativarModalInformarNumTermo();
      setIsAssinadoFisicamente(true);
    } else {
      setIsAssinadoFisicamente(false);
      setCheckBoxAssinaturaFisica([]);
    }

    if (tipo === "FISCALIZADO" || tipo === "AMBOS") {
      setMotivoNaoAssinaturaFiscalizado(checkedValue);

      if (checkedValue[0] === "RECUSADO") {
        cleanSignatureFields(fiscalizadoSignature.assinatura);
      }

      if (checkedValue[0] === "AUSENTE") {
        cleanSignatureFields(fiscalizadoSignature.assinatura);
        setFiscalizadoSignature(INITIAL_VALUES_ASSINATURA);
        return;
      }

      setFiscalizadoSignature({
        ...fiscalizadoSignature,
        nomeAssinante: formApreensao.getFieldValue("nomeFiscalizado"),
        cpfAssinante: formApreensao.getFieldValue("cpfCnpjFiscalizado"),
      });
    }

    if (tipo === "DEPOSITARIO" || tipo === "AMBOS") {
      setMotivoNaoAssinaturaDepositario(checkedValue);

      if (checkedValue[0] === "RECUSADO") {
        cleanSignatureFields(depositarioSignature.assinatura);
      }

      if (checkedValue[0] === "AUSENTE") {
        cleanSignatureFields(depositarioSignature.assinatura);
        setDepositarioSignature(INITIAL_VALUES_ASSINATURA);
        return;
      }

      setDepositarioSignature({
        ...depositarioSignature,
        nomeAssinante: formApreensao.getFieldValue("nomeDepositario"),
        cpfAssinante: formApreensao.getFieldValue("cpfCnpjDepositario"),
      });
    }

    if (checkedValue[0] === ASSINATURA_FISICA) {
      cleanSignatureFields(fiscalizadoSignature.assinatura);
      cleanSignatureFields(depositarioSignature.assinatura);
      const auxServ = servidores.map((serv) => {
        return {
          ...serv,
          assinatura: "",
        };
      });

      setServidores(auxServ);

      return;
    }
  };

  const handleOpenModalServidores = () => {
    setIsOpenModalServidores(true);
    setTimeout(() => {
      if (pointsArray) {
        refServidores.current.fromData(pointsArray);
      }
    });
  };

  const handleOpenModal = (assinante: string) => {
    if (assinante === "FISCALIZADO") {
      setIsOpenModalFiscalizado(true);
      return;
    }

    if (assinante === "DEPOSITARIO") {
      setIsOpenModalDepositario(true);
    }
  };

  const handleCloseModalFiscalizado = async () => {
    setIsOpenModalFiscalizado(false);
    setFiscalizadoSignature({
      ...fiscalizadoSignature,
      assinatura: refAssinante.current,
    });
  };

  const handleCloseModalDepositario = async () => {
    setIsOpenModalDepositario(false);
    setDepositarioSignature({
      ...depositarioSignature,
      assinatura: refDepositarioAssinante.current,
    });
  };

  useEffect(() => {
    const aux = servidoresSelecionados.map((serv) => {
      return {
        assinatura: "",
        servidor: serv.servidor,
      };
    });

    setServidores(aux);
  }, [servidoresSelecionados]);

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          padding: "50px 15px 15px 15px",
        }}
      >
        <Checkbox.Group
          style={{
            display: "flex",
            flexDirection: "row",
            width: 250,
          }}
          onChange={(e) => handleChangeMotivoNaoAssinatura(e, "AMBOS")}
          value={checkBoxAssinaturaFisica}
        >
          <Checkbox value={ASSINATURA_FISICA}>Assinatura Física</Checkbox>
        </Checkbox.Group>
      </div>
      <AssinaturaContainer>
        {servidores.map((serv, ind) => {
          return (
            <div className="servidor-signature-form" key={ind}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="container-img-assinatura">
                  {serv.assinatura && (
                    <img
                      src={serv.assinatura}
                      alt={`assinatura servidor: ${serv.servidor.nome}`}
                      style={{
                        width: "90%",
                        height: "90%",
                      }}
                    />
                  )}
                </div>

                <Typography style={{ textAlign: "center" }}>
                  Assinatura {serv.servidor.nome}
                </Typography>
                <Button
                  disabled={
                    motivoNaoAssinaturaFiscalizado[0] === ASSINATURA_FISICA
                  }
                  type="primary"
                  onClick={() => {
                    handleOpenModalServidores();
                    setIdServidorSelected(serv.servidor.id);
                  }}
                >
                  Clique aqui para assinar
                </Button>
              </div>
            </div>
          );
        })}

        <div className="fiscalizado-signature-form">
          <div>
            <div className="container-img-assinatura">
              {fiscalizadoSignature.assinatura && (
                <img
                  src={fiscalizadoSignature.assinatura
                    .getTrimmedCanvas()
                    .toDataURL("image/png")}
                  alt="assinatura Fiscalizado"
                  style={{
                    width: "90%",
                    height: "90%",
                  }}
                />
              )}
            </div>

            <Typography style={{ textAlign: "center" }}>
              Assinatura Fiscalizado
            </Typography>
            <Button
              disabled={
                motivoNaoAssinaturaFiscalizado.length > 0 ||
                isAssinadoFisicamente
              }
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                handleOpenModal("FISCALIZADO");
              }}
            >
              Clique aqui para assinar
            </Button>

            <div style={{ display: "flex", paddingTop: 10 }}>
              <Checkbox.Group
                onChange={(e) =>
                  handleChangeMotivoNaoAssinatura(e, "FISCALIZADO")
                }
                value={motivoNaoAssinaturaFiscalizado}
              >
                <Checkbox value="AUSENTE">Ausente</Checkbox>
              </Checkbox.Group>

              <Checkbox.Group
                value={motivoNaoAssinaturaFiscalizado}
                onChange={(e) =>
                  handleChangeMotivoNaoAssinatura(e, "FISCALIZADO")
                }
              >
                <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
              </Checkbox.Group>
            </div>

            <div
              className="dados-assinante-tif"
              style={{
                display: "flex",
                gap: 10,
                maxWidth: 600,
              }}
            >
              <div>
                <span>Nome:</span>
                <Input
                  disabled={
                    motivoNaoAssinaturaFiscalizado.length > 0 &&
                    ![ASSINATURA_FISICA, "RECUSADO"].includes(
                      motivoNaoAssinaturaFiscalizado[0].toString()
                    )
                  }
                  name="nome_assinante"
                  value={fiscalizadoSignature?.nomeAssinante}
                  onChange={(e) =>
                    setFiscalizadoSignature({
                      ...fiscalizadoSignature,
                      nomeAssinante: e.target.value,
                    })
                  }
                />
              </div>

              <div>
                <span>CPF:</span>
                <Input
                  disabled={
                    motivoNaoAssinaturaFiscalizado.length > 0 &&
                    ![ASSINATURA_FISICA, "RECUSADO"].includes(
                      motivoNaoAssinaturaFiscalizado[0].toString()
                    )
                  }
                  name="cpf_assinante"
                  value={fiscalizadoSignature?.cpfAssinante}
                  onChange={(e) => {
                    setFiscalizadoSignature({
                      ...fiscalizadoSignature,
                      cpfAssinante: cpfCnpjMask(e.target.value),
                    });
                  }}
                />
              </div>

              <div>
                <span>Função:</span>
                <Input
                  disabled={
                    motivoNaoAssinaturaFiscalizado.length > 0 &&
                    ![ASSINATURA_FISICA, "RECUSADO"].includes(
                      motivoNaoAssinaturaFiscalizado[0].toString()
                    )
                  }
                  name="funcao_assinante"
                  value={fiscalizadoSignature?.funcaoAssinante}
                  onChange={(e) =>
                    setFiscalizadoSignature({
                      ...fiscalizadoSignature,
                      funcaoAssinante: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {!isProprio && (
          <div className="fiscalizado-signature-form">
            <div>
              <div className="container-img-assinatura">
                {depositarioSignature.assinatura && (
                  <img
                    src={depositarioSignature.assinatura
                      .getTrimmedCanvas()
                      .toDataURL("image/png")}
                    alt="assinatura Depositário"
                    style={{
                      width: "90%",
                      height: "90%",
                    }}
                  />
                )}
              </div>

              <Typography style={{ textAlign: "center" }}>
                Assinatura Depositário
              </Typography>
              <Button
                disabled={
                  motivoNaoAssinaturaDepositario.length > 0 ||
                  isAssinadoFisicamente
                }
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                  handleOpenModal("DEPOSITARIO");
                }}
              >
                Clique aqui para assinar
              </Button>

              <div style={{ display: "flex", paddingTop: 10 }}>
                <Checkbox.Group
                  onChange={(e) =>
                    handleChangeMotivoNaoAssinatura(e, "DEPOSITARIO")
                  }
                  value={motivoNaoAssinaturaDepositario}
                >
                  <Checkbox value="AUSENTE">Ausente</Checkbox>
                </Checkbox.Group>

                <Checkbox.Group
                  value={motivoNaoAssinaturaDepositario}
                  onChange={(e) =>
                    handleChangeMotivoNaoAssinatura(e, "DEPOSITARIO")
                  }
                >
                  <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                </Checkbox.Group>
              </div>

              <div
                className="dados-assinante-tif"
                style={{
                  display: "flex",
                  gap: 10,
                  maxWidth: 600,
                }}
              >
                <div>
                  <span>Nome:</span>
                  <Input
                    disabled={
                      motivoNaoAssinaturaDepositario.length > 0 &&
                      ![ASSINATURA_FISICA, "RECUSADO"].includes(
                        motivoNaoAssinaturaDepositario[0].toString()
                      )
                    }
                    name="nome_assinante"
                    value={depositarioSignature?.nomeAssinante}
                    onChange={(e) =>
                      setDepositarioSignature({
                        ...depositarioSignature,
                        nomeAssinante: e.target.value,
                      })
                    }
                  />
                </div>

                <div>
                  <span>CPF:</span>
                  <Input
                    disabled={
                      motivoNaoAssinaturaDepositario.length > 0 &&
                      ![ASSINATURA_FISICA, "RECUSADO"].includes(
                        motivoNaoAssinaturaDepositario[0].toString()
                      )
                    }
                    name="cpf_assinante"
                    value={depositarioSignature?.cpfAssinante}
                    onChange={(e) => {
                      setDepositarioSignature({
                        ...depositarioSignature,
                        cpfAssinante: cpfCnpjMask(e.target.value),
                      });
                    }}
                  />
                </div>

                <div>
                  <span>Função:</span>
                  <Input
                    disabled={
                      motivoNaoAssinaturaDepositario.length > 0 &&
                      ![ASSINATURA_FISICA, "RECUSADO"].includes(
                        motivoNaoAssinaturaDepositario[0].toString()
                      )
                    }
                    name="funcao_assinante"
                    value={depositarioSignature?.funcaoAssinante}
                    onChange={(e) =>
                      setDepositarioSignature({
                        ...depositarioSignature,
                        funcaoAssinante: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </AssinaturaContainer>

      {/**MODAL ASSINATURA FISCALIZADO */}
      <ModalAssinatura
        isOpen={isOpenModalFiscalizado}
        cleanSignature={cleanSignatureFields}
        handleClose={handleCloseModalFiscalizado}
        refAssinante={refAssinante}
        nomeAssinante="Fiscalizado"
      />

      {/**MODAL ASSINATURA DEPOSITARIO */}
      <ModalAssinatura
        isOpen={isOpenModalDepositario}
        cleanSignature={cleanSignatureFields}
        handleClose={handleCloseModalDepositario}
        refAssinante={refDepositarioAssinante}
        nomeAssinante="Depositário"
      />

      {/**MODAL SERVIDORES */}
      <ModalAssinatura
        isOpen={isOpenMOdalServidores}
        cleanSignature={cleanSignatureFields}
        handleClose={handleCloseModalServidores}
        refAssinante={refServidores}
        nomeAssinante={
          servidores.find((serv) => serv.servidor.id === idServidorSelected)
            ?.servidor.nome || "Servidor"
        }
      />
    </Fragment>
  );
}
