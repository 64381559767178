import { LoadingOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { checkDevice } from "@functions/checkDevice";
import { apiService } from "@services/api";
import { TitleTermo } from "@styles/TitleTermo";
import {
  Checkbox,
  Divider,
  Form,
  List,
  message,
  Pagination,
  Spin,
  Typography,
} from "antd";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useEffect, useState } from "react";
import { useActionsTermoColeta } from "../hooks/useActionsTermoColeta";
import { ButtonEnviarBackupTermoColeta } from "./components/ButtonEnviarBackupTermoColeta";
import { FiltroTermoColeta } from "./components/FiltroTermoColeta";
import { ItemListaFiltroTermoColeta } from "./components/ItemListaFiltroTermoColeta";
import { ItemListaTermoColeta } from "./components/ItemListaTermoColeta";
import { TERMO_COLETA_OPTIONS_FILTER } from "./constants";
import styles from "./style.module.css";

const PAGE_SIZE = 20;

export function ListagemTermoColeta() {
  const [formFiltro] = Form.useForm();
  const { aplicarFiltro, removerFiltro, listaFiltroTermos, listaTermos } =
    useActionsTermoColeta();

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [optionsFiltroSelected, setOptionsFiltroSelected] = useState<
    CheckboxOptionType[]
  >([]);
  const [pageControl, setPageControl] = useState(1);
  const [loading, setLoading] = useState(false);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(PAGE_SIZE);

  const handleChangePagination = async (page: number, filtro?: boolean) => {
    const keyMessage = "termo-coleta-pagination";
    if (filtro && !navigator.onLine) return;

    // localStorage.setItem("termoColeta:pageSelect", page.toString());

    setPageControl(page);

    if (filtro) {
      const params = new URLSearchParams(listaFiltroTermos[0].params);
      params.append("page", (page - 1).toString());
      params.append("size", PAGE_SIZE.toString());

      setLoading(true);
      message.loading({
        key: keyMessage,
        content: "Carregando termos...",
        duration: 0,
      });
      await apiService.termoColeta
        .listar(params.toString())
        .then((res) => {
          aplicarFiltro([
            {
              ...listaFiltroTermos[0],
              termos: res.content,
            },
          ]);
        })
        .catch((erro) => console.log(erro));

      message.destroy(keyMessage);
      setLoading(false);
      return;
    }
    setMinIndex((page - 1) * PAGE_SIZE);
    setMaxIndex(page * PAGE_SIZE);
  };

  const handleChangeFilterSelect = async (values: any) => {
    const keyMessage = "termo-coleta-reload-filter";
    const params = new URLSearchParams(listaFiltroTermos[0].params);
    const newParams = new URLSearchParams();
    formFiltro.resetFields();

    if (values.length === 0) {
      removerFiltro();
      openNotification({
        type: "info",
        message: "Filtro Removido",
      });

      handleChangePagination(1);

      return;
    }

    values.forEach((value: any) => {
      newParams.append(value, params.get(value)?.toString() || "");
      formFiltro.setFieldValue(value, params.get(value)?.toString());
    });

    newParams.append("size", PAGE_SIZE.toString());

    setCheckedList(values);
    setLoading(true);
    message.loading({
      key: keyMessage,
      content: "Carregando termos...",
      duration: 0,
    });

    await apiService.termoColeta
      .listar(newParams.toString())
      .then((res) => {
        aplicarFiltro([
          {
            filtros: values,
            params: newParams.toString(),
            termos: res.content,
            numberPages: res.totalPages,
          },
        ]);

        setPageControl(1);
        message.destroy(keyMessage);
        setLoading(false);
        openNotification({
          type: "success",
          message: `Filtro Atualizado`,
        });
      })
      .catch((erro) => {
        setPageControl(1);
        message.destroy(keyMessage);
        setLoading(false);
        openNotification({
          type: "error",
          message: `Não foi possivel buscar Termo(s) de Coleta.`,
          description: erro.response.data.detail,
        });
      });
  };

  useEffect(() => {
    let pageSelected = pageControl;

    if (localStorage.getItem("termoColeta:pageSelect")) {
      pageSelected = parseInt(
        localStorage.getItem("termoColeta:pageSelect") || ""
      );

      localStorage.removeItem("termoColeta:pageSelect");
    }

    handleChangePagination(pageSelected, listaFiltroTermos.length > 0);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (listaFiltroTermos.length > 0) {
      setCheckedList(listaFiltroTermos[0].filtros);

      const optionsFiltro = TERMO_COLETA_OPTIONS_FILTER.filter((option) => {
        if (listaFiltroTermos[0].filtros.includes(option.value)) {
          return option;
        }

        return null;
      });

      setOptionsFiltroSelected(optionsFiltro);
    }

    //eslint-disable-next-line
  }, [listaFiltroTermos]);

  return (
    <Fragment>
      <TitleTermo>Termo(s) de Coleta</TitleTermo>
      <div className={styles["container-button-listagem"]}>
        <FiltroTermoColeta
          formFiltro={formFiltro}
          setPageControl={setPageControl}
        />
        <ButtonEnviarBackupTermoColeta />
      </div>
      <Divider />
      {listaFiltroTermos.length > 0 ? (
        <>
          <div className={styles["container-filtro-selected"]}>
            <Typography>Filtro Aplicado:</Typography>
            <Checkbox.Group
              onChange={handleChangeFilterSelect}
              options={optionsFiltroSelected}
              value={checkedList}
              disabled={loading}
            />
          </div>

          {loading ? (
            <div className={styles["container-loading"]}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </div>
          ) : (
            <div className={styles["heigth-list"]}>
              <List
                style={{
                  boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                }}
                bordered
                dataSource={listaFiltroTermos[0].termos}
                renderItem={(item, ind) => {
                  return (
                    <ItemListaFiltroTermoColeta
                      key={ind}
                      item={item}
                      pageControl={pageControl}
                    />
                  );
                }}
              />
            </div>
          )}
          <Pagination
            size={checkDevice() ? "small" : "default"}
            showSizeChanger={false}
            current={pageControl}
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
            pageSize={PAGE_SIZE}
            total={listaFiltroTermos[0].numberPages * PAGE_SIZE}
            onChange={(page) => handleChangePagination(page, true)}
          />
        </>
      ) : (
        <>
          <div className={styles["heigth-list"]}>
            <List
              style={{
                boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
              }}
              bordered
              dataSource={listaTermos.slice(minIndex, maxIndex)}
              renderItem={(item, ind) => (
                <ItemListaTermoColeta
                  key={ind}
                  item={item}
                  pageControl={pageControl}
                />
              )}
            />
          </div>
          <Pagination
            size={checkDevice() ? "small" : "default"}
            showSizeChanger={false}
            current={pageControl}
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
            pageSize={PAGE_SIZE}
            total={listaTermos.length}
            onChange={(page) => handleChangePagination(page)}
          />
        </>
      )}
    </Fragment>
  );
}
