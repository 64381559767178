import {
  DeleteOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { masks } from "@functions/mascaras";
import { useCategoriaSemente } from "@hooks/useCategoriaSemente";
import { useDadosTermoColeta } from "@hooks/useDadosTermoColeta";
import { useEstadioFenologico } from "@hooks/useEstadioFenologico";
import { useMateriaisColetados } from "@hooks/useMateriaisColetados";
import { IAmostra } from "@interfaces/IAmostra";
import { ICategoria } from "@interfaces/ICategoria";
import { ICultivares } from "@interfaces/ICultivares";
import { IEstadioFenologico } from "@interfaces/IEstadioFenologico";
import { IMaterialColetado } from "@interfaces/IMaterialColetado";
import { transformStringToNumber } from "@utils/transformStringToNumber";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import Table, { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { TipoColeta } from "../../../../@types/TipoColeta";
import { FITOSSANITARIA, OFICIAL } from "../../constants";

const REVESTIMENTO_SEMENTES = ["NENHUM", "ENCRUSTADA", "PELOTIZADA"];

const TRATAMENTO_SEMENTES = [" NENHUM", " BENEFICIADA", "TRATADA"];

const ContainerTipoAmostraCSS = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: flex-start;
`;

interface IColumsFitossanitaria {
  latitude: string;
  longitude: string;
  plantaVoluntaria: string;
  plantioExcepcional: string;
  dataPlantio: string;
  cultivar: string;
  materialColetado: string;
  idadeEstadioFenologico: string;
  qtdColetada: string;
}

interface IDadosAmostraProps {
  formDadosAmostra: FormInstance;
  tipoAmostra: TipoColeta;
  cultivares: ICultivares[];
  materialColetadoOptions: IMaterialColetado[] | undefined;
  estadioFenologicoOptions: IEstadioFenologico[] | undefined;
  categoriaOptions: ICategoria[] | undefined;
}

export function AmostrasTermo({
  formDadosAmostra,
  tipoAmostra,
  cultivares,
  materialColetadoOptions,
  estadioFenologicoOptions,
  categoriaOptions,
}: IDadosAmostraProps) {
  const [modal, contextHolder] = Modal.useModal();
  const { listaAmostras, adicionarAmostra, removerAmostra } =
    useDadosTermoColeta();
  const { listaMateriaisColetados } = useMateriaisColetados();
  const { listaEstadioFenologico } = useEstadioFenologico();
  const { listaCategoriaSemente } = useCategoriaSemente();
  const { coordMask } = masks;

  const [oficialAmostraInGrid, setOficialAmostraInGrid] = useState<any[]>([]);
  const [fitoAmostraInGrid, setFitoAmostraInGrid] = useState<
    IColumsFitossanitaria[]
  >([]);
  const [isPlantaVoluntaria, setIsPlantaVoluntaria] = useState(false);
  const [isPlantioExcepcional, setIsPlantioExcepcional] = useState(false);
  const [tetrazolioRequired, setTetrazolioRequired] = useState("");
  const [germinacaoRequired, setGerminacaoRequired] = useState("");

  const columnsFitossanitaria: ColumnsType<IColumsFitossanitaria> = [
    {
      title: "Latitude",
      dataIndex: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
    },
    {
      title: "Planta Voluntaria",
      dataIndex: "plantaVoluntaria",
    },
    {
      title: "Plantio Excepcional",
      dataIndex: "plantioExcepcional",
    },
    {
      title: "Data Plantio",
      dataIndex: "dataPlantio",
      render: (dataPlantio: string) => (
        <p>
          {dataPlantio ? moment(dataPlantio).locale("pt-br").format("L") : ""}
        </p>
      ),
    },
    {
      title: "Cultivar",
      dataIndex: "cultivar",
    },
    {
      title: "Material Coletado",
      dataIndex: "materialColetado",
    },
    {
      title: "Idade/Estadio Fenológico",
      dataIndex: "idadeEstadioFenologico",
    },
    {
      title: "Qtd. Coletada",
      dataIndex: "qtdColetada",
    },
    {
      title: "Ações",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button danger onClick={(e) => modalDeleteConfirm(e, record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const colunmsOficial: ColumnsType<any> = [
    {
      title: "Cultivar",
      dataIndex: "cultivar",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Revestimento",
      dataIndex: "revestimento",
    },
    {
      title: "Lote",
      dataIndex: "lote",
    },
    {
      title: "Representatividade do lote",
      children: [
        {
          title: "N° Embalagens",
          dataIndex: "qtdColetada",
        },
        {
          title: "Peso Liquido(Kg)",
          dataIndex: "peso",
        },
      ],
    },
    {
      title: "Valor Unitário",
      dataIndex: "valor",
    },
    {
      title: "Val. Teste Germ.",
      dataIndex: "validadeTeste",
    },
    {
      title: "Tratamento",
      dataIndex: "tratamento",
    },
    {
      title: "Garantias expressas na embalagem",
      children: [
        {
          title: "Pureza(%)",
          dataIndex: "pureza",
        },
        {
          title: "Germinação(%)",
          dataIndex: "germinacao",
        },
        {
          title: "Tetrazólio(%)",
          dataIndex: "tetrazolio",
        },
        {
          title: "Sementes Duras(%)",
          dataIndex: "sementesDuras",
        },
        {
          title: "Outros Fatores",
          dataIndex: "valorOutrosFatores",
        },
      ],
    },

    {
      title: "Ações",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button danger onClick={(e) => modalDeleteConfirm(e, record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const modalDeleteConfirm = (
    e: React.MouseEvent<HTMLElement>,
    record: any
  ) => {
    modal.confirm({
      title: "Tem certeza que deseja remover amostra?",
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      cancelText: "Não",
      onOk: () => {
        removerAmostra(record.key);
      },
    });
  };

  const handleChangeCoordGeo = (
    e: ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    formDadosAmostra.setFieldValue(name, coordMask(e.target.value));
  };
  const getMaterialColetadoSelected = (materialId: number) => {
    if (
      listaMateriaisColetados.some((material) => material.id === materialId)
    ) {
      return listaMateriaisColetados.filter(
        (material) => material.id === materialId
      )[0];
    }

    return materialColetadoOptions?.filter(
      (material) => material.id === materialId
    )[0];
  };

  const getEstadioFenologicoSelected = (estadioId: number) => {
    if (listaEstadioFenologico.some((estadio) => estadio.id === estadioId)) {
      return listaEstadioFenologico.filter(
        (estadio) => estadio.id === estadioId
      )[0];
    }

    return estadioFenologicoOptions?.filter(
      (estadio) => estadio.id === estadioId
    )[0];
  };

  const getCategoriaSelected = (categoriaId: number) => {
    if (
      listaCategoriaSemente.some((categoria) => categoria.id === categoriaId)
    ) {
      return listaCategoriaSemente.filter(
        (categoria) => categoria.id === categoriaId
      )[0];
    }

    return categoriaOptions?.filter(
      (categoria) => categoria.id === categoriaId
    )[0];
  };

  const handleSubmitAmostra = (values: any) => {
    const { tipoPlantio } = values;

    try {
      const objAmostra: IAmostra = {
        categoria: getCategoriaSelected(values.categoria) || null,
        cultivar:
          cultivares.filter((cultivar) => cultivar.id === values.cultivar)[0] ||
          null,
        dataPlantio: values.dataPlantio
          ? moment(values.dataPlantio).locale("pt-br").format().slice(0, 10)
          : "",
        estadio:
          getEstadioFenologicoSelected(values.idadeEstadioFenologico) || null,
        germinacao: values.germinacao
          ? transformStringToNumber(values.germinacao || "")
          : 0,
        identificacao: parseInt(Date.now().toString().slice(8)),
        latGrau: transformStringToNumber(values.latGrauAmostra || "") || 0,
        latMin: transformStringToNumber(values.latMinAmostra || "") || 0,
        latSeg: transformStringToNumber(values.latSegAmostra || "") || 0,
        longGrau: transformStringToNumber(values.longGrauAmostra || "") || 0,
        longMin: transformStringToNumber(values.longMinAmostra || "") || 0,
        longSeg: transformStringToNumber(values.longSegAmostra || "") || 0,
        lote: values.lote || "",
        nomeOutrosFatores: values.nomeOutrosFatores || "",
        nrAmostraLasgal:
          tipoAmostra === OFICIAL ? `${listaAmostras.length + 1}` : "",
        nrAmostraLsv:
          tipoAmostra === FITOSSANITARIA ? `${listaAmostras.length + 1}` : "",
        partePlanta:
          getMaterialColetadoSelected(values.materialColetado) || null,
        pesoEmbalagem: values.peso
          ? transformStringToNumber(values.peso || "")
          : 0,
        plantaVoluntaria: tipoPlantio
          ? tipoPlantio.includes("plantaVoluntaria")
            ? true
            : false
          : false,
        plantioExcepcional: tipoPlantio
          ? tipoPlantio.includes("plantioExcepcional")
            ? true
            : false
          : false,
        pureza: values.pureza
          ? transformStringToNumber(values.pureza || "")
          : 0,
        qtdEmbalagens: values.qtdColetada
          ? transformStringToNumber(values.qtdColetada || "")
          : 0,
        revestimento: values.revestimento || "NENHUM",
        sementesDura: values.sementesDuras
          ? transformStringToNumber(values.sementesDuras || "")
          : 0,
        tetrazolio: values.tetrazolio
          ? transformStringToNumber(values.tetrazolio || "")
          : 0,
        tratamento: values.tratamento || "NENHUM",
        validadeTesteGerminacao:
          moment(values.validadeTeste).format("MM/YYYY") || "",
        valorEmbalagem: values.valor
          ? transformStringToNumber(values.valor || "")
          : 0,
        valorOutrosFatores: values.valorOutrosFatores
          ? transformStringToNumber(values.valorOutrosFatores || "")
          : 0,
      };

      //VALIDACAO
      if (
        tipoAmostra === FITOSSANITARIA &&
        tipoPlantio &&
        tipoPlantio.length > 1
      ) {
        openNotification({
          type: "error",
          message: "Selecione somente um tipo de Plantio",
        });
        return;
      }

      // console.log(dadosAmostra);
      adicionarAmostra(objAmostra);

      formDadosAmostra.resetFields();
      setIsPlantaVoluntaria(false);
      setIsPlantioExcepcional(false);
      return;
    } catch (error) {
      openNotification({
        type: "error",
        message: "Erro ao adicionar amostra",
        description:
          "Verifique se as informações estão preenchidas corretamente, caso o problema persista entre em contato com o suporte.",
      });
      console.log(error);
    }
  };

  const handleChangeTipoPlantio = (checkedValue: CheckboxValueType[]) => {
    if (checkedValue.some((value) => value === "plantaVoluntaria")) {
      setIsPlantaVoluntaria(true);
      return;
    }
    setIsPlantaVoluntaria(false);

    if (checkedValue.some((value) => value === "plantioExcepcional")) {
      setIsPlantioExcepcional(true);
      return;
    }

    setIsPlantioExcepcional(false);
  };

  const handleCompleteGeoLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition((location) => {
        // console.log(location);
        let latDeg, latMin, latSeg, longDeg, longMin, longSeg;

        let valLat = Math.abs(location.coords.latitude);
        let valLong = Math.abs(location.coords.longitude);

        latDeg = Math.floor(valLat);
        latMin = Math.floor((valLat - latDeg) * 60);
        latSeg =
          Math.round((valLat - latDeg - latMin / 60) * 3600 * 1000) / 1000;

        longDeg = Math.floor(valLong);
        longMin = Math.floor((valLong - longDeg) * 60);
        longSeg =
          Math.round((valLong - longDeg - longMin / 60) * 3600 * 1000) / 1000;

        formDadosAmostra.setFieldsValue({
          latGrauAmostra: coordMask(latDeg.toString() || ""),
          latMinAmostra: coordMask(latMin.toString() || ""),
          latSegAmostra: coordMask(latSeg.toString() || ""),
          longGrauAmostra: coordMask(longDeg.toString() || ""),
          longMinAmostra: coordMask(longMin.toString() || ""),
          longSegAmostra: coordMask(longSeg.toString() || ""),
        });
      });
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel utilizar localização atual",
      });
    }
  };

  const handleChangeInputNumber = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === "germinacao") {
      setGerminacaoRequired(e.target.value);

      if (!e.target.value) {
        formDadosAmostra.setFieldValue("germinacao", "0");
      }
    }

    if (e.target.id === "tetrazolio") {
      setTetrazolioRequired(e.target.value);
      if (!e.target.value) {
        formDadosAmostra.setFieldValue("tetrazolio", "0");
      }
    }

    formDadosAmostra.setFieldValue(
      e.target.id,
      e.target.value.replace(/[^0-9,]/g, "")
    );
  };

  useEffect(() => {
    if (tipoAmostra === FITOSSANITARIA) {
      const auxFito = listaAmostras.map((amostra) => {
        return {
          key: amostra.identificacao,
          latitude: `${coordMask(amostra.latGrau.toString())}°${coordMask(
            amostra.latMin.toString()
          )}'${coordMask(amostra.latSeg.toString())}"`,
          longitude: `${coordMask(amostra.longGrau.toString())}°${coordMask(
            amostra.longMin.toString()
          )}'${coordMask(amostra.longSeg.toString())}"`,
          plantaVoluntaria: amostra.plantaVoluntaria ? "SIM" : "NÃO",
          plantioExcepcional: amostra.plantioExcepcional ? "SIM" : "NÃO",
          dataPlantio: amostra.dataPlantio,
          cultivar: amostra.cultivar ? amostra.cultivar.nome : "",
          materialColetado: amostra.partePlanta ? amostra.partePlanta.nome : "",
          idadeEstadioFenologico: amostra.estadio ? amostra.estadio.nome : "",
          qtdColetada: amostra.qtdEmbalagens.toString().replace(".", ","),
        };
      });

      setFitoAmostraInGrid(auxFito);
    } else if (tipoAmostra === OFICIAL) {
      const auxOficial = listaAmostras.map((amostra) => {
        return {
          key: amostra.identificacao,
          cultivar: amostra.cultivar ? amostra.cultivar.nome : "",
          categoria: amostra.categoria ? amostra.categoria.nome : "",
          revestimento: amostra.revestimento,
          lote: amostra.lote,
          qtdColetada: amostra.qtdEmbalagens.toString().replace(".", ","),
          peso: amostra.pesoEmbalagem.toString().replace(".", ","),
          valor: amostra.valorEmbalagem?.toString().replace(".", ","),
          validadeTeste: amostra.validadeTesteGerminacao,
          tratamento: amostra.tratamento,
          pureza: amostra.pureza?.toString().replace(".", ","),
          germinacao: amostra.germinacao?.toString().replace(".", ","),
          tetrazolio:
            amostra.tetrazolio === 0
              ? ""
              : amostra.tetrazolio?.toString().replace(".", ","),
          sementesDuras: amostra.sementesDura?.toString().replace(".", ","),
          outrosFatores: amostra.valorOutrosFatores
            ?.toString()
            .replace(".", ","),
        };
      });

      setOficialAmostraInGrid(auxOficial);
    } else {
      setFitoAmostraInGrid([]);
      setOficialAmostraInGrid([]);
    }

    // eslint-disable-next-line
  }, [listaAmostras]);

  return (
    <Fragment>
      {contextHolder}
      <Divider />
      <Typography.Title level={3}>Amostras</Typography.Title>
      <Form
        id="form-adicionar-amostra"
        form={formDadosAmostra}
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "200px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        onFinish={handleSubmitAmostra}
      >
        {tipoAmostra === OFICIAL ? (
          <Fragment>
            <div>
              <ContainerTipoAmostraCSS>
                <Form.Item
                  label="Cultivar"
                  name="cultivar"
                  style={{ maxWidth: "200px" }}
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: "150px" }}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(input) ||
                      (option!.children as unknown as string).includes(
                        input.toUpperCase()
                      )
                    }
                  >
                    {cultivares.map((cultivar: any) => (
                      <Select.Option key={cultivar.id} value={cultivar.id}>
                        {cultivar.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Categoria"
                  name="categoria"
                  style={{ maxWidth: "200px" }}
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: "150px" }}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(input) ||
                      (option!.children as unknown as string).includes(
                        input.toUpperCase()
                      )
                    }
                  >
                    {listaCategoriaSemente.length > 0 ? (
                      <Fragment>
                        {listaCategoriaSemente.map((categoria) => (
                          <Select.Option
                            key={categoria.id}
                            value={categoria.id}
                          >
                            {categoria.nome}
                          </Select.Option>
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {categoriaOptions?.map((categoria) => (
                          <Select.Option
                            key={categoria.id}
                            value={categoria.id}
                          >
                            {categoria.nome}
                          </Select.Option>
                        ))}
                      </Fragment>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Revestimento"
                  name="revestimento"
                  style={{ maxWidth: "200px" }}
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    style={{ width: "150px" }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(input) ||
                      (option!.children as unknown as string).includes(
                        input.toUpperCase()
                      )
                    }
                  >
                    {REVESTIMENTO_SEMENTES.map((revestimento) => (
                      <Select.Option value={revestimento} key={revestimento}>
                        {revestimento}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Lote"
                  name="lote"
                  style={{ maxWidth: "200px" }}
                  rules={[{ required: true }]}
                >
                  <Input style={{ width: "150px" }} />
                </Form.Item>

                <Form.Item
                  label="N° Embalagens"
                  name="qtdColetada"
                  style={{ maxWidth: "200px" }}
                  rules={[{ required: true }]}
                >
                  <Input
                    style={{ width: "150px" }}
                    onChange={handleChangeInputNumber}
                  />
                </Form.Item>

                <Form.Item
                  label="Peso Líquido (KG)"
                  name="peso"
                  style={{ maxWidth: "200px" }}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="KG"
                    style={{ width: "150px" }}
                    onChange={handleChangeInputNumber}
                  />
                </Form.Item>

                <Form.Item
                  label="Valor Unitário"
                  name="valor"
                  style={{ maxWidth: "175px" }}
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder="$"
                    style={{ width: "125px" }}
                    onChange={handleChangeInputNumber}
                  />
                </Form.Item>
              </ContainerTipoAmostraCSS>
            </div>

            <ContainerTipoAmostraCSS>
              <Form.Item
                label="Validade do Teste"
                name="validadeTeste"
                style={{ maxWidth: "150px" }}
              >
                <DatePicker
                  picker="month"
                  style={{ width: 110 }}
                  format={"MM/YYYY"}
                />
              </Form.Item>
              <Form.Item
                label="Tratamento"
                name="tratamento"
                style={{ maxWidth: "200px" }}
              >
                <Select
                  allowClear
                  style={{ width: "150px" }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input) ||
                    (option!.children as unknown as string).includes(
                      input.toUpperCase()
                    )
                  }
                >
                  {TRATAMENTO_SEMENTES.map((tratamento) => (
                    <Select.Option key={tratamento} value={tratamento}>
                      {tratamento}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Pureza"
                name="pureza"
                style={{ maxWidth: "150px" }}
                rules={[{ required: true }]}
              >
                <Input
                  style={{ width: "100px" }}
                  placeholder="%"
                  onChange={handleChangeInputNumber}
                />
              </Form.Item>
              <Form.Item
                label="Germinação"
                name="germinacao"
                style={{ maxWidth: "150px" }}
                rules={[
                  { required: tetrazolioRequired.length === 0, message: "" },
                ]}
              >
                <Input
                  style={{ width: "100px" }}
                  placeholder="%"
                  onChange={handleChangeInputNumber}
                />
              </Form.Item>
              <Form.Item
                label="Tetrazólio"
                name="tetrazolio"
                style={{ maxWidth: "150px" }}
                rules={[
                  { required: germinacaoRequired.length === 0, message: "" },
                ]}
              >
                <Input
                  style={{ width: "100px" }}
                  placeholder="%"
                  onChange={handleChangeInputNumber}
                />
              </Form.Item>
              <Form.Item
                label="Sementes Duras"
                name="sementesDuras"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  style={{ width: "100px" }}
                  placeholder="%"
                  onChange={handleChangeInputNumber}
                />
              </Form.Item>
              <Form.Item
                label="Nome outros Fatores"
                name="nomeOutrosFatores"
                style={{ maxWidth: "200px" }}
              >
                <Input style={{ width: "150px" }} />
              </Form.Item>

              <Form.Item
                label="Outros Fatores"
                name="valorOutrosFatores"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  style={{ width: "100px" }}
                  placeholder="%"
                  onChange={handleChangeInputNumber}
                />
              </Form.Item>
            </ContainerTipoAmostraCSS>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                paddingBottom: "20px",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                form="form-adicionar-amostra"
              >
                Adicionar Amostra
              </Button>
            </div>

            <Table
              bordered
              locale={{
                emptyText: (
                  <Empty
                    description={"Sem dados"}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
              }}
              dataSource={oficialAmostraInGrid}
              columns={colunmsOficial}
              style={{
                overflowX: "auto",
              }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <div style={{ padding: "10px 0px" }}>
                <Tooltip title="Usar localização atual" placement="right">
                  <Button onClick={handleCompleteGeoLocation}>
                    <EnvironmentOutlined />
                  </Button>
                </Tooltip>
              </div>

              <ContainerTipoAmostraCSS style={{ gap: 40 }}>
                {/**LATITUDE */}
                <div>
                  <Typography.Title level={4}>Latitude</Typography.Title>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <Form.Item
                      label="Grau"
                      name="latGrauAmostra"
                      style={{ maxWidth: "150px" }}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{ width: 100 }}
                        onChange={(e) =>
                          handleChangeCoordGeo(e, "latGrauAmostra")
                        }
                        maxLength={5}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Minutos"
                      name="latMinAmostra"
                      style={{ maxWidth: "150px" }}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{ width: 100 }}
                        onChange={(e) =>
                          handleChangeCoordGeo(e, "latMinAmostra")
                        }
                        maxLength={5}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Segundos"
                      name="latSegAmostra"
                      style={{ maxWidth: "150px" }}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{ width: 100 }}
                        onChange={(e) =>
                          handleChangeCoordGeo(e, "latSegAmostra")
                        }
                        maxLength={5}
                      />
                    </Form.Item>
                  </div>
                </div>

                {/**LONGITUDE */}
                <div>
                  <Typography.Title level={4}>Longitude</Typography.Title>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <Form.Item
                      label="Grau"
                      name="longGrauAmostra"
                      style={{ maxWidth: "150px" }}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{ width: 100 }}
                        onChange={(e) =>
                          handleChangeCoordGeo(e, "longGrauAmostra")
                        }
                        maxLength={5}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Minutos"
                      name="longMinAmostra"
                      style={{ maxWidth: "150px" }}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{ width: 100 }}
                        onChange={(e) =>
                          handleChangeCoordGeo(e, "longMinAmostra")
                        }
                        maxLength={5}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Segundos"
                      name="longSegAmostra"
                      style={{ maxWidth: "150px" }}
                      rules={[{ required: true }]}
                    >
                      <Input
                        style={{ width: 100 }}
                        onChange={(e) =>
                          handleChangeCoordGeo(e, "longSegAmostra")
                        }
                        maxLength={5}
                      />
                    </Form.Item>
                  </div>
                </div>
              </ContainerTipoAmostraCSS>

              {/**OUTROS CAMPOS */}
              <div
                style={{ display: "flex", flexWrap: "wrap", alignItems: "end" }}
              >
                <Form.Item name="tipoPlantio" style={{ maxWidth: "250px" }}>
                  <Checkbox.Group
                    onChange={handleChangeTipoPlantio}
                    style={{ display: "flex" }}
                  >
                    <Checkbox value="plantaVoluntaria">
                      Planta Voluntaria
                    </Checkbox>
                    <Checkbox value="plantioExcepcional">
                      Plantio Excepcional
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>

                <Form.Item
                  label="Data Plantio"
                  name="dataPlantio"
                  style={{ maxWidth: "150px" }}
                  rules={[{ required: !isPlantaVoluntaria }]}
                >
                  <DatePicker
                    format={"DD/MM/YYYY"}
                    style={{
                      minWidth: "100px",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Cultivar"
                  name="cultivar"
                  style={{ maxWidth: "200px" }}
                  rules={[
                    { required: !isPlantaVoluntaria && !isPlantioExcepcional },
                  ]}
                >
                  <Select
                    style={{ width: "150px" }}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(input) ||
                      (option!.children as unknown as string).includes(
                        input.toUpperCase()
                      )
                    }
                  >
                    {cultivares.map((cultivar: any) => (
                      <Select.Option key={cultivar.id} value={cultivar.id}>
                        {cultivar.nome}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Material coletado"
                  name="materialColetado"
                  style={{ maxWidth: "200px" }}
                  rules={[{ required: true }]}
                >
                  <Select style={{ width: "150px" }}>
                    {listaMateriaisColetados.length > 0 ? (
                      <Fragment>
                        {listaMateriaisColetados.map((material) => (
                          <Select.Option key={material.id} value={material.id}>
                            {material.nome}
                          </Select.Option>
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {materialColetadoOptions?.map((material) => (
                          <Select.Option key={material.id} value={material.id}>
                            {material.nome}
                          </Select.Option>
                        ))}
                      </Fragment>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Idade/estadio Fen."
                  name="idadeEstadioFenologico"
                  style={{ maxWidth: "200px" }}
                  rules={[{ required: true }]}
                >
                  <Select style={{ width: "150px" }}>
                    {listaEstadioFenologico.length > 0 ? (
                      <Fragment>
                        {listaEstadioFenologico.map((estadio) => (
                          <Select.Option key={estadio.id} value={estadio.id}>
                            {estadio.nome}
                          </Select.Option>
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {estadioFenologicoOptions?.map((estadio) => (
                          <Select.Option key={estadio.id} value={estadio.id}>
                            {estadio.nome}
                          </Select.Option>
                        ))}
                      </Fragment>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Qtd."
                  name="qtdColetada"
                  style={{ maxWidth: "125px" }}
                  rules={[{ required: true }]}
                >
                  <Input
                    style={{ width: "75px" }}
                    onChange={handleChangeInputNumber}
                  />
                </Form.Item>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                padding: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                form="form-adicionar-amostra"
              >
                Adicionar Amostra
              </Button>
            </div>

            <Table
              bordered
              locale={{
                emptyText: (
                  <Empty
                    description={"Sem dados"}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
              }}
              dataSource={fitoAmostraInGrid}
              columns={columnsFitossanitaria}
              style={{
                overflowX: "auto",
              }}
            />
          </Fragment>
        )}
      </Form>
    </Fragment>
  );
}
