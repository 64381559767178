import {
  DisconnectOutlined,
  DownloadOutlined,
  FileAddFilled,
  FileOutlined,
  FilePdfOutlined,
  FormOutlined,
  HomeOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useMenu } from "@hooks/useMenu";
import { usePermission } from "@hooks/usePermission";
import { Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ADMIN_ID = 1;

export function MenuSystem({ isSidebar }: { isSidebar?: boolean }) {
  const navigate = useNavigate();
  const { permissoes, userController } = usePermission();
  const { atualizarMenuSelected, menuSelected, listaMenus } = useMenu();
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);
  const menuOptions = listaMenus.map((item) => {
    const infoGeral = {
      key: item?.id,
      label: item?.nome,
    };

    if (!item) return null;

    if (item.codigo === "inicio") {
      return {
        ...infoGeral,
        icon: <HomeOutlined />,
        onClick: () => navigate(item.url),
      };
    }

    if (item.codigo === "offlineMode") {
      return {
        ...infoGeral,
        icon: <DisconnectOutlined />,
        onClick: () => navigate(item.url),
      };
    }

    if (
      item.codigo === "Perfil" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        icon: <UsergroupAddOutlined />,
        onClick: () => navigate(item.url),
      };
    }

    if (
      item.codigo === "config" &&
      userController?.perfisId.includes(ADMIN_ID)
    ) {
      const children = item.filhos.map((filho) => {
        const auxChildren = {
          key: filho.id,
          label: filho.nome,
          onClick: () => navigate(filho.url),
        };

        if (filho.codigo === "tifConfig") {
          return {
            ...auxChildren,
            icon: <FormOutlined />,
          };
        }

        return auxChildren;
      });
      return {
        ...infoGeral,
        icon: <SettingOutlined />,
        children: children,
      };
    }

    if (
      item.codigo === "TIF" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      const children = item.filhos.map((filho) => {
        const auxChildren = {
          key: filho.id,
          label: filho.nome,
          onClick: () => navigate(filho.url),
        };

        if (filho.codigo === "TIFNovo") {
          const isAddTermo = permissoes.filter(
            (perm) => perm.menu.id === item.id
          );

          if (isAddTermo.length) {
            return {
              ...auxChildren,
              icon: <FileAddFilled />,
              disabled: !isAddTermo[0].adicionar,
            };
          }

          return {
            ...auxChildren,
            icon: <FileAddFilled />,
            disabled: false,
          };
        }

        if (filho.codigo === "TIFListagem") {
          return {
            ...auxChildren,
            icon: <UnorderedListOutlined />,
          };
        }

        return auxChildren;
      });

      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: children,
      };
    }

    if (
      item.codigo === "TermoColeta" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      const children = item.filhos.map((filho) => {
        const auxChildren = {
          key: filho.id,
          label: filho.nome,
          onClick: () => navigate(filho.url),
        };
        if (filho.codigo === "TermoColetaNovo") {
          const isAddTermo = permissoes.filter(
            (perm) => perm.menu.id === item.id
          );

          if (isAddTermo.length) {
            return {
              ...auxChildren,
              icon: <FileAddFilled />,
              disabled: !isAddTermo[0].adicionar,
            };
          }

          return {
            ...auxChildren,
            icon: <FileAddFilled />,
            disabled: false,
          };
        }

        if (filho.codigo === "TermoColetaListagem") {
          return {
            ...auxChildren,
            icon: <UnorderedListOutlined />,
          };
        }

        return auxChildren;
      });

      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: children,
      };
    }

    if (
      item.codigo === "TermoApreensaoLiberacao" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      const children = item.filhos.map((filho) => {
        const auxChildren = {
          key: filho.id,
          label: filho.nome,
          onClick: () => navigate(filho.url),
        };
        if (filho.codigo === "TermoApreensaoLiberacaoNovo") {
          const isAddTermo = permissoes.filter(
            (perm) => perm.menu.id === item.id
          );

          if (isAddTermo.length) {
            return {
              ...auxChildren,
              icon: <FileAddFilled />,
              disabled: !isAddTermo[0].adicionar,
            };
          }

          return {
            ...auxChildren,
            icon: <FileAddFilled />,
            disabled: false,
          };
        }

        if (filho.codigo === "TermoApreensaoLiberacaoListagem") {
          return {
            ...auxChildren,
            icon: <UnorderedListOutlined />,
          };
        }

        return auxChildren;
      });

      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: children,
      };
    }
    if (
      item.codigo === "TermoVistoria" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      const children = item.filhos.map((filho) => {
        const auxChildren = {
          key: filho.id,
          label: filho.nome,
          onClick: () => navigate(filho.url),
        };
        if (filho.codigo === "TermoVistoriaNovo") {
          const isAddTermo = permissoes.filter(
            (perm) => perm.menu.id === item.id
          );

          if (isAddTermo.length) {
            return {
              ...auxChildren,
              icon: <FileAddFilled />,
              disabled: !isAddTermo[0].adicionar,
            };
          }

          return {
            ...auxChildren,
            icon: <FileAddFilled />,
            disabled: false,
          };
        }

        if (filho.codigo === "TermoVistoriaListagem") {
          return {
            ...auxChildren,
            icon: <UnorderedListOutlined />,
          };
        }
        return auxChildren;
      });

      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: children,
      };
    }

    if (
      item.codigo === "TID" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      const children = item.filhos.map((filho) => {
        const auxChildren = {
          key: filho.id,
          label: filho.nome,
          onClick: () => navigate(filho.url),
        };
        if (filho.codigo === "TIDNovo") {
          const isAddTermo = permissoes.filter(
            (perm) => perm.menu.id === item.id
          );

          if (isAddTermo.length) {
            return {
              ...auxChildren,
              icon: <FileAddFilled />,
              disabled: !isAddTermo[0].adicionar,
            };
          }

          return {
            ...auxChildren,
            icon: <FileAddFilled />,
            disabled: false,
          };
        }

        if (filho.codigo === "TIDListagem") {
          return {
            ...auxChildren,
            icon: <UnorderedListOutlined />,
          };
        }
        return auxChildren;
      });

      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: children,
      };
    }

    if (
      item.codigo === "LASGAL" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        key: 100,
        icon: <FileOutlined />,
        children: [
          {
            key: item.id,
            label: item.titulo,
            icon: <UnorderedListOutlined />,
            onClick: () => navigate(item.url),
          },
        ],
      };
    }

    return null;
  });

  const itemsMenu: ItemType[] = [
    ...menuOptions,
    {
      key: 50,
      icon: <FilePdfOutlined />,
      label: "Manual de Uso",
      onClick: () => {
        window.open(
          "https://drive.google.com/file/d/1a0Q4iahmt9_9nnnIdFvdEbyDMnsiEvRW/view?usp=drive_link"
        );
      },
    },
  ];

  const handleChangeInstallPwa = () => {
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      //console.log("da pra instalar");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler, false);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const handleChangeMenuSelect = ({ _, key }: any) => {
    atualizarMenuSelected([key]);
  };

  useEffect(() => {
    const rota = window.location.pathname.slice(1);

    if (rota === "home") {
      atualizarMenuSelected(["1"]);
      document.title = "Início | SISDEV-mobile";
      return;
    }

    if (rota === "TIF/novo") {
      atualizarMenuSelected(["14"]);
      document.title = "Novo TIF | SISDEV-mobile";
      return;
    }

    if (rota === "TIF/listagem") {
      atualizarMenuSelected(["15"]);
      document.title = "Lista de TIF(s) | SISDEV-mobile";
      return;
    }

    if (rota === "termo-coleta/novo") {
      atualizarMenuSelected(["12"]);
      document.title = "Novo Termo de Coleta | SISDEV-mobile";
      return;
    }

    if (rota === "termo-coleta/listagem") {
      atualizarMenuSelected(["13"]);
      document.title = "Lista de Termo(s) de Coleta | SISDEV-mobile";
      return;
    }

    if (rota === "termo-apreensao-liberacao/novo") {
      atualizarMenuSelected(["10"]);
      document.title = "Novo Termo de Apreensão/Liberação | SISDEV-mobile";
      return;
    }

    if (rota === "termo-apreensao-liberacao/listagem") {
      atualizarMenuSelected(["11"]);
      document.title =
        "Lista de Termo(s) de Apreensão/Liberação | SISDEV-mobile";
      return;
    }

    if (rota === "termo-vistoria/novo") {
      atualizarMenuSelected(["8"]);
      document.title = "Novo Termo de Vistoria | SISDEV-mobile";
      return;
    }

    if (rota === "termo-vistoria/listagem") {
      atualizarMenuSelected(["9"]);
      document.title = "Lista de Termo(s) de Vistoria | SISDEV-mobile";
      return;
    }

    if (rota === "offline-mode") {
      atualizarMenuSelected(["2"]);
      document.title = "Sincronização de Dados | SISDEV-mobile";
      return;
    }

    if (rota === "perfis") {
      atualizarMenuSelected(["7"]);
      document.title = "Gerenciamento de Perfis | SISDEV-mobile";
      return;
    }

    if (rota === "config/TIF") {
      atualizarMenuSelected(["21"]);
      document.title = "Configurações TIF | SISDEV-mobile";
      return;
    }

    if (rota === "LASGAL/listagem") {
      atualizarMenuSelected(["19"]);
      document.title = "Lista de amostras LASGAL | SISDEV-mobile";
      return;
    }

    if (rota === "TIF/edit") {
      document.title = "Edição TIF | SISDEV-mobile";
      return;
    }

    if (rota.includes("LASGAL/novo")) {
      document.title = "Novo recebimento de amostra LASGAL | SISDEV-mobile";
      return;
    }

    if (rota.includes("LASGAL/teste")) {
      document.title = "Testes de amostra LASGAL | SISDEV-mobile";
      return;
    }

    if (rota.includes("TIF/listagem/visualizar")) {
      document.title = "Visualização TIF | SISDEV-mobile";
      return;
    }

    if (rota.includes("TIF/edit/atividades-assinatura")) {
      document.title = "Edição TIF pós envio | SISDEV-mobile";
      return;
    }

    if (rota.includes("TIF/listagem/impressao")) {
      document.title = "Impressão TIF | SISDEV-mobile";
      return;
    }

    if (rota === "termo-coleta/edit") {
      document.title = "Edição Termo de coleta | SISDEV-mobile";
      return;
    }

    if (rota.includes("termo-coleta/listagem/visualizar")) {
      document.title = "Visualização Termo de Coleta | SISDEV-mobile";
      return;
    }

    if (rota === "termo-apreensao-liberacao/edit") {
      document.title = "Edição Termo Apreensão/Liberação | SISDEV-mobile";
      return;
    }

    if (rota.includes("termo-apreensao-liberacao/listagem/visualizar")) {
      document.title =
        "Visualização Termo de Apreensão/Liberação | SISDEV-mobile";
      return;
    }

    if (rota === "termo-vistoria/edit") {
      document.title = "Edição Termo de vistoria | SISDEV-mobile";
      return;
    }

    if (rota.includes("termo-vistoria/listagem/visualizar")) {
      document.title = "Visualização Termo de Vistoria | SISDEV-mobile";
      return;
    }

    if (rota.includes("termo-vistoria/listagem/impressao")) {
      document.title = "Impressão Termo de Vistoria | SISDEV-mobile";
      return;
    }

    if (rota.includes("TID/novo")) {
      atualizarMenuSelected(["17"]);
      document.title = "Novo TID | SISDEV-mobile";
      return;
    }

    if (rota.includes("TID/listagem")) {
      atualizarMenuSelected(["18"]);
      document.title = "Lista de TID(s) | SISDEV-mobile";
      return;
    }

    // eslint-disable-next-line
  }, [window.location.href]);

  useEffect(() => {
    if (localStorage.getItem("push-notification")) {
      window.Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notify = new Notification("Sincronizar dados", {
            body: "Gostaria de sincronizar dados para uso no modo Offline?",
            dir: "auto",
            tag: "SINCRONIZAR-DADOS",
          });

          notify.onclick = (e) => {
            e.preventDefault();
            atualizarMenuSelected(["2"]);
            window.location.href =
              process.env.REACT_APP_REDIRECT_URI + "/offline-mode";
          };

          notify.close();
        }
      });
      setTimeout(() => {
        localStorage.removeItem("push-notification");
      }, 1000);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Menu
      theme={isSidebar ? "dark" : "light"}
      mode={isSidebar ? "inline" : "vertical"}
      defaultSelectedKeys={["5"]}
      selectedKeys={menuSelected}
      onClick={handleChangeMenuSelect}
      items={
        isSidebar
          ? [
              ...itemsMenu,
              {
                key: 60,
                icon: <DownloadOutlined />,
                label: "Instalação",
                onClick: () => handleChangeInstallPwa(),
                disabled: !supportsPWA,
              },
            ]
          : itemsMenu
      }
    />
  );
}
