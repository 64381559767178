import { IAnexos } from "@interfaces/IAnexos";
import { RcFile } from "antd/lib/upload";

const converterFileToBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getAnexosFormatadosBase64 = async (anexos: any[]) => {
  //pegar anexos
  const aux: IAnexos[] = [];

  for (let i = 0; i < anexos.length; i++) {
    if (anexos[i].originFileObj) {
      const value = {
        nomeArquivo: anexos[i].name,
        nomeUpload: anexos[i].uid,
        arquivo: await converterFileToBase64(anexos[i].originFileObj as RcFile),
      };
      aux.push(value);
    } else {
      aux.push({
        nomeArquivo: anexos[i].name,
        nomeUpload: anexos[i].uid,
        arquivo: anexos[i].url,
      });
    }
  }
  //devolver um anexo com uid, nome e url
  return aux;
};
