import {
  CloseOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { IContaminante } from "@interfaces/IContaminante";
import { ITabelaContaminantesInformados } from "@interfaces/ITabelaContaminantesInformados";
import { ITabelaTesteLasgal } from "@interfaces/ITabelaTesteLasgal";
import { apiService } from "@services/api";
import { Button, Empty, Input, Space, Spin, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useQuery } from "react-query";
import { transformStringToNumber } from "../../../../../utils/transformStringToNumber";
import { EdicaoCelulaTabelaDOSN } from "./components/EdicaoCelulaTabelaDOSN";
import { EdicaoLinhaTabelaDOSN } from "./components/EdicaoLinhaTabelaDOSN";
import styles from "./style.module.css";

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

interface ITabelaContaminante {
  key: number;
  nomeCientifico: string;
  grupo: string;
  dadosContaminante: IContaminante;
}

interface IDOSNProps {
  setContaminantesInformados: React.Dispatch<
    React.SetStateAction<ITabelaContaminantesInformados[]>
  >;
  contaminantesInformados: ITabelaContaminantesInformados[];
  setDataTable: React.Dispatch<React.SetStateAction<ITabelaTesteLasgal[]>>;
  dataTable: ITabelaTesteLasgal[];
}

export function DOSN({
  contaminantesInformados,
  setContaminantesInformados,
  setDataTable,
  dataTable,
}: IDOSNProps) {
  const [optionsContaminantes, setOptionsContaminantes] = useState<
    ITabelaContaminante[]
  >([]);
  const { status, data } = useQuery({
    queryKey: "CONTAMINANTES",
    queryFn: () => apiService.contaminantes(),
    onSuccess: (data) => {
      setOptionsContaminantes(
        data.map((contaminante) => {
          return {
            key: contaminante.id,
            nomeCientifico: contaminante.especie.nome,
            grupo: contaminante.nociva || "",
            dadosContaminante: contaminante,
          };
        })
      );
    },
  });

  const columnsContaminantes: ColumnsType<ITabelaContaminante> = [
    {
      title: "Nome Científico",
      align: "center",
      dataIndex: "nomeCientifico",
      render: (value, record) => (
        <div
          className={styles["tabela-item"]}
          onClick={() => transferirItem(record)}
        >
          {value}
        </div>
      ),
    },

    {
      title: "Grupo",
      dataIndex: "grupo",
      align: "center",
      render: (value, record) => (
        <div
          className={styles["tabela-item"]}
          onClick={() => transferirItem(record)}
        >
          {value}
        </div>
      ),
    },
  ];

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "Nome Cientifico",
      dataIndex: "nomeCientifico",
      width: "40%",
    },
    {
      title: "Quantidade",
      dataIndex: "quantidade",
      align: "center",
      editable: true,
      width: "20%",
    },
    {
      title: "Grupo",
      dataIndex: "grupo",
      align: "center",
      width: "25%",
    },
    {
      title: "Ações",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "15%",
      render: (_, record: any) => (
        <Space size="middle">
          <Tooltip title="Remover">
            <Button danger onClick={(e) => removerItem(record)}>
              <CloseOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const components = {
    body: {
      row: EdicaoLinhaTabelaDOSN,
      cell: EdicaoCelulaTabelaDOSN,
    },
  };

  const informadosColumns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ITabelaContaminantesInformados) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleSave = (row: ITabelaContaminantesInformados) => {
    const newData = [...contaminantesInformados];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setContaminantesInformados(newData);

    const contaminantesGrupoSelected = newData.filter(
      (contaminante) => contaminante.grupo === item.grupo
    );

    const totalContaminantesGrupo = getQuantidadeItem(
      contaminantesGrupoSelected
    );

    preencherTabela(item, totalContaminantesGrupo);
  };

  const transferirItem = (item: ITabelaContaminante) => {
    if (
      contaminantesInformados.some(
        (contaminante) => contaminante.key === item.key
      )
    ) {
      openNotification({
        type: "error",
        message: "Não foi possivel adicionar contaminante",
        description: "Contaminante já foi inserida na lista",
      });
      return;
    }

    setContaminantesInformados([
      ...contaminantesInformados,
      {
        key: item.key,
        nomeCientifico: item.nomeCientifico,
        grupo: item.grupo,
        quantidade: "0",
        dadosContaminante: item.dadosContaminante,
      },
    ]);
  };

  const removerItem = (record: ITabelaContaminante) => {
    const auxLista = contaminantesInformados.filter(
      (item) => item.key !== record.key
    );
    setContaminantesInformados(auxLista);

    const contaminantesGrupoSelected = auxLista.filter(
      (contaminante) => contaminante.grupo === record.grupo
    );

    const totalContaminantesGrupo = getQuantidadeItem(
      contaminantesGrupoSelected
    );

    preencherTabela(record, totalContaminantesGrupo);
  };

  const preencherTabela = (item: ITabelaContaminante, total: string) => {
    if (item.grupo === "PROIBIDA") {
      setDataTable([
        dataTable[0],
        {
          ...dataTable[1],
          proibidas: total.replace(".", ","),
        },
      ]);
    } else if (item.grupo === "OESP") {
      setDataTable([
        dataTable[0],
        {
          ...dataTable[1],
          outraCultivadas: total.replace(".", ","),
        },
      ]);
    } else if (item.grupo === "SILVESTRE") {
      setDataTable([
        dataTable[0],
        {
          ...dataTable[1],
          silvestres: total.replace(".", ","),
        },
      ]);
    } else {
      setDataTable([
        dataTable[0],
        {
          ...dataTable[1],
          toleradas: total.replace(".", ","),
        },
      ]);
    }
  };

  const getQuantidadeItem = (
    contaminanteGrupo: ITabelaContaminantesInformados[]
  ) => {
    let qntd = 0;

    contaminanteGrupo.forEach((contaminante) => {
      qntd = qntd + transformStringToNumber(contaminante.quantidade);
    });

    if (qntd > 0 && qntd < 1) {
      return qntd.toFixed(3);
    } else if (qntd >= 1 && qntd < 100) {
      return qntd.toFixed(2);
    } else if (qntd >= 100 && qntd < 1000) {
      return qntd.toFixed(1);
    } else {
      return qntd.toFixed(0);
    }
  };

  if (status === "error") {
    return (
      <>
        <p>Não foi possivel carregar lista de contaminantes</p>
      </>
    );
  } else if (status === "idle" || status === "loading") {
    return (
      <>
        <div className={styles["container-loading"]}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <h1 className={styles.title}>DOSN</h1>

        <div className={styles.container}>
          <div className={styles["content-card"]}>
            <div style={{ height: 8 }} />

            <div>
              <h1 style={{ marginBottom: 0 }}>Contaminantes informados:</h1>
              <Table
                size="small"
                components={components}
                dataSource={contaminantesInformados}
                columns={informadosColumns as ColumnTypes}
                pagination={false}
                scroll={{ y: 500 }}
                style={{ overflowX: "auto" }}
                bordered
                locale={{
                  emptyText: (
                    <Empty
                      description={"Sem Contaminantes informados"}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  ),
                }}
              />
            </div>
          </div>

          <div className={styles["content-card"]}>
            <div>
              <Input
                placeholder="Buscar contaminante pelo nome cientifico"
                prefix={<SearchOutlined />}
                onChange={(e) => {
                  const { value } = e.target;

                  const filtroContaminante = data.filter((contaminante) => {
                    if (
                      contaminante.categoriaSemente.nome.includes(
                        value.toUpperCase()
                      )
                    ) {
                      return contaminante;
                    }

                    return null;
                  });

                  setOptionsContaminantes(
                    filtroContaminante.map((contaminante) => {
                      return {
                        key: contaminante.id,
                        nomeCientifico: contaminante.especie.nome,
                        grupo: contaminante.nociva || "",
                        dadosContaminante: contaminante,
                      };
                    })
                  );
                }}
              />
            </div>

            <Table
              size="small"
              columns={columnsContaminantes}
              scroll={{ y: 500 }}
              pagination={false}
              bordered
              dataSource={optionsContaminantes}
              style={{ overflowX: "auto" }}
            />
          </div>
        </div>
      </>
    );
  }
}
