import { IUnidadeMedida } from "@interfaces/IUnidadeMedida";
import { unidadeMedidaAction } from "@store/actions/unidadeMedidaAction";
import { getUnidadeMedida } from "@store/feature/unidadeMedidaSlice";
import { useDispatch, useSelector } from "react-redux";

export const useUnidadeMedida = () => {
  const listaUnidadeMedida = useSelector(getUnidadeMedida);
  const { addUnidadeMedida, removeUnidadeMedida } = unidadeMedidaAction;
  const appDispatch = useDispatch();

  const adicionarUnidadesMedidas = (
    unidades: IUnidadeMedida[],
    persistedAt: number
  ) => {
    appDispatch(addUnidadeMedida(unidades, persistedAt));
  };

  const removerUnidadesMedida = () => {
    appDispatch(removeUnidadeMedida());
  };

  return {
    listaUnidadeMedida,
    adicionarUnidadesMedidas,
    removerUnidadesMedida,
  };
};
