import { useAtividadeInterditada } from "@hooks/useAtividadeInterditada";
import { IAtividadeTIF } from "@interfaces/IAtividadeTIF";
import { Form, Input, Select } from "antd";

export function AtividadeTID({
  atividadeOptions,
  isInterdicao,
  isTifSelected,
}: {
  atividadeOptions?: IAtividadeTIF[];
  isInterdicao: boolean;
  isTifSelected: boolean;
}) {
  const { listaAtividadesInterditadas } = useAtividadeInterditada();

  const atividadeInterditadas = atividadeOptions?.filter((atv) => {
    if (
      listaAtividadesInterditadas?.find(
        (itemInterditado) => itemInterditado.atividade.id === atv.atividade.id
      ) &&
      listaAtividadesInterditadas?.find(
        (itemInterditado) =>
          itemInterditado.areaAtividade.id === atv.areaAtividade.id
      )
    ) {
      return atv;
    }

    return null;
  });

  return (
    <>
      <Form.Item
        label={`Atividade ${isInterdicao ? "Interditada" : "Desinterditada"}`}
        name="atividadesId"
        rules={[{ required: true }]}
      >
        <Select
          allowClear
          mode="multiple"
          disabled={!isTifSelected}
          options={atividadeInterditadas?.map((atv) => {
            return {
              value: atv.id,
              label: atv.atividade.nome,
            };
          })}
        />
      </Form.Item>
      <Form.Item label="Amparo Legal" name="amparoLegal">
        <Input.TextArea rows={4} maxLength={1000} showCount />
      </Form.Item>
    </>
  );
}
