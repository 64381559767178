import { useCategoriaSemente } from "@hooks/useCategoriaSemente";
import { useEspecie } from "@hooks/useEspecie";
import { useEstadioFenologico } from "@hooks/useEstadioFenologico";
import { useMateriaisColetados } from "@hooks/useMateriaisColetados";
import { apiService } from "@services/api";
import { useQuery } from "react-query";

const erroRequest = (erro: unknown) => {
  console.log(erro);
};

export function useQueriesTermoColeta() {
  const { listaEspecies } = useEspecie();
  const { listaCategoriaSemente } = useCategoriaSemente();
  const { listaEstadioFenologico } = useEstadioFenologico();
  const { listaMateriaisColetados } = useMateriaisColetados();

  const materialColetado = useQuery({
    queryKey: ["MATERIAL_COLETADO"],
    initialData: listaMateriaisColetados,
    queryFn: () => apiService.materialColetado(),
    onSuccess: (data) => data,
    onError: (error) => erroRequest(error),
  });

  const estadios = useQuery({
    queryKey: ["ESTADIO_FENOLOGICO"],
    initialData: listaEstadioFenologico,
    queryFn: () => apiService.estadiosFenologicos(),
    onSuccess: (data) => data,
    onError: (error) => erroRequest(error),
  });

  const especies = useQuery({
    queryKey: ["ESPECIE"],
    initialData: listaEspecies,
    queryFn: () => apiService.especie(),
    onSuccess: (data) => data,
    onError: (error) => erroRequest(error),
  });

  const categorias = useQuery({
    queryKey: ["CATEGORIA_SEMENTE"],
    initialData: listaCategoriaSemente,
    queryFn: () => apiService.categorias(),
    onSuccess: (data) => data,
    onError: (error) => erroRequest(error),
  });

  return {
    categorias,
    materialColetado,
    estadios,
    especies,
  };
}
