import { IOficialAmostrasPDF } from "@interfaces/IOficialAmosrasPDF";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { Fragment } from "react";
import { ContainerAmostraPDF } from "../../../styles/ContainerAmostraPDF";
import styles from "./style.module.css";

export function BodyOficialImpressao({
  termo,
  listaAmostras,
}: {
  termo: ITermoColetaAmostras;
  listaAmostras: IOficialAmostrasPDF[];
}) {
  return (
    <>
      <tbody>
        <tr>
          <td
            style={{
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <div style={{ minHeight: "50vh", borderBottom: "1px solid black" }}>
              <div
                style={{
                  border: "1px solid black",
                }}
              >
                {/**TITULO */}
                <div className={styles.background} style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "50%",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                      paddingLeft: 5,
                    }}
                  >
                    Termo de Coleta de Amostra N.º :
                  </div>
                  <div
                    style={{
                      width: "50%",
                      paddingLeft: 5,
                      borderBottom: "1px solid black",
                    }}
                  >
                    Termo de Fiscalização N.º :
                  </div>
                </div>
                {/**CONTEUDO */}
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "50%",
                      borderRight: "1px solid black",
                      paddingLeft: 5,
                    }}
                  >
                    {termo?.termoColetaAmostra}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      paddingLeft: 5,
                    }}
                  >
                    {termo?.termoFiscalizacao}
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                }}
              ></div>

              <div className={styles.background} style={{ display: "flex" }}>
                <div
                  style={{
                    width: "35%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                  }}
                >
                  Especie:
                </div>
                <div
                  style={{
                    width: "10%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                  }}
                >
                  Safra:
                </div>
                <div
                  style={{
                    width: "35%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                  }}
                >
                  Número do RENASEM do Produtor, ou Reembalador, Comerciante da
                  Semente :
                </div>
                <div
                  style={{
                    width: "20%",
                    paddingLeft: 5,
                  }}
                >
                  País de Origem (Quando Importada):
                </div>
              </div>

              {/**CONTEUDO */}
              <div style={{ display: "flex", borderBottom: "1px solid black" }}>
                <div
                  style={{
                    width: "35%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                  }}
                >
                  {termo?.especie?.nome}
                </div>
                <div
                  style={{
                    width: "10%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                  }}
                >
                  {termo?.safra}
                </div>
                <div
                  style={{
                    width: "35%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                  }}
                >
                  {termo?.renasemOrigemSementes}
                </div>
                <div
                  style={{
                    width: "20%",
                    paddingLeft: 5,
                  }}
                >
                  {termo?.paisOrigem}
                </div>
              </div>

              {/**TABELA */}

              <div
                className={styles.background}
                style={{
                  display: "flex",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  height: 80,
                }}
              >
                <ContainerAmostraPDF width="5%" hasBorder>
                  Amostra N°:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Cultivar:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Categoria:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Lote N°:
                </ContainerAmostraPDF>

                <div style={{ width: "15%", borderRight: "1px solid black" }}>
                  <div
                    style={{ borderBottom: "1px solid black", width: "100%" }}
                  >
                    <span>Representatividade do Lote</span>
                  </div>

                  <div style={{ display: "flex", height: 58 }}>
                    <ContainerAmostraPDF width="50%" hasBorder>
                      Nº de Embalagens
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="50%">
                      Peso liquido
                    </ContainerAmostraPDF>
                  </div>
                </div>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Valor unitário:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Validade do teste de germinação:
                </ContainerAmostraPDF>

                <div style={{ width: "30%" }}>
                  <div
                    style={{ borderBottom: "1px solid black", width: "100%" }}
                  >
                    <span>Garantias expressas na embalagem</span>
                  </div>

                  <div style={{ display: "flex", height: 58 }}>
                    <ContainerAmostraPDF width="20%" hasBorder>
                      Pureza (%)
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="20%" hasBorder>
                      Germinação (%)
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="20%" hasBorder>
                      Tetrazólio (%)
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="20%" hasBorder>
                      Sementes Duras (%)
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="20%">
                      Outros fatores
                    </ContainerAmostraPDF>
                  </div>
                </div>
              </div>

              <div style={{ minHeight: 100, borderBottom: "1px solid black" }}>
                {listaAmostras?.map((amostra, ind) => {
                  return (
                    <Fragment key={ind}>
                      <div
                        style={{
                          display: "flex",
                          textAlign: "center",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <ContainerAmostraPDF width="5%" hasBorder>
                          {ind + 1}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.cultivar}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.categoria}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.lote}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="7.5%" hasBorder>
                          {amostra.qtdEmbalagens?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="7.5%" hasBorder>
                          {amostra.pesoEmbalagens?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.valorEmbalagens
                            ?.toString()
                            .replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.validadeTesteGerminacao}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%" hasBorder>
                          {amostra.pureza?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%" hasBorder>
                          {amostra.germinacao?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%" hasBorder>
                          {amostra.tetrazolio}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%" hasBorder>
                          {amostra.sementesDuras?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%">
                          {amostra.outrosFatores?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>
                      </div>
                    </Fragment>
                  );
                })}
              </div>

              <div
                className={styles.background}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "1px solid black",
                }}
              >
                <span>Dados do Tratamento das Sementes</span>
              </div>

              <div
                style={{
                  borderBottom: "1px solid black",
                  textAlign: "justify",
                  padding: 5,
                  lineHeight: 1.3,
                  minHeight: "20vh",
                }}
              >
                {termo?.dadosTratamentoSementes}
              </div>

              <div
                className={styles.background}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "1px solid black",
                }}
              >
                <span>Declarações do Detentor/Comerciante das Sementes</span>
              </div>

              <div
                style={{
                  display: "flex",
                  paddingBottom: 5,
                  borderBottom: "1px solid black",
                }}
              >
                <div style={{ width: "50%", paddingLeft: 10 }}>
                  <span>
                    1 - O Detentor declara ter recebido a(s) Amostra(s) em
                    duplicata(s).
                  </span>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <input
                        value={"sim"}
                        readOnly={true}
                        type="checkbox"
                        checked={termo?.recebidoColetaDuplicata}
                      />
                      <label> Sim</label>
                    </div>

                    <div style={{ width: "50%" }}>
                      <input
                        value={"nao"}
                        readOnly={true}
                        type="checkbox"
                        checked={!termo?.recebidoColetaDuplicata}
                      />
                      <label> Não</label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  textAlign: "justify",
                  padding: 5,
                  lineHeight: 1.3,
                  minHeight: "10vh",
                }}
              >
                <strong>Observações: </strong>
                {termo?.observacao}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}
