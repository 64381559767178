import { IEspecie } from "@interfaces/IEspecie";
import { especieAction } from "@store/actions/especieAction";
import { getEspecies } from "@store/feature/especieSlice";
import { useDispatch, useSelector } from "react-redux";

export const useEspecie = () => {
  const listaEspecies = useSelector(getEspecies);
  const { addEspecies, removeEspecies } = especieAction;
  const appDispatch = useDispatch();

  const sincronizarEspecies = (especies: IEspecie[], expireTime: number) => {
    appDispatch(addEspecies(especies, expireTime));
  };

  const removerEspecies = () => {
    appDispatch(removeEspecies());
  };

  return {
    listaEspecies,
    sincronizarEspecies,
    removerEspecies,
  };
};
