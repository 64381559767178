import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import { filtroTermoApreensaoAction } from "@store/actions/filtroTermoApreensaoAction";
import { termoApreensaoLiberacaoAction } from "@store/actions/termoApreensaoLiberacaoAction";
import { getFiltroTermoApreensao } from "@store/feature/filtroTermoApreensaoSlice";
import { getTermosApreensaoLiberacao } from "@store/feature/termoApreensaoLiberacaoSlice";
import { useDispatch, useSelector } from "react-redux";

export const useActionsTAL = () => {
  const {
    addTermo,
    addTermoByFilter,
    removeTermo,
    updateTermo,
    updateAssinatura,
  } = termoApreensaoLiberacaoAction;
  const { addFiltroTermoApreensao, removeFiltroTermoApreensao } =
    filtroTermoApreensaoAction;
  const appDispatch = useDispatch();
  const listaTermos = useSelector(getTermosApreensaoLiberacao);
  const listaFiltroTermos = useSelector(getFiltroTermoApreensao);

  const adicionarTermo = (termoJson: ITermoApreensaoLiberacao) => {
    appDispatch(addTermo(termoJson));
  };

  const removerTermo = (numTermo: string) => {
    appDispatch(removeTermo(numTermo));
  };

  const atualizarTermo = (termo: ITermoApreensaoLiberacao) => {
    appDispatch(updateTermo(termo));
  };

  const adicionarTermoByFiltro = (termo: ITermoApreensaoLiberacao) => {
    appDispatch(addTermoByFilter(termo));
  };

  const atualizarAssinatura = (
    dados: any,
    termoId: number,
    termo: ITermoApreensaoLiberacao
  ) => {
    appDispatch(updateAssinatura(dados, termoId, termo));
  };

  const aplicarFiltro = (content: any) => {
    appDispatch(addFiltroTermoApreensao(content));
  };

  const removerFiltro = () => {
    appDispatch(removeFiltroTermoApreensao());
  };

  return {
    adicionarTermo,
    removerTermo,
    atualizarTermo,
    adicionarTermoByFiltro,
    atualizarAssinatura,
    aplicarFiltro,
    removerFiltro,
    listaFiltroTermos,
    listaTermos,
  };
};
