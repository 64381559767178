import { IEspecie } from "@interfaces/IEspecie";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IEspecieSlice {
  items: IEspecie[];
  persistedAt: number;
}

const initialState: IEspecieSlice = {
  items: [],
  persistedAt: 0,
};

const especieSlice = createSlice({
  name: "ESPECIES",
  initialState,
  reducers: {
    addEspecies: (state, action) => {
      const { especies, dateTimeExpire } = action.payload;
      state.items = especies;
      state.persistedAt = dateTimeExpire;
    },
    removeEspecies: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default especieSlice.reducer;

export const getEspecies = (state: RootState) => state.especies.items;
