import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Tooltip, Typography } from "antd";
import moment from "moment";
import styles from "./style.module.css";

interface IDownloadingDadosTermoProps {
  handleChange: (action: string) => void;
  isDataDownloaded: boolean;
  dadosTermoDownloading: boolean;
  timeExpireData: number;
  title: string;
}

export function DownloadingDadosTermo({
  handleChange,
  isDataDownloaded,
  dadosTermoDownloading,
  timeExpireData,
  title,
}: IDownloadingDadosTermoProps) {
  return (
    <>
      <div className={styles.container}>
        <Typography style={{ display: "flex", flexDirection: "column" }}>
          {title}
          <br />
          {isDataDownloaded && (
            <span style={{ opacity: 0.7, fontSize: 13 }}>
              <strong>Atualizado em:</strong>{" "}
              {moment(timeExpireData).format("llll")}
            </span>
          )}
        </Typography>

        {!isDataDownloaded ? (
          <Button
            onClick={() => handleChange("adicionar")}
            style={{ width: 97, boxShadow: "5px 5px 5px rgba(0,0,0,0.2)" }}
            disabled={dadosTermoDownloading}
          >
            {dadosTermoDownloading ? (
              <SyncOutlined spin={dadosTermoDownloading} />
            ) : (
              <span>Baixar</span>
            )}
          </Button>
        ) : (
          <div
            style={{
              display: "flex",
              gap: 5,
            }}
          >
            <Tooltip title="Atualizar">
              <Button
                style={{ boxShadow: "5px 5px 5px rgba(0,0,0,0.2)" }}
                onClick={() => handleChange("atualizar")}
                disabled={dadosTermoDownloading}
              >
                <SyncOutlined spin={dadosTermoDownloading} />
              </Button>
            </Tooltip>

            <Tooltip title="Remover">
              <Button
                style={{ boxShadow: "5px 5px 5px rgba(0,0,0,0.2)" }}
                danger
                onClick={() => handleChange("remover")}
                disabled={dadosTermoDownloading}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
}
