import { openNotification } from "@components/Notifications";
import { useServidores } from "@hooks/useServidores";
import { Form, FormInstance, Input, Typography } from "antd";
import { Fragment } from "react";

interface IAmostradorProps {
  formColeta: FormInstance;
}

export function Amostrador({ formColeta }: IAmostradorProps) {
  const { getServidorPorMatricula } = useServidores();

  const handleSearchAmostrador = async (value: string) => {
    value = value.replace(/\D/g, "");
    //validdar se o documento esta valido
    if (!value) {
      openNotification({
        type: "error",
        message: "Informe a Matrícula do servidor",
      });
      return;
    }

    const servidor = getServidorPorMatricula(value);

    if (servidor) {
      formColeta.setFieldValue("nomeAmostrador", servidor.pessoa.nome);
      openNotification({
        type: "success",
        message: "Amostrador encontrado com sucesso!",
      });
      return;
    }

    openNotification({
      type: "error",
      message: "Erro ao buscar Amostrador",
      description: "Amostrador não encontrado.",
    });
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: matricula } = e.target;

    formColeta.setFieldValue(
      "matriculaAmostrador",
      matricula.replace(/\D/g, "")
    );
  };

  return (
    <Fragment>
      <Typography.Title level={3}>Amostrador</Typography.Title>

      <Form.Item
        name="matriculaAmostrador"
        label="Matrícula"
        rules={[
          {
            required: true,
            message: "Digite a Matricula do Amostrador",
          },
        ]}
      >
        <Input.Search
          maxLength={18}
          type="primary"
          enterButton="Buscar"
          onChange={handleChange}
          onSearch={handleSearchAmostrador}
        />
      </Form.Item>

      <Form.Item
        name="nomeAmostrador"
        label="Nome"
        rules={[{ required: true }]}
      >
        <Input disabled={true} />
      </Form.Item>
    </Fragment>
  );
}
