import { ICategoria } from "@interfaces/ICategoria";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IEstadioFenologicoSlice {
  items: ICategoria[];
  persistedAt: number;
}

const initialState: IEstadioFenologicoSlice = {
  items: [],
  persistedAt: 0,
};

const categoriaSlice = createSlice({
  name: "CATEGORIAS",
  initialState,
  reducers: {
    addCategorias: (state, action) => {
      const { categorias, dateTimeExpire } = action.payload;
      state.items = categorias;
      state.persistedAt = dateTimeExpire;
    },
    removeCategorias: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default categoriaSlice.reducer;

export const getCategorias = (state: RootState) => state.categorias.items;
