import { IContentListagemTIF } from "@interfaces/IListagemTIF";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IFiltroSlice {
  items: {
    filtros: string[];
    params: string;
    formularios: IContentListagemTIF[];
    numberPages: number;
  }[];
}

const initialState: IFiltroSlice = {
  items: [],
};

const filtroTifSlice = createSlice({
  name: "FILTRO_TIF",
  initialState,
  reducers: {
    addFiltroTif: (state, action) => {
      const tifs = action.payload;

      state.items = tifs;
    },
    removeFiltroTif: (state) => {
      state.items = [];
    },
  },
});

export default filtroTifSlice.reducer;

export const getFiltroTif = (state: RootState) => state.filtroTIF.items;
