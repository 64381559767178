import { IArmadilha } from "@interfaces/IArmadilha";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IArmadilhaSlice {
  items: {
    municipioId: number;
    armadilhas: IArmadilha[];
  }[];
  persistedAt: number;
}

const initialState: IArmadilhaSlice = {
  items: [],
  persistedAt: 0,
};

const armadilhaSlice = createSlice({
  name: "ARMADILHA",
  initialState,
  reducers: {
    addArmadilha: (
      state,
      action: PayloadAction<{
        armadilhas: IArmadilha[];
        municipioId: number;
        dateTimeCreate: number;
      }>
    ) => {
      const { armadilhas, municipioId, dateTimeCreate } = action.payload;

      state.items.push({
        municipioId,
        armadilhas,
      });

      state.persistedAt = dateTimeCreate;
    },

    removeArmadilha: (
      state,
      action: PayloadAction<{ municipioId: number }>
    ) => {
      const { municipioId } = action.payload;

      state.items = state.items.filter(
        (item) => item.municipioId !== municipioId
      );

      if (state.items.length === 0) {
        state.persistedAt = 0;
      }
    },
  },
});

export default armadilhaSlice.reducer;

export const getArmadilha = (state: RootState) => state.armadilha.items;
