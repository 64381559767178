import { UploadOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { apiService } from "@services/api";
import { Button, Tooltip } from "antd";
import Upload, { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { Buffer } from "buffer";
import { useActionsTermoColeta } from "../../../hooks/useActionsTermoColeta";

export function ButtonEnviarBackupTermoColeta() {
  const { atualizarTermoColeta, adicionarTermoColetaByFiltro, listaTermos } =
    useActionsTermoColeta();

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeUpload = async (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    try {
      const anexo = await getAnexosFormatadosBase64(info.fileList);
      const json = Buffer.from(anexo[0].arquivo, "base64").toString();

      //SLICE NA POSICAO 14 PARA REMOVER CARACTERES ESPECIAIS
      const jsonObject = JSON.parse(json.slice(14));

      await apiService.termoColeta
        .enviar(jsonObject)
        .then(async (res) => {
          const termoInApp = listaTermos.find(
            (termo) => termo.termoColetaAmostra === res.data.termoColetaAmostra
          );

          if (termoInApp) {
            atualizarTermoColeta({ ...termoInApp, id: res.data.id });
          } else {
            const termoFiltro = await apiService.termoColeta.byId(res.data.id);
            adicionarTermoColetaByFiltro(termoFiltro);
          }

          openNotification({
            type: "success",
            message: "Termo de coleta enviado com sucesso",
          });
        })
        .catch((erro) => {
          openNotification({
            type: "error",
            message: "Não foi possivel enviar backup do Termo de Coleta",
            description: erro.response.data.detail,
          });
        });
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel enviar backup do Termo de Coleta",
        description:
          "Por favor verifique se o backup foi selecionado corretamente, caso o erro persista entre em contato com o suporte.",
      });
    }
  };

  return (
    <>
      <Tooltip title="Enviar backup do Termo de Coleta" placement="left">
        <Upload
          customRequest={dummyRequest}
          onChange={handleChangeUpload}
          fileList={[]}
        >
          <Button disabled={!navigator.onLine}>
            <UploadOutlined />
          </Button>
        </Upload>
      </Tooltip>
    </>
  );
}
