import { IBoletimAmostra } from "@interfaces/IBoletimAmostra";
import { iconsLogo } from "../../../../../../icons";

export function HeaderBoletimLasgal({ boletim }: { boletim: IBoletimAmostra }) {
  return (
    <>
      <thead>
        <tr
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <td style={{ border: "1px solid black" }}>
            <div
              style={{
                display: "block",
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: 150,
                  padding: "10px 10px 0px 10px",
                }}
              >
                <div>
                  <img
                    alt={iconsLogo[3].name}
                    style={{
                      height: 70,
                    }}
                    src={iconsLogo[3].image}
                  />
                </div>
                <div>
                  <img
                    alt={iconsLogo[2].name}
                    style={{
                      height: 70,
                    }}
                    src={iconsLogo[2].image}
                  />
                </div>
              </div>
            </div>
          </td>

          <td
            style={{
              textAlign: "center",
            }}
          >
            <h1
              style={{
                fontSize: 18,
                textTransform: "uppercase",
                marginBottom: 0,
              }}
            >
              Boletim Oficial de análise de sementes N°{" "}
              {boletim.amostra.numeroAmostraLasgal}
            </h1>
          </td>
        </tr>
      </thead>
    </>
  );
}
