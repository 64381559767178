import { IUnidadeMedida } from "@interfaces/IUnidadeMedida";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IUnidadeMedidaSlice {
  items: IUnidadeMedida[];
  persistedAt: number;
}

const initialState: IUnidadeMedidaSlice = {
  items: [],
  persistedAt: 0,
};

const unidadeMedidaSlice = createSlice({
  name: "UNIDADE_MEDIDA",
  initialState,
  reducers: {
    addUnidadeMedida: (state, action) => {
      const { unidades, dateTimeExpire } = action.payload;

      state.items = unidades;
      state.persistedAt = dateTimeExpire;
    },
    removeUnidadeMedida: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default unidadeMedidaSlice.reducer;

export const getUnidadeMedida = (state: RootState) => state.unidadeMedida.items;
