import { UploadOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { apiService } from "@services/api";
import { Button, Tooltip } from "antd";
import Upload, { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { Buffer } from "buffer";
import { useActionsTermoVistoria } from "../../../hooks/useActionsTermoVistoria";

export function ButtonEnviarBackupTermoVistoria() {
  const { atualizarTermo, adicionarTermoByFiltro, listaTermos } =
    useActionsTermoVistoria();

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeUpload = async (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    try {
      const anexo = await getAnexosFormatadosBase64(info.fileList);

      const json = Buffer.from(anexo[0].arquivo, "base64").toString();

      //SLICE NA POSICAO 14 PARA REMOVER CARACTERES ESPECIAIS
      const jsonObject = JSON.parse(json.slice(14));

      const { data } = await apiService.termoVistoria.enviar(jsonObject);
      const termoInApp = listaTermos.find(
        (termo) => termo.numero === data.numero
      );

      openNotification({
        type: "success",
        message: "Termo de Vistoria enviado com sucesso",
      });

      if (termoInApp && data) {
        atualizarTermo({ ...termoInApp, id: data.id });
        return;
      }

      const termoFiltro = await apiService.termoVistoria.byId(data.id);
      adicionarTermoByFiltro(termoFiltro);
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel enviar backup do Termo de Coleta",
        description:
          "Por favor verifique se o backup foi selecionado corretamente, caso o erro persista entre em contato com o suporte.",
      });
    }
  };

  return (
    <>
      <Tooltip title="Enviar backup do Termo de Vistoria" placement="left">
        <Upload
          customRequest={dummyRequest}
          onChange={handleChangeUpload}
          fileList={[]}
        >
          <Button disabled={!navigator.onLine}>
            <UploadOutlined />
          </Button>
        </Upload>
      </Tooltip>
    </>
  );
}
