import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { filtroTermoColetaAction } from "@store/actions/filtroTermoColetaAction";
import { termoColetaAction } from "@store/actions/termoColetaAction";
import { getFiltroTermoColeta } from "@store/feature/filtroTermoColetaSlice";
import { getTermosColeta } from "@store/feature/termoColetaSlice";
import { useDispatch, useSelector } from "react-redux";

export const useActionsTermoColeta = () => {
  const { addTermo, removeTermo, updateTermo, addTermoByFilter } =
    termoColetaAction;
  const { addFiltroTermoColeta, removeFiltroTermoColeta } =
    filtroTermoColetaAction;
  const appDispatch = useDispatch();
  const listaTermos = useSelector(getTermosColeta);
  const listaFiltroTermos = useSelector(getFiltroTermoColeta);

  const adicionarTermoColeta = (termo: ITermoColetaAmostras) => {
    appDispatch(addTermo(termo));
  };

  const removerTermoColeta = (numTermo: string) => {
    appDispatch(removeTermo(numTermo));
  };

  const atualizarTermoColeta = (termo: ITermoColetaAmostras) => {
    appDispatch(updateTermo(termo));
  };

  const adicionarTermoColetaByFiltro = (termo: ITermoColetaAmostras) => {
    appDispatch(addTermoByFilter(termo));
  };

  const aplicarFiltro = (content: any) => {
    appDispatch(addFiltroTermoColeta(content));
  };

  const removerFiltro = () => {
    appDispatch(removeFiltroTermoColeta());
  };

  return {
    adicionarTermoColeta,
    removerTermoColeta,
    atualizarTermoColeta,
    adicionarTermoColetaByFiltro,
    aplicarFiltro,
    removerFiltro,
    listaFiltroTermos,
    listaTermos,
  };
};
