import { ModalAssinatura } from "@components/ModalAssinatura";
import { INITIAL_VALUES_ASSINATURA } from "@constants/INITIAL_VALUES_ASSINATURA";
import { masks } from "@functions/mascaras";
import { IAssinante } from "@interfaces/IAssinante";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { Button, Checkbox, FormInstance, Input, Typography } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useRef, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import "./style.css";

interface IAssinaturaVistoriaProps {
  fiscalizado: IAssinante;
  setFiscalizado: React.Dispatch<React.SetStateAction<IAssinante>>;
  form: FormInstance<any>;
  assinaturaServidor: string;
  setAssinaturaServidor: React.Dispatch<React.SetStateAction<string>>;
  setStatusAssinatura: React.Dispatch<
    React.SetStateAction<CheckboxValueType[]>
  >;
  statusAssinatura: CheckboxValueType[];
  isServidorSelected: boolean;
  refServidor: React.MutableRefObject<any>;
  ativarModalInformarNumTermo: () => void;
}

export function AssinaturaVistoria({
  fiscalizado,
  setFiscalizado,
  form,
  assinaturaServidor,
  setAssinaturaServidor,
  statusAssinatura,
  setStatusAssinatura,
  isServidorSelected,
  refServidor,
  ativarModalInformarNumTermo,
}: IAssinaturaVistoriaProps) {
  const { cpfCnpjMask } = masks;
  const [isOpenModalFiscalizado, setIsOpenModalFiscalizado] = useState(false);
  const [isOpenMOdalServidor, setIsOpenModalServidor] = useState(false);
  const refAssinante = useRef<any>(null);

  const cleanSignatureFields = (
    signature: ReactSignatureCanvas | null | undefined
  ) => {
    if (signature !== null && signature !== undefined) {
      signature.clear();
    }
  };

  const handleOpenModal = (assinante: string) => {
    if (assinante === "FISCALIZADO") {
      setIsOpenModalFiscalizado(true);
    } else if (assinante === "SERVIDOR") {
      setIsOpenModalServidor(true);
    }
  };

  const handleChangeFiscalizadoAusente = (
    checkedValue: CheckboxValueType[]
  ) => {
    setStatusAssinatura(checkedValue);

    if (checkedValue[0] === "AUSENTE") {
      cleanSignatureFields(refAssinante.current);
      setFiscalizado(INITIAL_VALUES_ASSINATURA);
      return;
    }

    setFiscalizado({
      ...fiscalizado,
      nomeAssinante: form.getFieldValue("nomeFiscalizado"),
      cpfAssinante: form.getFieldValue("cnpjFiscalizado"),
    });

    if (checkedValue[0] === "ASSINADO_FISICAMENTE") {
      ativarModalInformarNumTermo();
      cleanSignatureFields(refAssinante.current);
      cleanSignatureFields(refServidor.current);

      setAssinaturaServidor("");
      setFiscalizado({ ...fiscalizado, assinatura: null });

      return;
    }
  };

  const handleCloseModalFiscalizado = async () => {
    setIsOpenModalFiscalizado(false);
    setFiscalizado({
      ...fiscalizado,
      assinatura: refAssinante.current,
    });
  };

  const handleCloseModalServidores = async () => {
    setIsOpenModalServidor(false);
    setAssinaturaServidor(
      refServidor.current.getTrimmedCanvas().toDataURL("image/png")
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: "50px 15px 15px 15px",
        }}
      >
        <Checkbox.Group
          style={{
            display: "flex",
            flexDirection: "row",
            width: 250,
          }}
          onChange={handleChangeFiscalizadoAusente}
          value={statusAssinatura}
        >
          <Checkbox value="ASSINADO_FISICAMENTE">Assinatura Física</Checkbox>
        </Checkbox.Group>
      </div>
      <AssinaturaContainer>
        {isServidorSelected && (
          <div className="servidor-signature-form">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="container-img-assinatura">
                {assinaturaServidor && (
                  <img
                    src={assinaturaServidor}
                    alt={`assinatura Amostrador`}
                    style={{
                      width: "90%",
                      height: "90%",
                    }}
                  />
                )}
              </div>

              <Typography style={{ textAlign: "center" }}>
                Assinatura Servidor
              </Typography>
              <Button
                disabled={statusAssinatura[0] === "ASSINADO_FISICAMENTE"}
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                  handleOpenModal("SERVIDOR");
                }}
              >
                Clique aqui para assinar
              </Button>
            </div>
          </div>
        )}

        <div className="fiscalizado-signature-form">
          <div>
            <div className="container-img-assinatura">
              {fiscalizado.assinatura && (
                <img
                  src={fiscalizado.assinatura
                    .getTrimmedCanvas()
                    .toDataURL("image/png")}
                  alt="assinatura Fiscalizado"
                  style={{
                    width: "90%",
                    height: "90%",
                  }}
                />
              )}
            </div>

            <Typography style={{ textAlign: "center" }}>
              Assinatura Fiscalizado
            </Typography>
            <Button
              disabled={statusAssinatura.length > 0}
              style={{ width: "100%" }}
              type="primary"
              onClick={() => {
                handleOpenModal("FISCALIZADO");
              }}
            >
              Clique aqui para assinar
            </Button>

            <div style={{ display: "flex", paddingTop: 10 }}>
              <Checkbox.Group
                onChange={handleChangeFiscalizadoAusente}
                value={statusAssinatura}
              >
                <Checkbox value="AUSENTE">Ausente</Checkbox>
              </Checkbox.Group>

              <Checkbox.Group
                value={statusAssinatura}
                onChange={handleChangeFiscalizadoAusente}
              >
                <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
              </Checkbox.Group>
            </div>

            <div
              className="dados-assinante-tif"
              style={{
                display: "flex",
                gap: 10,
                maxWidth: 600,
              }}
            >
              <div>
                <span>Nome:</span>
                <Input
                  disabled={
                    statusAssinatura.length > 0 &&
                    !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                      statusAssinatura[0].toString()
                    )
                  }
                  name="nome_assinante"
                  value={fiscalizado?.nomeAssinante}
                  onChange={(e) =>
                    setFiscalizado({
                      ...fiscalizado,
                      nomeAssinante: e.target.value,
                    })
                  }
                />
              </div>

              <div>
                <span>CPF:</span>
                <Input
                  disabled={
                    statusAssinatura.length > 0 &&
                    !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                      statusAssinatura[0].toString()
                    )
                  }
                  name="cpf_assinante"
                  value={fiscalizado?.cpfAssinante}
                  onChange={(e) => {
                    setFiscalizado({
                      ...fiscalizado,
                      cpfAssinante: cpfCnpjMask(e.target.value),
                    });
                  }}
                />
              </div>

              <div>
                <span>Função:</span>
                <Input
                  disabled={
                    statusAssinatura.length > 0 &&
                    !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                      statusAssinatura[0].toString()
                    )
                  }
                  name="funcao_assinante"
                  value={fiscalizado?.funcaoAssinante}
                  onChange={(e) =>
                    setFiscalizado({
                      ...fiscalizado,
                      funcaoAssinante: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </AssinaturaContainer>

      {/**MODAL ASSINATURA FISCALIZADO */}
      <ModalAssinatura
        isOpen={isOpenModalFiscalizado}
        cleanSignature={cleanSignatureFields}
        handleClose={handleCloseModalFiscalizado}
        refAssinante={refAssinante}
        nomeAssinante="Fiscalizado"
      />

      {/**MODAL ASSINATURA SERVIDOR */}
      <ModalAssinatura
        isOpen={isOpenMOdalServidor}
        cleanSignature={cleanSignatureFields}
        handleClose={handleCloseModalServidores}
        refAssinante={refServidor}
        nomeAssinante="Servidor"
      />
    </>
  );
}
