import {
  CloseCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MenuOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { usePerfil } from "@hooks/usePerfil";
import { IPerfil } from "@interfaces/IPerfil";
import { apiService } from "@services/api";
import { perfilAction } from "@store/actions/perfilAction";
import { TitleTermo } from "@styles/TitleTermo";
import {
  Button,
  Divider,
  Dropdown,
  List,
  Menu,
  Modal,
  Tooltip,
  Typography,
} from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalEditarPerfil } from "./components/ModalEditarPerfil";
import styles from "./style.module.css";

export function ListagemPerfis() {
  const navigate = useNavigate();
  const appDispatch = useDispatch();
  const { listaPerfis } = usePerfil();
  const { addPerfis } = perfilAction;
  const [modal, contextHolder] = Modal.useModal();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [perfilSelected, setPerfilSelected] = useState<IPerfil | null>(null);

  const handleEditPerfil = (perfil: IPerfil) => {
    setIsOpenModal(true);
    setPerfilSelected(perfil);
  };

  const handleTurnOffPerfil = (perfil: IPerfil) => {
    modal.confirm({
      title: (
        <h1 className={styles["titulo-modal-desativar-perfil"]}>
          Tem certeza que deseja Inativar Perfil {perfil.nome}?
        </h1>
      ),
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      cancelText: "Não",
      onOk: async () => {
        await apiService.perfis.inativar(perfil.id).then((res) => {
          openNotification({
            type: "success",
            message: `Perfil ${perfil.nome} inativado com sucesso!`,
          });
        });

        await apiService.perfis.buscar().then((res) => {
          appDispatch(addPerfis(res));
        });
      },
    });
  };

  return (
    <>
      <TitleTermo>Gerenciamento de Perfis</TitleTermo>
      <Divider />
      {contextHolder}

      <div className={styles["container-perfil-list"]}>
        <Button
          type="default"
          className={styles["button-novo-perfil"]}
          onClick={() => {
            navigate("/perfis/novo");
          }}
          disabled={!navigator.onLine}
        >
          Novo
        </Button>

        <List
          size="large"
          bordered
          header={
            <div className={styles["container-header-list"]}>
              <span>Nome</span>
              <span>Ações</span>
            </div>
          }
          style={{
            boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
          }}
          dataSource={listaPerfis}
          renderItem={(item, ind) => {
            return (
              <List.Item key={ind}>
                <List.Item.Meta
                  title={
                    <Typography
                      style={{
                        opacity: item.ativo ? 1 : 0.7,
                      }}
                    >
                      {item.nome} {item.ativo ? "" : "(inativo)"}
                    </Typography>
                  }
                />
                <div className={styles["pc-container-buttons"]}>
                  <Tooltip title="Editar">
                    <Button
                      disabled={
                        ["ADMINISTRADOR"].includes(item.nome) ||
                        !item.ativo ||
                        !navigator.onLine
                      }
                      onClick={() => handleEditPerfil(item)}
                    >
                      <EditOutlined />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Gerenciar">
                    <Button
                      disabled={
                        ["ADMINISTRADOR"].includes(item.nome) ||
                        !item.ativo ||
                        !navigator.onLine
                      }
                      onClick={() => navigate(`/perfis/gerenciar/${item.id}`)}
                    >
                      <SettingOutlined />
                    </Button>
                  </Tooltip>

                  <Tooltip title="Inativar">
                    <Button
                      danger
                      disabled={
                        ["ADMINISTRADOR", "SERVIDOR"].includes(item.nome) ||
                        !item.ativo ||
                        !navigator.onLine
                      }
                      onClick={() => handleTurnOffPerfil(item)}
                    >
                      <CloseCircleOutlined />
                    </Button>
                  </Tooltip>
                </div>

                <div className={styles["mobile-container-buttons"]}>
                  <Dropdown
                    placement="bottom"
                    trigger={["click"]}
                    overlay={
                      <Menu
                        items={[
                          {
                            key: 1,
                            label: "Editar",
                            disabled:
                              ["ADMINISTRADOR"].includes(item.nome) ||
                              !item.ativo ||
                              !navigator.onLine,
                            icon: <EditOutlined />,
                            onClick: () => handleEditPerfil(item),
                          },
                          {
                            key: 2,
                            label: "Gerenciar",
                            disabled:
                              ["ADMINISTRADOR"].includes(item.nome) ||
                              !item.ativo ||
                              !navigator.onLine,
                            icon: <SettingOutlined />,
                            onClick: (e) =>
                              navigate(`/perfis/gerenciar/${item.id}`),
                          },

                          {
                            key: 3,
                            label: "Inativar",
                            icon: <CloseCircleOutlined />,
                            disabled:
                              ["ADMINISTRADOR", "SERVIDOR"].includes(
                                item.nome
                              ) ||
                              !item.ativo ||
                              !navigator.onLine,
                          },
                        ]}
                      />
                    }
                  >
                    <Tooltip title="Opções" destroyTooltipOnHide>
                      <Button>
                        <MenuOutlined />
                      </Button>
                    </Tooltip>
                  </Dropdown>
                </div>
              </List.Item>
            );
          }}
        />
      </div>

      <ModalEditarPerfil
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        perfil={perfilSelected}
      />
    </>
  );
}
