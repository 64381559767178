import { IProgramaAtividades } from "@interfaces/IProgramaAtividades";
import { programaActions } from "@store/actions/programaAction";
import { getTipoProgramas } from "@store/feature/programaSlice";
import { useDispatch, useSelector } from "react-redux";

export const useAreaAtividades = () => {
  const { addProgramas, removeProgramas } = programaActions;
  const programas = useSelector(getTipoProgramas);
  const appDispatch = useDispatch();

  const adicionarProgramas = (
    programas: IProgramaAtividades[],
    persistedAt: number
  ) => {
    appDispatch(addProgramas(programas, persistedAt));
  };

  const removerProgramas = () => {
    appDispatch(removeProgramas());
  };

  return { adicionarProgramas, removerProgramas, programas };
};
