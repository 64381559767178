import { LoadingOutlined } from "@ant-design/icons";
import { masks } from "@functions/mascaras";
import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { apiService } from "@services/api";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { TitleTermo } from "@styles/TitleTermo";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Radio,
  Result,
  Spin,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getPerguntasEnumeradas } from "../../functions/getPerguntasEnumeradas";
import styles from "./style.module.css";

export function ViewTermoVistoria() {
  const navigate = useNavigate();
  const { termoId } = useParams();
  const { cpfCnpjMask, coordMask, telefoneMask } = masks;
  const [perguntas, setPerguntas] = useState<IPerguntaTermoVistoria[]>([]);
  const [motivoNaoAssinatura, setMotivoNaoAssinatura] = useState<
    CheckboxValueType[]
  >([]);

  const { data: termoVistoria, status } = useQuery({
    queryKey: ["VISUALIZAR_TERMO_VISTORIA"],
    queryFn: () => apiService.termoVistoria.byId(parseInt(termoId || "")),
    onSuccess: (termoVistoria) => {
      let perguntasTermo = getPerguntasEnumeradas(termoVistoria);

      setPerguntas(perguntasTermo);
      setMotivoNaoAssinatura([termoVistoria.statusAssinaturaFiscalizado]);
    },
  });

  if (status === "error") {
    return (
      <Fragment>
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              minHeight: "20vh",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Result
              status="error"
              title="Erro ao visualizar Termo de Vistoria"
              subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
              extra={
                <Button onClick={() => navigate("/termo-vistoria/listagem")}>
                  Voltar
                </Button>
              }
            />
          </div>
        </div>
      </Fragment>
    );
  } else if (status === "loading" || status === "idle") {
    return (
      <Fragment>
        <div
          style={{
            minHeight: "80vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </Fragment>
    );
  } else {
    return (
      <>
        <TitleTermo>Termo de Vistoria</TitleTermo>
        <Divider />

        <Form
          labelWrap
          labelAlign="left"
          labelCol={{ flex: "200px", span: 20 }}
          wrapperCol={{ flex: 1, span: 14 }}
        >
          <Form.Item label="N° Termo">
            <Input value={termoVistoria?.numero} />
          </Form.Item>

          <Typography.Title level={3}>Fiscalizado</Typography.Title>

          <Form.Item label="CNPJ">
            <Input
              value={cpfCnpjMask(termoVistoria?.cpfCnpjFiscalizado || "")}
            />
          </Form.Item>
          <Form.Item label="Nome">
            <Input value={termoVistoria?.nomeFiscalizado} />
          </Form.Item>
          <Form.Item label="telefone">
            <Input
              value={telefoneMask(termoVistoria?.telefoneFiscalizado || "")}
            />
          </Form.Item>

          <Form.Item label="email">
            <Input value={termoVistoria?.emailFiscalizado} />
          </Form.Item>
          <Form.Item label="CEP">
            <Input value={termoVistoria?.cepFiscalizado} />
          </Form.Item>
          <Form.Item label="UF">
            <Input value={termoVistoria?.municipioFiscalizado?.uf.sigla} />
          </Form.Item>
          <Form.Item label="Municipio">
            <Input value={termoVistoria?.municipioFiscalizado?.nome} />
          </Form.Item>
          <Form.Item label="Endereço">
            <Input value={termoVistoria?.enderecoFiscalizado} />
          </Form.Item>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "150px",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "150px",
                }}
              >
                Latitude:
              </Typography>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
              <Form.Item
                label="Grau"
                labelAlign="left"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  value={coordMask(
                    termoVistoria?.latGrauFiscalizado.toString() || ""
                  )}
                />
              </Form.Item>

              <Form.Item label="Minutos" style={{ maxWidth: "150px" }}>
                <Input
                  value={coordMask(
                    termoVistoria?.latMinFiscalizado.toString() || ""
                  )}
                />
              </Form.Item>

              <Form.Item label="Segundos" style={{ maxWidth: "150px" }}>
                <Input
                  value={coordMask(
                    termoVistoria?.latSegFiscalizado.toString() || ""
                  )}
                />
              </Form.Item>
            </div>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "150px",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "150px",
                }}
              >
                Longitude:
              </Typography>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
              <Form.Item
                label="Grau"
                labelAlign="left"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  value={coordMask(
                    termoVistoria?.longGrauFiscalizado.toString() || ""
                  )}
                />
              </Form.Item>

              <Form.Item
                label="Minutos"
                labelAlign="left"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  value={coordMask(
                    termoVistoria?.longMinFiscalizado.toString() || ""
                  )}
                />
              </Form.Item>

              <Form.Item
                label="Segundos"
                labelAlign="left"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  value={coordMask(
                    termoVistoria?.longSegFiscalizado.toString() || ""
                  )}
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item
            label="Local de armazenamento dos produtos agrotóxicos"
            labelCol={{ span: 6 }}
          >
            <Radio.Group
              value={termoVistoria?.localArmazenamentoProdutoAgrotoxico}
            >
              <Radio value="PREDIO_PROPRIO"> Prédio próprio</Radio>
              <Radio value="OUTRO_LOCAL">Outro local</Radio>
            </Radio.Group>
          </Form.Item>

          <Divider />
          <Form.Item label="Tipo Estabelecimento">
            <Input value={termoVistoria?.formulario?.nome} />
          </Form.Item>
          {perguntas.length > 0 && (
            <Typography.Title level={3}>Perguntas</Typography.Title>
          )}

          {perguntas.map((perg, ind) => {
            return (
              <Fragment key={ind}>
                <div
                  style={{
                    gap: 10,
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  <Typography.Title level={5}>
                    {perg.indPergunta}. {perg.pergunta.nome}
                  </Typography.Title>
                </div>

                {["ALTERNATIVA", "AMBOS"].includes(
                  perg.pergunta.tipoResposta
                ) && (
                  <>
                    <Form.Item
                      label="Alternativa"
                      labelCol={{ flex: "100px", span: 20 }}
                    >
                      <Radio.Group
                        value={
                          termoVistoria.respostas.find(
                            (res) => res.pergunta.id === perg.pergunta.id
                          )?.alternativa?.id
                        }
                      >
                        {perg.pergunta.alternativas.map((alt, i) => {
                          if (alt.ativo) {
                            return (
                              <Radio value={alt.id} key={i}>
                                {alt.nome}
                              </Radio>
                            );
                          }

                          return null;
                        })}
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}

                {perg.pergunta.campos.map((campo, i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: 5,
                        flexDirection: "column",
                      }}
                      key={i}
                    >
                      <label className={styles["label-campo-vistoria"]}>
                        {campo}
                      </label>
                      <Form.Item key={i} noStyle={false}>
                        <Input
                          style={{ maxWidth: 400 }}
                          value={
                            termoVistoria.respostas.find(
                              (res) => res.pergunta.id === perg.pergunta.id
                            )?.campos[i].resposta
                          }
                        />
                      </Form.Item>
                    </div>
                  );
                })}

                {["DESCRITIVA"].includes(perg.pergunta.tipoResposta) && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: 5,
                        flexDirection: "column",
                      }}
                    >
                      <label className={styles["label-campo-vistoria"]}>
                        ESPECIFICAR:
                      </label>
                      <Form.Item noStyle={true}>
                        <Input
                          style={{ maxWidth: 400 }}
                          value={
                            termoVistoria.respostas.find(
                              (res) => res.pergunta.id === perg.pergunta.id
                            )?.descricao
                          }
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </Fragment>
            );
          })}

          <Divider />
          <Typography.Title level={3}>Servidor</Typography.Title>
          <Form.Item label="Matricula">
            <Input value={termoVistoria?.servidor?.matricula || ""} />
          </Form.Item>
          <Form.Item label="Nome">
            <Input value={termoVistoria?.servidor?.pessoa.nome || ""} />
          </Form.Item>
        </Form>

        <div
          style={{
            display: "flex",
            padding: 15,
          }}
        >
          <Checkbox.Group
            style={{
              display: "flex",
              flexDirection: "row",
              width: 250,
            }}
            value={motivoNaoAssinatura}
          >
            <Checkbox value="ASSINADO_FISICAMENTE">Assinatura Física</Checkbox>
          </Checkbox.Group>
        </div>
        <AssinaturaContainer>
          <div className="servidor-signature-form">
            <div>
              <div className="container-img-assinatura">
                {termoVistoria?.statusAssinaturaServidor ===
                  "ASSINADO_DIGITALMENTE" && (
                  <img
                    src={termoVistoria?.assinaturaServidor}
                    alt="assinatura servidor"
                    style={{
                      width: "90%",
                      height: "90%",
                    }}
                  />
                )}
              </div>
              <Typography style={{ textAlign: "center" }}>
                Assinatura {termoVistoria?.servidor?.pessoa.nome}
              </Typography>
            </div>
          </div>
          <div className="fiscalizado-signature-form">
            <div>
              <div className="container-img-assinatura">
                {termoVistoria?.statusAssinaturaFiscalizado ===
                  "ASSINADO_DIGITALMENTE" && (
                  <img
                    style={{ width: "90%", height: "90%" }}
                    src={termoVistoria?.assinaturaFiscalizado}
                    alt="Assinatura fiscalizado"
                  />
                )}
              </div>

              <Typography style={{ textAlign: "center" }}>
                Assinatura Fiscalizado
              </Typography>
              <div
                style={{
                  paddingTop: 5,
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Checkbox.Group
                  value={motivoNaoAssinatura}
                  style={{
                    display: "flex",
                  }}
                >
                  <Checkbox value="AUSENTE">Ausente</Checkbox>
                  <br />
                  <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                </Checkbox.Group>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: 10,
                  maxWidth: 600,
                }}
              >
                <div>
                  <span>Nome:</span>
                  <Input value={termoVistoria?.nomeAssinanteFiscalizado} />
                </div>

                <div>
                  <span>CPF:</span>
                  <Input
                    value={cpfCnpjMask(termoVistoria.cpfAssinanteFiscalizado)}
                  />
                </div>

                <div>
                  <span>Função:</span>
                  <Input value={termoVistoria?.funcaoAssinanteFiscalizado} />
                </div>
              </div>
            </div>
          </div>
        </AssinaturaContainer>

        <ButtonFooterContainer>
          <Button onClick={(e) => navigate("/termo-vistoria/listagem")}>
            Voltar
          </Button>
        </ButtonFooterContainer>
      </>
    );
  }
}
