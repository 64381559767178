import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { apiService } from "@services/api";
import { getTermosVistoria } from "@store/feature/termoVistoriaSlice";
import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getPerguntasEnumeradas } from "../../functions/getPerguntasEnumeradas";
import { BodyTermoVistoriaImpressao } from "./components/BodyTermoVistoriaImpressao";
import { FooterTermoVistoriaImpressao } from "./components/FooterTermoVistoriaImpressao";
import { HeaderTermoVistoriaImpressao } from "./components/HeaderTermoVistoriaImpressao";

export function ViewImpressaoTermoVistoria() {
  const { numTermo } = useParams();
  const navigate = useNavigate();
  const listaTermos = useSelector(getTermosVistoria);
  const componentRef = useRef(null);
  const [termoSelected, setTermoSelected] = useState<ITermoVistoria>();

  const [perguntas, setPerguntas] = useState<IPerguntaTermoVistoria[]>([]);

  const handleSearchTermoVistoriaById = async (id: number) => {
    const termoVistoria = listaTermos.filter((termo) => termo.id === id);

    if (termoVistoria.length > 0) {
      setTermoSelected(termoVistoria[0]);
    } else {
      const aux = await apiService.termoVistoria.byId(id);
      setTermoSelected(aux);
    }
  };

  const handleSearchTermoVistoriaByNumero = () => {
    const termoVistoria = listaTermos.filter(
      (termo) => termo.numero === numTermo?.replaceAll("_", "/")
    );
    setTermoSelected(termoVistoria[0]);
  };

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: "@page { size: A4 !important;}",
    documentTitle: `Termo ${termoSelected?.numero}`,
    suppressErrors: true,
    preserveAfterPrint: true,
  });

  useEffect(() => {
    const numberId = parseInt(numTermo || "");
    if (numberId) {
      handleSearchTermoVistoriaById(numberId);
    } else {
      handleSearchTermoVistoriaByNumero();
    }

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (termoSelected) {
      // PEGAR AS PERGUNTAS INDEPENDENTES
      const perguntasTermo = getPerguntasEnumeradas(termoSelected);
      // console.log(perguntasTermo);
      setPerguntas(perguntasTermo);
    }

    //eslint-disable-next-line
  }, [termoSelected]);

  return (
    <>
      {termoSelected && (
        <div
          style={{
            display: "flex",
            gap: 20,
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 20,
              paddingBottom: 20,
            }}
          >
            <Button type="primary" onClick={() => handlePrint()}>
              Imprimir
            </Button>

            <Button onClick={() => navigate("/termo-vistoria/listagem")}>
              Voltar
            </Button>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div ref={componentRef} className="termo-vistoria-font">
              <table style={{ width: 780 }}>
                <HeaderTermoVistoriaImpressao numTermo={termoSelected.numero} />

                <BodyTermoVistoriaImpressao
                  termo={termoSelected}
                  perguntas={perguntas}
                />

                <FooterTermoVistoriaImpressao termo={termoSelected} />
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
