import { IUle } from "@interfaces/IUle";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface uleState {
  items: IUle[] | [];
}

const initialState: uleState = {
  items: [],
};

const uleSlice = createSlice({
  name: "ULE",
  initialState,
  reducers: {
    addUles: (state, action) => {
      const { ules } = action.payload;
      state.items = ules;
    },
  },
});

export default uleSlice.reducer;

export const getUles = (state: RootState) => state.ules.items;
