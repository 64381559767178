import { NotificationOutlined } from "@ant-design/icons";
import { getPayloadUser } from "@functions/getPayloadUser";
import { IContentListagemTIF } from "@interfaces/IListagemTIF";
import { apiService } from "@services/api";
import { Badge, Button, Tooltip } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { ModalPrazoAdequarTIF } from "../ModalPrazoAdequarTIF";
import styles from "./style.module.css";

export function NotificacaoGeral() {
  const user = getPayloadUser(localStorage.getItem("access_token"));
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [tifList, setTifList] = useState<IContentListagemTIF[]>([]);

  const fetchTifs = async () => {
    const params = new URLSearchParams();
    params.append("cpfCnpj", user?.cpf || "");

    const { data } = await apiService.tif.prazoNotificacao(params.toString());
    return data;
  };

  useQuery({
    queryKey: "PRAZO_ADEQUACAO_TIF",
    queryFn: fetchTifs,
    onSuccess: (data) => {
      if (localStorage.getItem("notification-adequar-tif") && data.length > 0) {
        setIsOpenModal(true);
      }

      setTimeout(() => {
        localStorage.removeItem("notification-adequar-tif");
      }, 1000);
      setTifList(data);
    },
    refetchOnWindowFocus: false,
  });

  return (
    <div className={styles.container}>
      <Tooltip
        title={`${
          tifList.length === 0 ? "Sem Notificações" : "Notificações pendentes"
        }`}
      >
        <Button
          shape="circle"
          disabled={tifList.length === 0}
          onClick={() => {
            setIsOpenModal(true);
          }}
          className={styles["button-notification"]}
          icon={
            <Badge count={tifList.length}>
              <NotificationOutlined />
            </Badge>
          }
        />
      </Tooltip>

      <ModalPrazoAdequarTIF
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        listaTif={tifList}
      />
    </div>
  );
}
