import { LayoutDefault } from "layouts/layoutDefault";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page404 } from "./pages/404";
import { TifConfig } from "./pages/config/tifConfig";
import { Home } from "./pages/home";
import { ListagemLasgal } from "./pages/listagemLasgal";
import { AnaliseTesteLasgal } from "./pages/listagemLasgal/AnaliseTesteLasgal";
import { NovoRecebimentoLasgal } from "./pages/listagemLasgal/NovoRecebimentoLasgal";
import { ViewImpressaoBoletimLasgal } from "./pages/listagemLasgal/ViewImpressaoBoletimLasgal";
import { ListagemPerfis } from "./pages/listagemPerfis";
import { GerenciarPerfil } from "./pages/listagemPerfis/components/GerenciarPerfil";
import { NovoPerfil } from "./pages/listagemPerfis/components/NovoPerfil";
import { LoginOffline } from "./pages/loginOffline";
import { OfflineMode } from "./pages/offlineMode";
import { TermoApreensao } from "./pages/termoApreensao";
import { EdicaoTermoApreensaoLiberacao } from "./pages/termoApreensao/edicaoTermoApreensaoLiberacao";
import { ListagemTermoApreensaoLiberacao } from "./pages/termoApreensao/listagemTermoApreensaoLiberacao";
import { EdicaoPosEnvioTermoApreensaoLiberacao } from "./pages/termoApreensao/listagemTermoApreensaoLiberacao/EdicaoPosEnvioTermoApreensaoLiberacao";
import { VisualizacaoImpressaoTermoApreensaoLiberacao } from "./pages/termoApreensao/listagemTermoApreensaoLiberacao/VisualizacaoImpressaoTermoApreensaoLiberacao";
import { VisualizacaoTermoApreensaoLiberacao } from "./pages/termoApreensao/listagemTermoApreensaoLiberacao/VisualizacaoTermoApreensaoLiberacao";
import { TermoColeta } from "./pages/termoColeta";
import { EditTermoColeta } from "./pages/termoColeta/editTermoColeta";
import { ListagemTermoColeta } from "./pages/termoColeta/listagemTermoColeta";
import { EdicaoPosEnvioTermoColeta } from "./pages/termoColeta/listagemTermoColeta/EdicaoPosEnvioTermoColeta";
import { ViewTermoColeta } from "./pages/termoColeta/listagemTermoColeta/ViewTermoColeta";
import { TermoVistoria } from "./pages/termoVistoria";
import { EditTermoVistoria } from "./pages/termoVistoria/editTermoVistoria";
import { ListagemTermoVistoria } from "./pages/termoVistoria/listagemTermoVistoria";
import { EdicaoPosEnvioTermoVistoria } from "./pages/termoVistoria/listagemTermoVistoria/EdicaoPosEnvioTermoVistoria";
import { ViewImpressaoTermoVistoria } from "./pages/termoVistoria/listagemTermoVistoria/ViewImpressaoTermoVistoria";
import { ViewTermoVistoria } from "./pages/termoVistoria/listagemTermoVistoria/ViewTermoVistoria";
import { TID } from "./pages/tid";
import { EdicaoTID } from "./pages/tid/EdicaoTID";
import { ListagemTID } from "./pages/tid/listagemTID";
import { EdicaoPosEnvioTID } from "./pages/tid/listagemTID/EdicaoPosEnvioTID";
import { ViewImpressaoTID } from "./pages/tid/listagemTID/ViewImpressaoTID";
import { ViewTID } from "./pages/tid/listagemTID/ViewTID";
import { FormTif } from "./pages/tif";
import { EditFormTif } from "./pages/tif/editTIF";
import { ListagemTIF } from "./pages/tif/listagemTIF";
import { EditPosEnvioTIF } from "./pages/tif/listagemTIF/EditPosEnvioTIF";
import { ViewImpressaoTIF } from "./pages/tif/listagemTIF/ViewImpressaoTIF";
import { ViewTIF } from "./pages/tif/listagemTIF/ViewTIF";

export function Routers() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginOffline />} />
        <Route
          path="/home"
          element={
            <LayoutDefault>
              <Home />
            </LayoutDefault>
          }
        />

        <Route
          path="/TIF/novo"
          element={
            <LayoutDefault>
              <FormTif />
            </LayoutDefault>
          }
        />
        <Route
          path="/offline-mode"
          element={
            <LayoutDefault>
              <OfflineMode />
            </LayoutDefault>
          }
        />
        <Route
          path="/TIF/listagem"
          element={
            <LayoutDefault>
              <ListagemTIF />
            </LayoutDefault>
          }
        />

        <Route
          path="/TIF/edit/:numTIF"
          element={
            <LayoutDefault>
              <EditFormTif />
            </LayoutDefault>
          }
        />
        <Route
          path="/TIF/edit/atividades-assinatura/:tifId"
          element={
            <LayoutDefault>
              <EditPosEnvioTIF />
            </LayoutDefault>
          }
        />

        <Route
          path="/TIF/listagem/visualizar/:tifId"
          element={
            <LayoutDefault>
              <ViewTIF />
            </LayoutDefault>
          }
        />

        <Route
          path="/TIF/listagem/impressao/:tifId"
          element={
            <LayoutDefault>
              <ViewImpressaoTIF />
            </LayoutDefault>
          }
        />

        <Route
          path="/config/TIF"
          element={
            <LayoutDefault>
              <TifConfig />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-coleta/novo"
          element={
            <LayoutDefault>
              <TermoColeta />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-coleta/edit/:numTermo"
          element={
            <LayoutDefault>
              <EditTermoColeta />
            </LayoutDefault>
          }
        />
        <Route
          path="/termo-coleta/listagem"
          element={
            <LayoutDefault>
              <ListagemTermoColeta />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-coleta/edit/assinatura/:id"
          element={
            <LayoutDefault>
              <EdicaoPosEnvioTermoColeta />
            </LayoutDefault>
          }
        />
        <Route
          path="/termo-coleta/listagem/visualizar/:termoId"
          element={
            <LayoutDefault>
              <ViewTermoColeta />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/novo"
          element={
            <LayoutDefault>
              <TermoApreensao />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/listagem"
          element={
            <LayoutDefault>
              <ListagemTermoApreensaoLiberacao />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/edit/:numTermo"
          element={
            <LayoutDefault>
              <EdicaoTermoApreensaoLiberacao />
            </LayoutDefault>
          }
        />
        <Route
          path="/termo-apreensao-liberacao/listagem/visualizar/:termoId"
          element={
            <LayoutDefault>
              <VisualizacaoTermoApreensaoLiberacao />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/listagem/impressao/:termoId"
          element={
            <LayoutDefault>
              <VisualizacaoImpressaoTermoApreensaoLiberacao />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/listagem/edicao-assinatura/:termoId"
          element={
            <LayoutDefault>
              <EdicaoPosEnvioTermoApreensaoLiberacao />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-vistoria/novo"
          element={
            <LayoutDefault>
              <TermoVistoria />
            </LayoutDefault>
          }
        />
        <Route
          path="/termo-vistoria/listagem"
          element={
            <LayoutDefault>
              <ListagemTermoVistoria />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-vistoria/listagem/impressao/:numTermo"
          element={
            <LayoutDefault>
              <ViewImpressaoTermoVistoria />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-vistoria/listagem/visualizar/:termoId"
          element={
            <LayoutDefault>
              <ViewTermoVistoria />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-vistoria/edit/:numTermo"
          element={
            <LayoutDefault>
              <EditTermoVistoria />
            </LayoutDefault>
          }
        />

        <Route
          path="/termo-vistoria/listagem/edicao-assinatura/:termoId"
          element={
            <LayoutDefault>
              <EdicaoPosEnvioTermoVistoria />
            </LayoutDefault>
          }
        />

        <Route
          path="/LASGAL/novo/:amostraId"
          element={
            <LayoutDefault>
              <NovoRecebimentoLasgal />
            </LayoutDefault>
          }
        />

        <Route
          path="/LASGAL/teste/:amostraId"
          element={
            <LayoutDefault>
              <AnaliseTesteLasgal />
            </LayoutDefault>
          }
        />

        <Route
          path="/LASGAL/impressao/:amostraId"
          element={
            <LayoutDefault>
              <ViewImpressaoBoletimLasgal />
            </LayoutDefault>
          }
        />

        <Route
          path="/LASGAL/listagem"
          element={
            <LayoutDefault>
              <ListagemLasgal />
            </LayoutDefault>
          }
        />

        <Route
          path="/TID/novo"
          element={
            <LayoutDefault>
              <TID />
            </LayoutDefault>
          }
        />

        <Route
          path="/TID/listagem"
          element={
            <LayoutDefault>
              <ListagemTID />
            </LayoutDefault>
          }
        />

        <Route
          path="/TID/listagem/edit/:numTermo"
          element={
            <LayoutDefault>
              <EdicaoTID />
            </LayoutDefault>
          }
        />

        <Route
          path="/TID/listagem/edicao-assinatura/:termoId"
          element={
            <LayoutDefault>
              <EdicaoPosEnvioTID />
            </LayoutDefault>
          }
        />

        <Route
          path="/TID/listagem/impressao/:termoId"
          element={
            <LayoutDefault>
              <ViewImpressaoTID />
            </LayoutDefault>
          }
        />

        <Route
          path="/TID/listagem/visualizar/:termoId"
          element={
            <LayoutDefault>
              <ViewTID />
            </LayoutDefault>
          }
        />

        <Route
          path="/TID/listagem/edicao-assinatura/:termoId"
          element={
            <LayoutDefault>
              <EdicaoPosEnvioTID />
            </LayoutDefault>
          }
        />

        <Route
          path="/perfis"
          element={
            <LayoutDefault>
              <ListagemPerfis />
            </LayoutDefault>
          }
        />

        <Route
          path="/perfis/gerenciar/:idPerfil"
          element={
            <LayoutDefault>
              <GerenciarPerfil />
            </LayoutDefault>
          }
        />

        <Route
          path="/perfis/novo"
          element={
            <LayoutDefault>
              <NovoPerfil />
            </LayoutDefault>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}
