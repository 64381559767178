import { IListagemFormularioPerguntas } from "@interfaces/IListagemFormularioPerguntas";
import { formularioPerguntasVistoriaAction } from "@store/actions/formularioPerguntasVistoriaAction";
import { termoVistoriaAction } from "@store/actions/termoVistoriaAction";
import { getFormularioPerguntasVistoria } from "@store/feature/formularioPerguntasVistoriaSlice";
import { getPersistedAtTermoVistoria } from "@store/feature/termoVistoriaSlice";
import { useDispatch, useSelector } from "react-redux";

export const useDadosTermoVistoria = () => {
  const { addFormsPerguntaVistoria, removeFormsPerguntaVistoria } =
    formularioPerguntasVistoriaAction;
  const { addTimePersistedAtItems, removeTimePersistedAtItems } =
    termoVistoriaAction;

  const listaFormsPerguntaTermoVistoria = useSelector(
    getFormularioPerguntasVistoria
  );
  const termoVistoriaPersistedAtItems = useSelector(
    getPersistedAtTermoVistoria
  );
  const appDispatch = useDispatch();

  const adicionarFormsPerguntasTermoVistoria = (
    forms: IListagemFormularioPerguntas[],
    persistedAt: number
  ) => {
    appDispatch(addFormsPerguntaVistoria(forms, persistedAt));
  };

  const removerFormsPerguntasTermoVistoria = () => {
    appDispatch(removeFormsPerguntaVistoria());
  };

  const adicionarPersistedAtTermoVistoria = (time: number) => {
    appDispatch(addTimePersistedAtItems(time));
  };

  const removerPersistedAtTermoVistoria = () => {
    appDispatch(removeTimePersistedAtItems());
  };

  return {
    removerFormsPerguntasTermoVistoria,
    adicionarFormsPerguntasTermoVistoria,
    listaFormsPerguntaTermoVistoria,
    termoVistoriaPersistedAtItems,
    adicionarPersistedAtTermoVistoria,
    removerPersistedAtTermoVistoria,
  };
};
