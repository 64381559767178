import { UploadOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { IAnexos } from "@interfaces/IAnexos";
import { apiService } from "@services/api";
import { Button, message, Tooltip, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { Buffer } from "buffer";
import { converterTIDtoFormData } from "../../../functions/converterTIDtoFormData";
import { useActionsTID } from "../../../hooks/useActionsTID";

export function ButtonEnviarBackupTID() {
  const { listaTIDs, adicionarTermoSubmited, atualizarTermo } = useActionsTID();

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeUpload = async (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const keyMessage = "termo";

    try {
      const anexo = await getAnexosFormatadosBase64(info.fileList);
      const json = Buffer.from(anexo[0].arquivo, "base64").toString();

      //SLICE NA POSICAO 14 PARA REMOVER CARACTERES ESPECIAIS
      let jsonObject = JSON.parse(json.slice(14));

      if (!jsonObject.tif.id) {
        const params = new URLSearchParams();
        params.append("numero", jsonObject.tif.numero);
        await apiService.tif.listar(params.toString()).then((data) => {
          if (data.content.length > 0) {
            jsonObject = {
              ...jsonObject,
              tif: {
                ...jsonObject.tif,
                id: data.content[0].id,
              },
            };
          }
        });
      }

      if (!jsonObject.tif.id) {
        openNotification({
          type: "info",
          message: `Não foi possivel enviar Termo de ${jsonObject.tipoTermo.toLowerCase()}.`,
          description:
            "Favor sincronizar o TIF antes de salvar esse termo. Caso já tenha sincronizado, procure o administrador do sistema.",
        });

        return;
      }

      const anexosFile = jsonObject.anexos.map((anexo: IAnexos) => {
        return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
      });

      message.loading({
        duration: 0,
        content: "Enviando Termo...",
        key: keyMessage,
      });

      await apiService.tid
        .enviar(converterTIDtoFormData(jsonObject, anexosFile))
        .then(async (data) => {
          if (!data.id) return;

          const termoInApp = listaTIDs.find(
            (termo) => termo.numero === data.numero
          );

          if (termoInApp && data) {
            atualizarTermo({ ...termoInApp, id: data.id });

            message.destroy(keyMessage);
            openNotification({
              type: "success",
              message: "Termo de Interdição/Desinterdição enviado com sucesso",
            });
            return;
          }

          const termoFiltro = await apiService.tid.byId(data.id);
          adicionarTermoSubmited(termoFiltro);

          message.destroy(keyMessage);
          openNotification({
            type: "success",
            message: "Termo de Interdição/Desinterdição enviado com sucesso",
          });
        })
        .catch((erro) => {
          message.destroy(keyMessage);
          openNotification({
            type: "error",
            message:
              "Não foi possivel enviar backup do Termo de Interdição/Desinterdição",
            description: erro.response.data.detail,
          });
        });
    } catch (error) {
      message.destroy(keyMessage);

      openNotification({
        type: "error",
        message:
          "Não foi possivel enviar backup do Termo de Interdição/Desinterdição",
        description:
          "Por favor verifique se o backup foi selecionado corretamente, caso o erro persista entre em contato com o suporte.",
      });
    }
  };

  return (
    <>
      <Tooltip
        title="Enviar backup do Termo de Interdição/Desinterdição"
        placement="left"
      >
        <Upload
          customRequest={dummyRequest}
          onChange={handleChangeUpload}
          fileList={[]}
        >
          <Button disabled={!navigator.onLine}>
            <UploadOutlined />
          </Button>
        </Upload>
      </Tooltip>
    </>
  );
}
