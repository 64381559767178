import { openNotification } from "@components/Notifications";
import { useMenu } from "@hooks/useMenu";
import { usePerfil } from "@hooks/usePerfil";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { TitleTermo } from "@styles/TitleTermo";
import { Button, Checkbox, Divider, Form, Input } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.css";

export function NovoPerfil() {
  const { listaMenus } = useMenu();
  const { adicionarPerfil } = usePerfil();
  const navigate = useNavigate();
  const [formPerfil] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBack = () => {
    navigate("/perfis");
    formPerfil.resetFields();
  };

  const handleSubmit = async (values: any) => {
    setIsSubmitting(true);
    const permissoes = listaMenus.map((menu) => {
      const aux = {
        adicionar: isAllowed(values[`permissoes_${menu.id}`], "ADICIONAR"),
        editar: isAllowed(values[`permissoes_${menu.id}`], "EDITAR"),
        excluir: isAllowed(values[`permissoes_${menu.id}`], "EXCLUIR"),
        visualizar: isAllowed(values[`permissoes_${menu.id}`], "VISUALIZAR"),
        log: isAllowed(values[`permissoes_${menu.id}]`], "LOG"),
      };

      if (
        !aux.adicionar &&
        !aux.excluir &&
        !aux.editar &&
        !aux.visualizar &&
        !aux.log
      ) {
        return null;
      }

      return {
        menu: {
          ativo: menu.ativo,
          codigo: menu.codigo,
          id: menu.id,
          nome: menu.nome,
          titulo: menu.titulo,
          url: menu.url,
        },
        ...aux,
      };
    });

    const data = {
      nome: values.nome,
      permissoes: permissoes.filter((perm) => perm),
    };

    await apiService.perfis.adicionar(data).then((res) => {
      // console.log(res);
      adicionarPerfil(res);
      openNotification({
        type: "success",
        message: "Perfil criado com sucesso!",
      });

      handleBack();
    });

    setIsSubmitting(false);
  };

  const isAllowed = (permissoes: CheckboxValueType[], type: string) => {
    if (!permissoes) return false;

    if (permissoes.includes(type)) return true;

    return false;
  };

  return (
    <>
      <TitleTermo>Novo Perfil</TitleTermo>
      <Divider />

      <Form form={formPerfil} onFinish={handleSubmit}>
        <Form.Item
          name="nome"
          rules={[{ required: true }]}
          className={styles["form-input-nome-perfil"]}
        >
          <Input
            className={styles["input-nome"]}
            placeholder="Digite o nome do Perfil"
          />
        </Form.Item>

        <div className={styles["container-permissoes"]}>
          {listaMenus.map((menu) => {
            if (["inicio", "offlineMode", "config"].includes(menu.codigo)) {
              return <Fragment key={menu.id}></Fragment>;
            }

            return (
              <div className={styles.content} key={menu.id}>
                <h1 className={styles["nome-menu"]}>{menu.nome}</h1>
                <Form.Item name={`permissoes_${menu.id}`}>
                  <Checkbox.Group className={styles.checkbox}>
                    <Checkbox value="ADICIONAR" className={styles.options}>
                      Adicionar
                    </Checkbox>
                    <Checkbox value="EDITAR" className={styles.options}>
                      Editar
                    </Checkbox>
                    <Checkbox value="EXCLUIR" className={styles.options}>
                      Excluir
                    </Checkbox>
                    <Checkbox value="VISUALIZAR" className={styles.options}>
                      Visualizar
                    </Checkbox>
                    <Checkbox value="LOG" className={styles.options}>
                      Log
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            );
          })}

          <ButtonFooterContainer>
            <Button
              htmlType="reset"
              onClick={handleBack}
              disabled={isSubmitting}
            >
              Cancelar
            </Button>
            <Button htmlType="submit" type="primary" disabled={isSubmitting}>
              Salvar
            </Button>
          </ButtonFooterContainer>
        </div>
      </Form>
    </>
  );
}
