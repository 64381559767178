import { IServidor } from "@interfaces/IServidor";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IServidorSlice {
  items: IServidor[] | [];
}

const initialState: IServidorSlice = {
  items: [],
};

const servidorSlice = createSlice({
  name: "SERVIDOR",
  initialState,
  reducers: {
    addServidores: (state, action) => {
      const { servidores } = action.payload;
      state.items = servidores;
    },
  },
});

export default servidorSlice.reducer;

export const getServidores = (state: RootState) => state.servidores.items;
