import { IMunicipio } from "@interfaces/IMunicipio";
import { IUf } from "@interfaces/IUf";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface ICidadeState {
  items: IMunicipio[] | [];
}

const initialState: ICidadeState = {
  items: [],
};

const municipiosSlice = createSlice({
  name: "MUNICIPIO",
  initialState,
  reducers: {
    addMunicipios: (state, action) => {
      const { municipios } = action.payload;
      state.items = municipios;
    },
  },
});

export default municipiosSlice.reducer;

export const getMunicipiosMT = (state: RootState) => {
  const mt = state.municipios.items.filter(
    (municipio) => municipio.uf.id === 51
  );

  return mt;
};

export const getMunicipios = (state: RootState) => {
  return state.municipios.items;
};

export const getUfs = (state: RootState) => {
  const ufs = state.municipios.items.map((municipio) => {
    return municipio.uf;
  });

  const filterUf: IUf[] = [];

  for (let i = 0; i < ufs.length; i++) {
    let hasItem = false;

    for (let j = 0; j < filterUf.length; j++) {
      if (ufs[i].id === filterUf[j].id) {
        hasItem = true;
      }
    }

    if (!hasItem) {
      filterUf.push(ufs[i]);
    }
  }

  //array em ordem alfabetica
  const orderFilterUf = filterUf.sort((a, b) => {
    if (a.nome > b.nome) {
      return 1;
    }

    if (a.nome < b.nome) {
      return -1;
    }

    return 0;
  });

  return orderFilterUf;
};
