import { API_URL } from "@constants/API_URL";
import { IAmostraDadosLasgal } from "@interfaces/IAmostraDadosLasgal";
import { IArmadilha } from "@interfaces/IArmadilha";
import { IAtividadeInterdicao } from "@interfaces/IAtividadeInterdicao";
import { IBoletimAmostra } from "@interfaces/IBoletimAmostra";
import { ICategoria } from "@interfaces/ICategoria";
import { IContaminante } from "@interfaces/IContaminante";
import { IEmbalagem } from "@interfaces/IEmbalagem";
import { IEmpresaAgrotoxico } from "@interfaces/IEmpresaAgrotoxico";
import { IEspecie } from "@interfaces/IEspecie";
import { IEstabelecimento } from "@interfaces/IEstabelecimento";
import { IEstadioFenologico } from "@interfaces/IEstadioFenologico";
import { IListagemAmostra } from "@interfaces/IListagemAmostra";
import { IListagemFormularioPerguntas } from "@interfaces/IListagemFormularioPerguntas";
import { IListagemTermoApreensaoLiberacao } from "@interfaces/IListagemTermoApreensaoLiberacao";
import { IListagemTermoColeta } from "@interfaces/IListagemTermoColeta";
import { IListagemTermoVistoria } from "@interfaces/IListagemTermoVistoria";
import { IListagemTID } from "@interfaces/IListagemTID";
import { IListagemTIF } from "@interfaces/IListagemTIF";
import { IMaterialColetado } from "@interfaces/IMaterialColetado";
import { IMenuOptions } from "@interfaces/IMenuOptions";
import { IMunicipio } from "@interfaces/IMunicipio";
import { IPerfil } from "@interfaces/IPerfil";
import { IPessoa } from "@interfaces/IPessoa";
import { IProdutoAgrotoxico } from "@interfaces/IProdutoAgrotoxico";
import { IProgramaAtividades } from "@interfaces/IProgramaAtividades";
import { IPropriedade } from "@interfaces/IPropriedade";
import { IRecebimentoAmostraLasgal } from "@interfaces/IRecebimentoAmostraLasgal";
import { IServidor } from "@interfaces/IServidor";
import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import {
  IDadosAssinaturaTermoVistoria,
  ITermoVistoria,
} from "@interfaces/ITermoVistoria";
import { IDadosAssinaturaFiscalizadoTID, ITID } from "@interfaces/ITid";
import { ITif } from "@interfaces/ITif";
import { IUle } from "@interfaces/IUle";
import { IUnidadeMedida } from "@interfaces/IUnidadeMedida";
import { IUserController } from "@interfaces/IUserController";
import axios from "axios";

export const apiService = {
  pessoas: async (params: string) => {
    const { data }: { data: IPessoa[] } = await api.get(`/pessoas?${params}`);

    return data;
  },
  municipios: async () => {
    const { data }: { data: IMunicipio[] } = await api.get(`/municipios`);

    return data;
  },
  programas: async () => {
    const { data }: { data: IProgramaAtividades[] } = await api.get(
      "/vegetal/areas-atividades"
    );
    return data;
  },
  servidores: {
    todos: async () => {
      const { data }: { data: IServidor[] } = await api.get(`servidores`);

      return data;
    },

    byMatricula: async (matricula: string) => {
      const params = new URLSearchParams();
      params.append("matricula", matricula);

      const { data }: { data: IServidor } = await api.get(
        `/servidores?${params.toString()}`
      );

      return data;
    },
  },
  propriedades: {
    byMunicipio: async (idMunicipio: number) => {
      const { data }: { data: IPropriedade[] } = await api.get(
        `/vegetal/propriedades/municipio/${idMunicipio}`
      );
      return data;
    },

    byCpfCnpj: async (cpfCnpj: string) => {
      const { data }: { data: IPropriedade[] } = await api.get(
        `/vegetal/propriedades/produtor/${cpfCnpj}`
      );

      return data;
    },
    byId: async (id: number) => {
      const { data }: { data: IPropriedade } = await api.get(
        `/propriedades/${id}`
      );

      return data;
    },
  },
  estabelecimentos: {
    byCnpj: async (cnpj: string) => {
      const params = new URLSearchParams();
      params.append("cnpj", cnpj);

      const { data }: { data: IEstabelecimento[] } = await api.get(
        `/estabelecimentos?${params}`
      );

      return data;
    },
    byMunicipio: async (idMunicipio: number) => {
      const params = new URLSearchParams();
      params.append("municipioId", `${idMunicipio}`);
      const { data }: { data: IEstabelecimento[] } = await api.get(
        `estabelecimentos?${params}`
      );

      return { data };
    },
  },
  ules: async () => {
    const { data }: { data: IUle[] } = await api.get("/ules");

    return data;
  },

  tif: {
    byId: async (id: number) => {
      const { data }: { data: ITif } = await api.get(`/vegetal/tifs/${id}`);

      return data;
    },
    update: async (item: any, id: number) => {
      const { data }: { data: ITif } = await api.patch(
        `/vegetal/tifs/${id}`,
        item
      );

      return data;
    },
    enviar: async (formData: FormData) => {
      const tif = await api.post("/vegetal/tifs", formData);

      return tif;
    },
    remover: async (id: number, motivo: string) => {
      await api.post(`/vegetal/tifs/${id}/cancelar`, {
        motivo: motivo,
      });

      return;
    },
    listar: async (params: string) => {
      const { data }: { data: IListagemTIF } = await api.get(
        `/vegetal/tifs?${params}`
      );

      return data;
    },

    prazoNotificacao: async (params: string) => {
      const tifs = await api.get(`/vegetal/tifs/prazo-notificacao?${params}`);

      return tifs;
    },

    enviarAnexos: async (id: number, anexos: FormData) => {
      const tif = await api.put(`/vegetal/tifs/${id}/anexo`, anexos, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return tif;
    },

    getAnexo: async (nomeArquivo: string) => {
      const { data }: { data: Blob } = await api.get(
        `/vegetal/tifs/anexo/${nomeArquivo}`,
        {
          responseType: "blob",
        }
      );

      return { data };
    },

    getByNumero: async (params: string) => {
      const { data }: { data: ITif } = await api.get(
        `/vegetal/tifs/buscar?${params}`
      );

      return data;
    },
  },

  termoColeta: {
    enviar: async (formTermo: ITermoColetaAmostras) => {
      const termo = await api.post(`/vegetal/termos-coleta`, formTermo);
      return termo;
    },

    listar: async (params: string) => {
      const { data }: { data: IListagemTermoColeta } = await api.get(
        `/vegetal/termos-coleta?${params}`
      );

      return data;
    },

    remover: async (id: number, motivo: string) => {
      await api.post(`/vegetal/termos-coleta/${id}/cancelar`, {
        motivo: motivo,
      });

      return;
    },

    byId: async (id: number) => {
      const { data }: { data: ITermoColetaAmostras } = await api.get(
        `/vegetal/termos-coleta/${id}`
      );

      return data;
    },
    update: async (item: any, id: number) => {
      const { data } = await api.patch(`vegetal/termos-coleta/${id}`, item);

      return data;
    },
  },

  termoApreensaoLiberacao: {
    enviar: async (form: FormData) => {
      const termo = await api.post("/vegetal/termos-apreensao-liberacao", form);

      return termo;
    },

    listar: async (params: string) => {
      const { data }: { data: IListagemTermoApreensaoLiberacao } =
        await api.get(`/vegetal/termos-apreensao-liberacao?${params}`);

      return data;
    },

    byId: async (id: number) => {
      const { data }: { data: ITermoApreensaoLiberacao } = await api.get(
        `/vegetal/termos-apreensao-liberacao/${id}`
      );

      return data;
    },

    remover: async (id: number, motivo: string) => {
      await api.post(`/vegetal/termos-apreensao-liberacao/${id}/cancelar`, {
        motivo: motivo,
      });

      return;
    },
    update: async (dados: any, termoId: number) => {
      const { data } = await api.put(
        `/vegetal/termos-apreensao-liberacao/${termoId}`,
        dados
      );

      return data;
    },

    getAnexo: async (nomeArquivo: string) => {
      const { data }: { data: Blob } = await api.get(
        `/vegetal/termos-apreensao-liberacao/anexo/${nomeArquivo}`,
        {
          responseType: "blob",
        }
      );

      return { data };
    },
  },

  especie: async () => {
    const { data }: { data: IEspecie[] } = await api.get("/vegetal/especies");

    return data;
  },

  materialColetado: async () => {
    const { data }: { data: IMaterialColetado[] } = await api.get(
      "/vegetal/materiais-coletados"
    );

    return data;
  },

  estadiosFenologicos: async () => {
    const { data }: { data: IEstadioFenologico[] } = await api.get(
      "/vegetal/estadios-fenologicos"
    );
    return data;
  },

  categorias: async () => {
    const { data }: { data: ICategoria[] } = await api.get(
      "/vegetal/categoria-sementes"
    );
    return data;
  },

  unidadeMedida: async (params?: string) => {
    const { data }: { data: IUnidadeMedida[] } = await api.get(
      `/unidades-medida?${params}`
    );

    return data;
  },

  empresasAgrotoxicos: async () => {
    const { data }: { data: IEmpresaAgrotoxico[] } = await api.get(
      `/empresas-agrotoxicos`
    );

    return data;
  },

  embalagens: async () => {
    const { data }: { data: IEmbalagem[] } = await api.get(`/embalagens`);

    return data;
  },

  produtosAgrotoxico: async () => {
    const { data }: { data: IProdutoAgrotoxico[] } = await api.get(
      `/produtos-agrotoxicos`
    );

    return data;
  },

  listagemFormularios: async () => {
    const { data }: { data: IListagemFormularioPerguntas[] } = await api.get(
      "/vegetal/formularios"
    );
    return data;
  },

  termoVistoria: {
    enviar: async (termoVistoria: ITermoVistoria) => {
      const termo = await api.post("/vegetal/termos-vistoria", termoVistoria);
      return termo;
    },
    byId: async (id: number) => {
      const { data }: { data: ITermoVistoria } = await api.get(
        `/vegetal/termos-vistoria/${id}`
      );

      return data;
    },
    remover: async (id: number, motivo: string) => {
      const termoVistoria = await api.post(
        `/vegetal/termos-vistoria/${id}/cancelar`,
        {
          motivo: motivo,
        }
      );

      return termoVistoria;
    },
    listar: async (params: string) => {
      const { data }: { data: IListagemTermoVistoria } = await api.get(
        `/vegetal/termos-vistoria?${params}`
      );

      return data;
    },

    update: async (dados: IDadosAssinaturaTermoVistoria, termoId: number) => {
      const { data }: { data: ITermoVistoria } = await api.put(
        `/vegetal/termos-vistoria/${termoId}`,
        dados
      );

      return data;
    },
  },

  menu: async () => {
    const { data }: { data: IMenuOptions[] } = await api.get("/vegetal/menus");

    return data;
  },

  perfis: {
    buscar: async () => {
      const { data }: { data: IPerfil[] } = await api.get("/vegetal/perfis");

      return data;
    },

    adicionar: async (perfil: any) => {
      const { data }: { data: IPerfil } = await api.post(
        "/vegetal/perfis",
        perfil
      );

      return data;
    },

    editar: async (idPerfil: number, perfil: any) => {
      const { data }: { data: IPerfil } = await api.put(
        `/vegetal/perfis/${idPerfil}`,
        perfil
      );

      return data;
    },
    inativar: async (perfilId: number) => {
      await api.patch(`/vegetal/perfis/${perfilId}/inativar`);

      return;
    },
  },

  userController: async (cpfCnpj: string) => {
    const { data }: { data: IUserController } = await api.get(
      `/vegetal/permissoes/${cpfCnpj}`
    );

    return data;
  },

  amostraSemente: {
    byId: async (id: number) => {
      const { data }: { data: IAmostraDadosLasgal } = await api.get(
        `/amostras-sementes/${id}`
      );
      return data;
    },
    buscarAmostra: async (params: string) => {
      const { data }: { data: IListagemAmostra } = await api.get(
        `/amostras-sementes?${params}`
      );
      return data;
    },

    receberAmostraLasgal: async (
      amostraId: number,
      amostra: IRecebimentoAmostraLasgal
    ) => {
      const { data } = await api.put(
        `/amostras-sementes/${amostraId}/recebimento-lasgal`,
        amostra
      );

      return data;
    },

    recusaAmostraLasgal: async (
      amostraId: number,
      motivo: {
        descricaoRecusa: string;
        motivoRecusaAmostra: { id: number; nome: string };
      }
    ) => {
      const { data } = await api.put(
        `/amostras-sementes/${amostraId}/recusa-lasgal`,
        motivo
      );

      return data;
    },

    motivosRecusaAmostraLasgal: async () => {
      const { data }: { data: { id: number; nome: string }[] } = await api.get(
        "/motivos-recusa-amostra"
      );

      return data;
    },
  },

  testesAnalise: async () => {
    const { data }: { data: { id: number; nome: string }[] } = await api.get(
      "/testes-analise"
    );

    return data;
  },

  contaminantes: async () => {
    const { data }: { data: IContaminante[] } = await api.get("/contaminantes");

    return data;
  },

  materialInerteBoletim: async () => {
    const { data }: { data: { ativo: boolean; id: number; nome: string }[] } =
      await api.get("materiais-inertes");
    return data;
  },

  boletimAmostra: {
    enviar: async (boletim: IBoletimAmostra) => {
      const { data } = await api.post("boletins-amostras", boletim);
      return data;
    },
    byAmostraId: async (amostraId: number) => {
      const { data }: { data: IBoletimAmostra } = await api.get(
        `boletins-amostras/amostra/${amostraId}`
      );
      return data;
    },
    enviarAssinatura: async (assinatura: {
      usuario: string;
      senha: string;
      boletimAmostraId: number;
    }) => {
      const params = new URLSearchParams();
      params.append("usuario", assinatura.usuario);
      params.append("senha", assinatura.senha);

      const response = await api.post(
        `/sisdev-api-hom/boletins-amostras/${
          assinatura.boletimAmostraId
        }/assinatura?${params.toString()}`
      );

      return response;
    },
  },

  tid: {
    listar: async (params: string) => {
      const { data }: { data: IListagemTID } = await api.get(`/tids?${params}`);
      return data;
    },

    byId: async (termoId: number) => {
      const { data }: { data: ITID } = await api.get(`/tids/${termoId}`);
      return data;
    },

    enviar: async (formData: FormData) => {
      const { data }: { data: ITID } = await api.post("/tids", formData);

      return data;
    },

    remover: async (id: number, motivo: string) => {
      await api.post(`/tids/${id}/cancelar`, {
        motivo: motivo,
      });

      return;
    },
    getAnexo: async (nomeAnexo: string) => {
      const { data } = await api.get(`/tids/anexo/${nomeAnexo}`, {
        responseType: "blob",
      });
      return data;
    },
    update: async (dados: IDadosAssinaturaFiscalizadoTID, termoId: number) => {
      const { data }: { data: ITID } = await api.put(`/tids/${termoId}`, dados);

      return data;
    },
  },

  armadilha: async (municipioId: number) => {
    const params = new URLSearchParams();
    params.append("codigoIbge", municipioId.toString());

    const { data }: { data: IArmadilha[] } = await api.get(
      `/armadilhas?${params}`
    );

    return data;
  },

  atividadesInterdicao: {
    adicionar: async (atividade: IAtividadeInterdicao) => {
      const { data } = await api.post(
        `/areas-atividades-interdicoes`,
        atividade
      );

      return data;
    },
    remover: async (areaAtividadeInterdicaoId: number) => {
      await api.delete(
        `/areas-atividades-interdicoes/${areaAtividadeInterdicaoId}`
      );

      return;
    },
    listar: async () => {
      const { data }: { data: IAtividadeInterdicao[] } = await api.get(
        `/areas-atividades-interdicoes`
      );

      return data;
    },
  },
};

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
});
