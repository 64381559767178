import { ITabelaContaminantesInformados } from "@interfaces/ITabelaContaminantesInformados";
import { ITabelaTesteLasgal } from "@interfaces/ITabelaTesteLasgal";
import { Button } from "antd";
import { VALOR_PADRAO } from "../../constants";

export function ButtonConfirmaOutraCultivadaSilvestre({
  hasOutrasCultivadaSilvestre,
  dataTable,
  listaContaminanteSelecionados,
  armazenarDados,
}: {
  hasOutrasCultivadaSilvestre: boolean;
  listaContaminanteSelecionados: ITabelaContaminantesInformados[];
  dataTable: ITabelaTesteLasgal[];
  armazenarDados: (
    dadosTabela: ITabelaTesteLasgal[],
    dadosContaminante: ITabelaContaminantesInformados[]
  ) => void;
}) {
  const onChange = () => {
    const auxDataTable = [
      dataTable[0],
      {
        ...dataTable[1],
        silvestres: hasOutrasCultivadaSilvestre ? VALOR_PADRAO : "",
        outraCultivadas: hasOutrasCultivadaSilvestre ? VALOR_PADRAO : "",
      },
    ];

    const contaminanteSemSilvestreOutrasCultivadas =
      listaContaminanteSelecionados.filter(
        (contaminante) =>
          contaminante.grupo !== "OESP" && contaminante.grupo !== "SILVESTRE"
      );

    armazenarDados(auxDataTable, contaminanteSemSilvestreOutrasCultivadas);
  };

  return (
    <Button
      type={hasOutrasCultivadaSilvestre ? "primary" : "default"}
      style={{ width: 250 }}
      onClick={onChange}
    >
      {hasOutrasCultivadaSilvestre
        ? "Não fez silvestre e outras cultivadas"
        : "Fez Silvestre e outras cultivadas"}
    </Button>
  );
}
