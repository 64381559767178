import { LoadingOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { masks } from "@functions/mascaras";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { useDadosTif } from "@hooks/useDadosTif";
import { IAssinante } from "@interfaces/IAssinante";
import { IFormAtividades } from "@interfaces/IFormAtividades";
import { apiService } from "@services/api";
import { tifAction } from "@store/actions/tifAction";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { TitleTermo } from "@styles/TitleTermo";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Result,
  Spin,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import { AtividadesTIF } from "../../components/AtividadesTIF";
import { organizarListaAtividade } from "../../functions/organizarListaAtividade";
import { useActionsTif } from "../../hooks/useActionsTif";

export function EditPosEnvioTIF() {
  const [formAtividade] = Form.useForm();
  const { tifId } = useParams();
  const appDispatch = useDispatch();
  const navigate = useNavigate();
  const { cpfCnpjMask } = masks;
  const { listaTif, atualizarTif } = useActionsTif();
  const {
    atividadesTif,
    respostasAtividades,
    adicionarAtividade,
    removerTodasAtividades,
    adicionarRespostaAtividade,
  } = useDadosTif();

  const { updateAtividades } = tifAction;

  const [isOpenModalFiscalizado, setIsOpenModalFiscalizado] = useState(false);
  const [motivoNaoAssinatura, setMotivoNaoAssinatura] = useState<
    CheckboxValueType[]
  >([]);
  const [fiscalizadoSignature, setFiscalizadoSignature] = useState<IAssinante>({
    assinatura: null,
    cpfAssinante: "",
    funcaoAssinante: "",
    nomeAssinante: "",
  });
  const { data, status, remove, isFetched } = useQuery({
    queryKey: ["EDITAR_POS_ENVIO_TIF"],
    queryFn: () => apiService.tif.byId(parseInt(tifId || "")),
    initialData: listaTif.find((tif) => tif.id === parseInt(tifId || "")),
  });

  const handleBack = () => {
    remove();
    removerTodasAtividades();
    navigate("/TIF/listagem");
  };

  const cleanSignatureFields = (
    signature: ReactSignatureCanvas | null | undefined
  ) => {
    if (signature !== null && signature !== undefined) {
      signature.clear();
    }
  };

  const handleChangeFiscalizadoAusente = (
    checkedValue: CheckboxValueType[]
  ) => {
    setMotivoNaoAssinatura(checkedValue);

    if (checkedValue[0] === "RECUSADO" || checkedValue[0] === "AUSENTE") {
      cleanSignatureFields(fiscalizadoSignature.assinatura);
      setFiscalizadoSignature({
        assinatura: null,
        cpfAssinante: "",
        funcaoAssinante: "",
        nomeAssinante: "",
      });
      return;
    }
  };

  const handleSubmitTIF = async () => {
    if (!data) return;

    let dadosAux = {
      statusAssinatura: data.statusAssinatura,
      assinatura: data.assinatura,
      nomeAssinante: data.nomeAssinante,
      funcaoAssinante: data.funcaoAssinante,
      cpfAssinante: data.cpfAssinante,
      atividades: organizarListaAtividade(atividadesTif),
      respostasAtividades: respostasAtividades,
    };

    const atvQuestionsEmpty = atividadesTif.filter((atv) => {
      if (atv.atividade.questoes.length > 0) {
        const { id } = atv.atividade;

        if (atv.armadilha) {
          if (
            respostasAtividades.some(
              (res) => res.armadilha?.id === atv.armadilha?.id
            )
          ) {
            return null;
          }
          return atv;
        } else {
          if (respostasAtividades.some((res) => res.atividade.id === id)) {
            return null;
          }
          return atv;
        }
      }

      return null;
    });

    if (
      data.statusAssinatura !== "ASSINADO_DIGITALMENTE" &&
      data.statusAssinatura !== ASSINATURA_FISICA
    ) {
      if (
        (!fiscalizadoSignature.nomeAssinante ||
          !fiscalizadoSignature.cpfAssinante ||
          !fiscalizadoSignature.funcaoAssinante) &&
        motivoNaoAssinatura.length === 0
      ) {
        openNotification({
          type: "info",
          message: "Não foi possivel Atualizar TIF",
          description: "Preencha os dados do assinante",
        });

        return;
      }

      if (motivoNaoAssinatura.length > 1) {
        openNotification({
          type: "info",
          message: "Não foi possivel Atualizar TIF",
          description:
            "Preencha somente um motivo para não assinatura do fiscalizado",
        });
        return;
      }

      if (
        motivoNaoAssinatura.length === 0 &&
        (!fiscalizadoSignature.assinatura ||
          fiscalizadoSignature.assinatura?.toData().length === 0)
      ) {
        openNotification({
          type: "info",
          message: "Não foi possivel Atualizar TIF",
          description: "Preencha a Assinatura do Fiscalizado.",
        });
        return;
      }

      if (atvQuestionsEmpty.length > 0) {
        openNotification({
          type: "info",
          message: "Não foi possivel Atualizar TIF",
          description:
            "Responda todas as perguntas pendentes do questionário de atividades.",
        });
        return;
      }

      dadosAux = {
        ...dadosAux,
        assinatura:
          motivoNaoAssinatura.length === 0
            ? fiscalizadoSignature?.assinatura
                ?.getTrimmedCanvas()
                .toDataURL() || ""
            : "",
        statusAssinatura: getStatusAssinatura(motivoNaoAssinatura),
        nomeAssinante: fiscalizadoSignature.nomeAssinante,
        cpfAssinante: fiscalizadoSignature.cpfAssinante.replace(/[^\d]+/g, ""),
        funcaoAssinante: fiscalizadoSignature.funcaoAssinante,
      };
    }

    if (navigator.onLine) {
      await apiService.tif
        .update(dadosAux, parseInt(tifId || ""))
        .then((res) => {
          // console.log(res);
          atualizarTif({
            ...data,
            ...dadosAux,
          });
          openNotification({
            type: "success",
            message: "TIF atualizado com sucesso!",
          });

          handleBack();
        })
        .catch((error: any) => {
          console.log(error);
          openNotification({
            type: "error",
            message: "Não foi possivel atualizar TIF",
            description: error.response.data.detail,
          });
        });
    } else {
      appDispatch(updateAtividades(dadosAux, parseInt(tifId || ""), data));
      openNotification({
        type: "success",
        message: "TIF atualizado com sucesso!",
      });
      handleBack();
    }
  };

  const handleCloseModal = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsOpenModalFiscalizado(false);
    setFiscalizadoSignature({
      ...fiscalizadoSignature,
      assinatura: fiscalizadoSignature.assinatura,
    });
  };

  useEffect(() => {
    if (data) {
      const auxAtividades: IFormAtividades[] = data.atividades.map((atv) => {
        const auxText: string[] = [];

        if (atv.coleta) {
          auxText.push("Coleta");
        }

        if (atv.denuncia) {
          auxText.push("Atendimento à denuncia");
        }

        if (atv.desinterdicao) {
          auxText.push("Desinterdição");
        }

        if (atv.interdicao) {
          auxText.push("Interdição");
        }

        if (atv.notificacao) {
          auxText.push("Notificação");
        }

        return {
          key: atv.id,
          programa: {
            id: atv.areaAtividade.id,
            label: atv.areaAtividade.nome,
            value: atv.areaAtividade.nome,
          },
          atividade: atv.atividade,
          prazo: atv.prazoAdequacao,
          especificacoes: atv.armadilha
            ? atv.armadilha.codigo
            : auxText.join(","),
          armadilha: atv.armadilha,
        };
      });

      removerTodasAtividades();
      auxAtividades.forEach((atv) => {
        adicionarAtividade(atv);
      });

      //PREENCHENDO AUTOMATICAMENTE AS RESPOSTAS DOS QUESTIONARIOS SE HOUVER
      adicionarRespostaAtividade(data.respostasAtividades);

      setMotivoNaoAssinatura([data.statusAssinatura]);
      setFiscalizadoSignature({
        nomeAssinante: data.nomeAssinante || "",
        cpfAssinante: data.cpfAssinante || "",
        funcaoAssinante: data.funcaoAssinante || "",
        assinatura: null,
      });
    }
    //eslint-disable-next-line
  }, [data]);

  if (status === "error") {
    return (
      <Fragment>
        <Result
          status="error"
          title="Erro ao Editar TIF"
          subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
          extra={
            <Button onClick={() => navigate("/TIF/listagem")}> Voltar</Button>
          }
        />
      </Fragment>
    );
  } else if (
    status === "idle" ||
    status === "loading" ||
    (!isFetched && navigator.onLine)
  ) {
    return (
      <Fragment>
        <div
          style={{
            minHeight: "80vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <TitleTermo>Editar TIF</TitleTermo>

        <Divider />

        <div>
          <Typography.Title level={3}>Atividades</Typography.Title>

          <AtividadesTIF
            atividadeForm={formAtividade}
            isEdit
            municipioFiscalizacaoId={data.municipioFiscalizacao?.id}
          />
        </div>

        {data.statusAssinatura !== "ASSINADO_DIGITALMENTE" &&
          data.statusAssinatura !== "ASSINADO_FISICAMENTE" && (
            <AssinaturaContainer>
              {data.servidores.map((serv: any, ind: number) => {
                return (
                  <div className="servidor-signature-form" key={ind}>
                    <div>
                      <div className="container-img-assinatura">
                        <img
                          src={serv.assinatura}
                          alt="assinatura servidor"
                          style={{
                            width: "90%",
                            height: "90%",
                          }}
                        />
                      </div>
                      <Typography style={{ textAlign: "center" }}>
                        Assinatura {serv.servidor.nome}
                      </Typography>
                    </div>
                  </div>
                );
              })}

              <div className="fiscalizado-signature-form">
                <div>
                  <div className="container-img-assinatura">
                    {fiscalizadoSignature.assinatura && (
                      <img
                        src={fiscalizadoSignature.assinatura
                          .getTrimmedCanvas()
                          .toDataURL("image/png")}
                        alt="assinatura Fiscalizado"
                        style={{
                          width: "90%",
                          height: "90%",
                        }}
                      />
                    )}
                  </div>

                  <Typography style={{ textAlign: "center" }}>
                    Assinatura Fiscalizado
                  </Typography>
                  <Button
                    disabled={motivoNaoAssinatura.length > 0}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={() => {
                      setIsOpenModalFiscalizado(true);
                    }}
                  >
                    Clique aqui para assinar
                  </Button>

                  <div style={{ display: "flex", paddingTop: 10 }}>
                    <Checkbox.Group
                      onChange={handleChangeFiscalizadoAusente}
                      value={motivoNaoAssinatura}
                    >
                      <Checkbox value="AUSENTE">Ausente</Checkbox>
                    </Checkbox.Group>

                    <Checkbox.Group
                      value={motivoNaoAssinatura}
                      onChange={handleChangeFiscalizadoAusente}
                    >
                      <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                    </Checkbox.Group>
                  </div>

                  <div
                    className="dados-assinante-tif"
                    style={{
                      display: "flex",
                      gap: 10,
                      maxWidth: 600,
                    }}
                  >
                    <div>
                      <span>Nome:</span>
                      <Input
                        disabled={
                          motivoNaoAssinatura.length > 0 &&
                          !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                            motivoNaoAssinatura[0].toString()
                          )
                        }
                        name="nome_assinante"
                        value={fiscalizadoSignature?.nomeAssinante}
                        onChange={(e) =>
                          setFiscalizadoSignature({
                            ...fiscalizadoSignature,
                            nomeAssinante: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div>
                      <span>CPF:</span>
                      <Input
                        disabled={
                          motivoNaoAssinatura.length > 0 &&
                          !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                            motivoNaoAssinatura[0].toString()
                          )
                        }
                        name="cpf_assinante"
                        value={fiscalizadoSignature?.cpfAssinante}
                        onChange={(e) => {
                          setFiscalizadoSignature({
                            ...fiscalizadoSignature,
                            cpfAssinante: cpfCnpjMask(e.target.value),
                          });
                        }}
                      />
                    </div>

                    <div>
                      <span>Função:</span>
                      <Input
                        disabled={
                          motivoNaoAssinatura.length > 0 &&
                          !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                            motivoNaoAssinatura[0].toString()
                          )
                        }
                        name="funcao_assinante"
                        value={fiscalizadoSignature?.funcaoAssinante}
                        onChange={(e) =>
                          setFiscalizadoSignature({
                            ...fiscalizadoSignature,
                            funcaoAssinante: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </AssinaturaContainer>
          )}

        <ButtonFooterContainer style={{ marginTop: 40 }}>
          <Button onClick={handleBack}>Voltar</Button>
          <Button onClick={handleSubmitTIF} type="primary">
            Atualizar TIF
          </Button>
        </ButtonFooterContainer>

        <Modal
          title="Assinar no campo abaixo"
          centered
          maskClosable={false}
          open={isOpenModalFiscalizado}
          width={1400}
          keyboard={true}
          onCancel={handleCloseModal}
          footer={null}
        >
          <AssinaturaContainer>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                width: " 90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Button
                  style={{ width: "48%" }}
                  onClick={(e) => {
                    cleanSignatureFields(fiscalizadoSignature.assinatura);
                  }}
                >
                  Limpar
                </Button>

                <Button style={{ width: "48%" }} onClick={handleCloseModal}>
                  Voltar
                </Button>
              </div>
              <div style={{ width: "100%" }}>
                <ReactSignatureCanvas
                  minWidth={2}
                  penColor="black"
                  ref={(ref) => (fiscalizadoSignature.assinatura = ref)}
                  canvasProps={{
                    className: "sigCanvas",
                  }}
                  clearOnResize={false}
                />

                <Typography style={{ textAlign: "center" }}>
                  Assinatura Fiscalizado
                </Typography>
              </div>
            </div>
          </AssinaturaContainer>
        </Modal>
      </Fragment>
    );
  }
}
