import { IProdutoAgrotoxico } from "@interfaces/IProdutoAgrotoxico";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IProdutoAgrotoxicoSlice {
  items: IProdutoAgrotoxico[];
  persistedAt: number;
}

const initialState: IProdutoAgrotoxicoSlice = {
  items: [],
  persistedAt: 0,
};

const produtoAgrotoxicoSlice = createSlice({
  name: "PRODUTO_AGROTOXICO",
  initialState,
  reducers: {
    addProdutosAgrotoxico: (state, action) => {
      const { produtos, createdAt } = action.payload;
      state.items = produtos;
      state.persistedAt = createdAt;
    },
    removeProdutosAgrotoxicos: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default produtoAgrotoxicoSlice.reducer;

export const getProdutosAgrotoxicos = (state: RootState) =>
  state.produtosAgrotoxicos.items;
