import { openNotification } from "@components/Notifications";
import { useAreaAtividades } from "@hooks/useAreaAtividades";
import { useDadosTif } from "@hooks/useDadosTif";
import { apiService } from "@services/api";
import { useState } from "react";
import { DownloadingDadosTermo } from "../DownloadingDadosTermo";

export function SyncDadosTif() {
  const { adicionarProgramas, programas, removerProgramas } =
    useAreaAtividades();
  const {
    tifPersistedAtItems,
    removerPersistedAtTif,
    adicionarPersistedAtTif,
  } = useDadosTif();
  const [isDownloading, setIsDownloading] = useState(false);

  const tifDownloadDados = async (action: string) => {
    const dateTimeExpire = Date.now();

    if (action === "adicionar" || action === "atualizar") {
      if (!navigator.onLine) {
        openNotification({
          type: "error",
          message: "Erro ao baixar informações",
          description:
            "Sem accesso a internet para baixar dados. Tente novamente mais tarde.",
        });
        return;
      }

      setIsDownloading(true);

      await Promise.allSettled([apiService.programas()]).then((res) => {
        const requestRejected = res.filter(
          (data) => data.status === "rejected"
        );

        if (requestRejected.length > 0) {
          openNotification({
            type: "error",
            message: `Erro ao ${
              action === "adicionar" ? "baixar" : "atualizar"
            } informações`,
            description:
              "Tente novamente, se o erro persistir entre em contato com o suporte",
          });
          return;
        }

        if (res[0].status === "fulfilled") {
          adicionarProgramas(res[0].value, dateTimeExpire);
          adicionarPersistedAtTif(dateTimeExpire);
        }

        openNotification({
          type: "success",
          message: `Dados para o TIF ${
            action === "adicionar" ? "sincronizados" : "atualizados"
          } com Sucesso!`,
        });
      });

      setIsDownloading(false);
    } else {
      removerProgramas();
      removerPersistedAtTif();

      openNotification({
        type: "success",
        message: "Dados sincronizados do TIF removidos com Sucesso!",
      });
    }
  };

  return (
    <DownloadingDadosTermo
      title="Dados para o Termo de Inspeção, Fiscalização e Notificação:"
      dadosTermoDownloading={isDownloading}
      handleChange={tifDownloadDados}
      isDataDownloaded={programas.length > 0}
      timeExpireData={tifPersistedAtItems}
    />
  );
}
