import { Button, Divider, Input, Modal } from "antd";
import { useState } from "react";
import { openNotification } from "../Notifications";
import styles from "./style.module.css";

interface IModalInformarNumeroTermoProps {
  isOpen: boolean;
  onClose: () => void;
  numTermo: string;
  setNumTermo: React.Dispatch<React.SetStateAction<string>>;
}

export function ModalInformarNumeroTermo({
  isOpen,
  onClose,
  numTermo,
  setNumTermo,
}: IModalInformarNumeroTermoProps) {
  const [isConfirm, setIsConfirm] = useState(false);

  const gerarNumeroPeloSistema = () => {
    setIsConfirm(false);
    setNumTermo("");

    openNotification({
      type: "info",
      message: "N° do Termo será gerado automaticamente pelo sistema.",
    });

    onClose();
  };

  const confirmarNumeroInformado = () => {
    if (!numTermo) {
      openNotification({
        type: "error",
        message: "Digite um N° do Termo válido.",
      });
      return;
    }

    openNotification({
      type: "success",
      message: "N° do Termo informado com sucesso!",
    });
    onClose();
  };

  return (
    <>
      <Modal
        open={isOpen}
        maskClosable={false}
        closable={false}
        afterClose={() => onClose()}
        footer={null}
      >
        <div className={styles.container}>
          <div>
            <h1 className={styles.title}>Deseja informar o N° do Termo?</h1>

            <p className={styles["sub-title"]}>
              Essa opção possibilita informar o número do termo manualmente,
              caso queira que o sistema gere o número automaticamente pressione
              <strong> Não</strong>.
            </p>
          </div>

          <div className={styles["container-button"]}>
            <Button onClick={gerarNumeroPeloSistema}>Não</Button>
            <Button
              onClick={() => setIsConfirm(true)}
              type="primary"
              disabled={isConfirm}
            >
              Sim
            </Button>
          </div>

          {isConfirm && (
            <>
              <Divider />
              <div className={styles["container-confirm"]}>
                <Input
                  placeholder="Informe o N° do termo"
                  value={numTermo}
                  onChange={(e) => {
                    const { value } = e.target;
                    setNumTermo(value);
                  }}
                />

                <Button type="primary" onClick={confirmarNumeroInformado}>
                  Confirmar
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
