import { IOficialAmostrasPDF } from "@interfaces/IOficialAmosrasPDF";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import React from "react";
import { BodyOficialImpressao } from "./components/BodyOficialImpressao";
import { FooterOficialImpressao } from "./components/FooterOficialImpressao";
import { HeaderOficialImpressao } from "./components/HeaderOficialImpressao";
import { TabelaAmostrasOficialImpressao } from "./components/TabelaAmostrasOficialImpressao";

interface IOficialImpressaoProps {
  termo: ITermoColetaAmostras;
  listaAmostras: IOficialAmostrasPDF[];
  marcaDagua: string;
}

export const OficialImpressao = React.forwardRef(
  (props: IOficialImpressaoProps, ref: any) => {
    return (
      <>
        <div>
          <div ref={ref}>
            <div className="marcadagua">{props.marcaDagua}</div>
            <table className="termo-coleta-impressao-font">
              <HeaderOficialImpressao />
              <BodyOficialImpressao
                termo={props.termo}
                listaAmostras={props.listaAmostras.filter(
                  (amostra, ind) => ind < 10
                )}
              />
              <FooterOficialImpressao termo={props.termo} />
            </table>

            {props.listaAmostras.length > 10 && (
              <>
                <table
                  style={{ width: "100vw", pageBreakBefore: "always" }}
                  className="termo-coleta-impressao-font"
                >
                  <HeaderOficialImpressao />
                  <TabelaAmostrasOficialImpressao
                    termo={props.termo}
                    listaAmostras={props.listaAmostras.filter(
                      (amostra, ind) => ind >= 10
                    )}
                  />

                  <FooterOficialImpressao termo={props.termo} />
                </table>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
);
