import { IFormValuesTermoApreensaoLiberacao } from "@interfaces/IFormValuesTermoApreensaoLiberacao";
import { FormInstance } from "antd";

export const resetarDepositarioFields = (
  form: FormInstance<IFormValuesTermoApreensaoLiberacao>
) => {
  form.resetFields([
    "nomeDepositario",
    "cpfCnpjDepositario",
    "cepDepositario",
    "emailDepositario",
    "telefoneDepositario",
    "ufDepositarioId",
    "municipioDepositarioId",
    "enderecoDepositario",
    "renasemDepositario",
    "rgDepositario",
    "atuadoDepositario",
  ]);
};
