import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MenuOutlined,
  MinusOutlined,
  PrinterOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";
import { openNotification } from "@components/Notifications";
import { masks } from "@functions/mascaras";
import { usePermission } from "@hooks/usePermission";
import { IFitoAmostrasPDF } from "@interfaces/IFitoAmostrasPDF";
import { IOficialAmostrasPDF } from "@interfaces/IOficialAmosrasPDF";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { apiService } from "@services/api";
import { Button, Dropdown, List, Menu, Modal, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { FitoImpressao } from "../../../components/ImpressaoTermoColeta/FitoImpressao";
import { OficialImpressao } from "../../../components/ImpressaoTermoColeta/OficialImpressao";
import { FITOSSANITARIA, OFICIAL } from "../../../constants";
import { useActionsTermoColeta } from "../../../hooks/useActionsTermoColeta";
import styles from "../../style.module.css";
import { ModalEditDestinoTermoColeta } from "../ModalEditDestinoTermoColeta";

const CODIGO_TERMO = "TermoColeta";

export function ItemListaTermoColeta({
  item,
  pageControl,
}: {
  item: ITermoColetaAmostras;
  pageControl: number;
}) {
  const navigate = useNavigate();
  const fitoRef = useRef(null);
  const oficialTermoRef = useRef(null);
  const { isAllowed } = usePermission();
  const { atualizarTermoColeta, removerTermoColeta, listaTermos } =
    useActionsTermoColeta();
  const [modal, contextHolder] = Modal.useModal();
  const [isCancel, setIsCancel] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalEditDestino, setIsOpenModalEditDestino] = useState(false);
  const [fitoAmostras, setFitoAmostras] = useState<IFitoAmostrasPDF[]>([]);
  const [oficialAmostras, setOficialAmostras] = useState<IOficialAmostrasPDF[]>(
    []
  );

  const { coordMask } = masks;

  //ENVIAR TERMO
  const handlePostTermo = async () => {
    setIsLoading(true);
    await apiService.termoColeta
      .enviar(item)
      .then((res) => {
        atualizarTermoColeta(res.data);
        openNotification({
          type: "success",
          message: "Termo salvo com sucesso!",
          description: "Termo salvo na base de dados.",
        });
      })
      .catch((erro) => {
        console.log(erro);

        openNotification({
          type: "error",
          message: "Não foi possivel salvar o termo.",
        });
      });

    setIsLoading(false);
  };

  //IMPRIMIR
  const handlePrintedTermo = async () => {
    try {
      if (item.tipoColeta === OFICIAL) {
        const auxOficial = item.itens.map((amostra) => {
          return {
            numAmostra: amostra.nrAmostraLasgal,
            cultivar: amostra.cultivar?.nome,
            categoria: amostra.categoria?.nome,
            lote: amostra.lote,
            qtdEmbalagens: amostra.qtdEmbalagens,
            valorEmbalagens: amostra.valorEmbalagem,
            pesoEmbalagens: amostra.pesoEmbalagem,
            validadeTesteGerminacao: amostra.validadeTesteGerminacao,
            pureza: amostra.pureza,
            germinacao: amostra.germinacao,
            tetrazolio: amostra.tetrazolio,
            sementesDuras: amostra.sementesDura,
            outrosFatores: amostra.valorOutrosFatores,
          };
        });

        setOficialAmostras(auxOficial);
      } else {
        const auxFito = item.itens.map((amostra) => {
          return {
            numAmostra: amostra.nrAmostraLsv,
            latitude: `${coordMask(amostra.latGrau.toString())}°${coordMask(
              amostra.latMin.toString()
            )}'${coordMask(amostra.latSeg.toString())}"`,
            longitude: `${coordMask(amostra.longGrau.toString())}°${coordMask(
              amostra.longMin.toString()
            )}'${coordMask(amostra.longSeg.toString())}"`,
            dataPlantio: amostra.dataPlantio,
            cultivar: amostra.cultivar?.nome,
            estadio: amostra.estadio?.nome,
            qtdColetada: amostra.qtdEmbalagens,
            partePlanta: amostra.partePlanta?.nome,
            pragas: "",
          };
        });

        setFitoAmostras(auxFito);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //VISUALIZAR
  const handleViewTermo = async () => {
    if (!item.id) return;
    navigate(`/termo-coleta/listagem/visualizar/${item.id}`);
    localStorage.setItem("termoColeta:pageSelect", pageControl.toString());
  };

  //EDITAR
  const handleEditTermo = () => {
    navigate(
      `/termo-coleta/edit/${item.termoColetaAmostra.replaceAll("/", "_")}`
    );
    localStorage.setItem("termoColeta:pageSelect", pageControl.toString());
  };

  //EXCLUIR
  const handleDeleteTermo = () => {
    setIsDelete(true);
    modal.confirm({
      title: `Tem certeza que deseja Excluir o Termo de coleta ${item.termoColetaAmostra}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      cancelText: "Não",
      onOk: () => {
        removerTermoColeta(item.termoColetaAmostra);
        openNotification({
          type: "success",
          message: "Termo de coleta excluido com sucesso!",
        });
        setIsDelete(false);
      },
      onCancel: () => setIsDelete(false),
    });
  };

  //CANCELAR
  const handleCancelTermo = async () => {
    if (!item.id) return;
    setIsCancel(true);
  };

  //REMOVER DO USO OFFLINE
  const handleRemoveTermoInApp = () => {
    if (!item.id) return;

    const termoColeta = listaTermos.find((auxTermo) => auxTermo.id === item.id);

    if (!termoColeta) return;

    removerTermoColeta(item.termoColetaAmostra);
    openNotification({
      type: "success",
      message: `Termo de Coleta ${termoColeta.termoColetaAmostra} removido  com sucesso!`,
    });
  };

  //Fechar Modal de editar destino no termo de coleta
  const handleCloseModalEditarDestino = () => {
    setIsOpenModalEditDestino(false);
  };

  //Abrir modal de editar destino do termo
  const handleOpenModalEditarDestino = async () => {
    if (!item.id) return;

    try {
      setIsOpenModalEditDestino(true);
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel editar esse termo de coleta",
      });
    }
  };

  const handlePrint = useReactToPrint({
    contentRef: item.tipoColeta === FITOSSANITARIA ? fitoRef : oficialTermoRef,
    documentTitle: `Termo de coleta ${item?.termoColetaAmostra}`,
    pageStyle: `@page { size: landscape A3 !important;}`,
    suppressErrors: true,
    preserveAfterPrint: true,
  });

  useEffect(() => {
    if (oficialAmostras.length > 0 || fitoAmostras.length > 0) {
      handlePrint();
    }
    //eslint-disable-next-line
  }, [oficialAmostras, fitoAmostras]);

  return (
    <>
      {contextHolder}
      <List.Item
        style={{
          border: isCancel || isDelete ? "1px solid #ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          style={{ paddingRight: 10 }}
          title={
            <span
              className="titulo-card"
              style={{
                opacity: item.id ? 1 : 0.7,
                color: isCancel || isDelete ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              Termo de Coleta {item.termoColetaAmostra}{" "}
              {item.id ? "" : "(Pendente)"}
            </span>
          }
          description={
            <span className="subtitulo-card">
              Tipo de coleta: {item.tipoColeta} ID:
              {item.id ? item.id : ""}
              <br />
              Data Coleta:{" "}
              {item.dataColeta.includes("-")
                ? moment(item.dataColeta).format("DD/MM/YYYY")
                : item.dataColeta}
              <br />
              {item.propriedade
                ? `Propriedade: ${item.propriedade?.nome}`
                : item.produtor
                  ? `Estabelecimento: ${item.produtor?.nome}`
                  : ""}
            </span>
          }
        />
        <div className={styles["pc-menu-lista-termos"]}>
          {!item.id && navigator.onLine && (
            <Button disabled={isLoading} onClick={() => handlePostTermo()}>
              {isLoading ? <SyncOutlined spin={isLoading} /> : "Enviar"}
            </Button>
          )}

          <Tooltip title="Imprimir" destroyTooltipOnHide>
            <Button onClick={() => handlePrintedTermo()}>
              <PrinterOutlined />
            </Button>
          </Tooltip>

          {item.id && (
            <>
              <Tooltip title="Visualizar" destroyTooltipOnHide>
                <Button
                  disabled={
                    !navigator.onLine || !isAllowed("visualizar", CODIGO_TERMO)
                  }
                  onClick={() => handleViewTermo()}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Editar" destroyTooltipOnHide>
                <Button
                  disabled={
                    !navigator.onLine ||
                    !isAllowed("editar", CODIGO_TERMO) ||
                    (["EXTERNO", "LSV"].includes(item.destino) &&
                      item.statusAssinatura !== "AUSENTE")
                  }
                  onClick={(e) => {
                    if (item.statusAssinatura === "AUSENTE") {
                      navigate(`/termo-coleta/edit/assinatura/${item.id}`);
                      return;
                    }

                    handleOpenModalEditarDestino();
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Cancelar" destroyTooltipOnHide>
                <Button
                  disabled={
                    !navigator.onLine || !isAllowed("excluir", CODIGO_TERMO)
                  }
                  danger
                  onClick={(e) => handleCancelTermo()}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Remover do uso offline">
                <Button danger onClick={() => handleRemoveTermoInApp()}>
                  <MinusOutlined />
                </Button>
              </Tooltip>
            </>
          )}

          {!item.id && (
            <>
              <Tooltip title="Editar" destroyTooltipOnHide>
                <Button onClick={() => handleEditTermo()}>
                  <EditOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Excluir">
                <Button danger onClick={(e) => handleDeleteTermo()}>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </>
          )}
        </div>

        <div className={styles["mobile-menu-lista-termos"]}>
          {!item.id && navigator.onLine && (
            <Button disabled={isLoading} onClick={() => handlePostTermo()}>
              {isLoading ? <SyncOutlined spin={isLoading} /> : "Enviar"}
            </Button>
          )}

          <Dropdown
            placement="bottom"
            trigger={["click"]}
            className="menu-mobile"
            overlay={() => {
              if (item.id) {
                return (
                  <Menu
                    items={[
                      {
                        key: 0,
                        label: "Imprimir",
                        icon: <PrinterOutlined />,
                        onClick: () => handlePrintedTermo(),
                      },
                      {
                        key: 1,
                        label: "Visualizar",
                        icon: <EyeOutlined />,
                        disabled:
                          !navigator.onLine ||
                          !isAllowed("visualizar", CODIGO_TERMO),
                        onClick: () => handleViewTermo(),
                      },
                      {
                        key: 2,
                        label: "Editar",
                        icon: <EditOutlined />,
                        disabled:
                          !navigator.onLine ||
                          !isAllowed("editar", CODIGO_TERMO) ||
                          (["EXTERNO", "LSV"].includes(item.destino) &&
                            item.statusAssinatura !== "AUSENTE"),
                        onClick: () => {
                          if (item.statusAssinatura === "AUSENTE") {
                            navigate(
                              `/termo-coleta/edit/assinatura/${item.id}`
                            );
                            return;
                          }

                          handleOpenModalEditarDestino();
                        },
                      },
                      {
                        key: 3,
                        danger: true,
                        label: "Cancelar",
                        icon: <CloseOutlined />,
                        disabled:
                          !navigator.onLine ||
                          !isAllowed("excluir", CODIGO_TERMO),
                        onClick: () => handleCancelTermo(),
                      },
                      {
                        key: 4,
                        danger: true,
                        label: "Remover do uso Offline",
                        icon: <MinusOutlined />,
                        onClick: () => handleRemoveTermoInApp(),
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Menu
                    items={[
                      {
                        key: 0,
                        label: "Imprimir",
                        icon: <PrinterOutlined />,
                        onClick: () => handlePrintedTermo(),
                      },
                      {
                        key: 2,
                        label: "Editar",
                        icon: <EditOutlined />,
                        onClick: () => handleEditTermo(),
                      },
                      {
                        key: 3,
                        danger: true,
                        label: "Excluir",
                        icon: <DeleteOutlined />,
                        disabled: !!item.id,
                        onClick: () => handleDeleteTermo(),
                      },
                    ]}
                  />
                );
              }
            }}
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      {/**MODAL CANCELAMENTO TERMO COLETA */}
      <ModalCancelarTermo
        isOpen={isCancel}
        setIsOpen={setIsCancel}
        numTermo={item.termoColetaAmostra}
        apiName="termoColeta"
        idTermo={item.id}
        onRemoveTermo={removerTermoColeta}
        titleTermo="Termo de Coleta"
        reloadFilter={async () => {}}
      />

      <ModalEditDestinoTermoColeta
        isOpen={isOpenModalEditDestino}
        onClose={handleCloseModalEditarDestino}
        termoSelected={item}
      />

      <div style={{ display: "none" }}>
        {item?.tipoColeta === FITOSSANITARIA && (
          <FitoImpressao
            ref={fitoRef}
            termo={item}
            listaAmostras={fitoAmostras}
            marcaDagua=""
          />
        )}

        {item?.tipoColeta === OFICIAL && (
          <OficialImpressao
            ref={oficialTermoRef}
            termo={item}
            listaAmostras={oficialAmostras}
            marcaDagua=""
          />
        )}
      </div>
    </>
  );
}
