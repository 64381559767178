import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { masks } from "@functions/mascaras";
import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import moment from "moment";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  height: 2vh;
`;

const ContainerAssinaturas = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const CONECTOR_NAMES = ["da", "de", "do", "dos", "das"];

export function FooterTermoApreensaoLiberacaoImpressao({
  termo,
}: {
  termo: ITermoApreensaoLiberacao;
}) {
  const { cpfCnpjMask } = masks;

  const formataNome = (str: string) => {
    const names = str.toLowerCase().split(" ");
    let aux = "";

    names.map((name) => {
      if (!name) return "";

      if (CONECTOR_NAMES.includes(name)) {
        aux = aux + " " + name;
      } else {
        aux = aux + " " + name[0].toUpperCase() + name.substring(1);
      }
      return name;
    });

    return aux;
  };

  return (
    <>
      <tfoot>
        <tr>
          <td style={{ border: "1px solid black" }}>
            <div>
              <Container style={{ display: "flex" }}>
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                  }}
                >
                  <span>Municipio: {termo.municipioFiscalizacao?.nome}</span>
                </div>
                <div
                  style={{
                    width: "25%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                  }}
                >
                  <span>
                    Data:{" "}
                    {moment(termo.dataCadastro).locale("pt-br").format("L")}
                  </span>
                </div>
                <div style={{ width: "25%", paddingLeft: 5 }}>
                  <span>
                    Horário:{" "}
                    {termo.dataCadastro && termo.dataCadastro.slice(11, 16)}
                  </span>
                </div>
              </Container>

              <ContainerAssinaturas id="footer-impressao-form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 10,
                    flexWrap: "wrap",
                  }}
                >
                  {termo.servidores.map((serv, ind) => {
                    return (
                      <div
                        key={ind}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingBottom: 10,
                          paddingTop: 10,
                          alignItems: "center",
                        }}
                      >
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="4cm"
                          height="2.5cm"
                          viewBox="0 0 645 600"
                          enableBackground="new 0 0 645 600"
                          xmlSpace="preserve"
                          style={{ borderBottom: "1px solid black" }}
                        >
                          <image
                            id="image0"
                            width="17cm"
                            height="15.875cm"
                            x="0"
                            y="0"
                            href={serv.assinatura}
                          />
                        </svg>

                        <span style={{ fontSize: 12 }}>
                          Fiscal/Agente Fiscal
                        </span>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            // minWidth: "4cm",
                          }}
                        >
                          <span style={{ fontSize: 10 }}>
                            Nome: {formataNome(serv.servidor.nome || "")}
                          </span>

                          <span style={{ fontSize: 10 }}>
                            Matricula: {serv.servidor.matricula}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                  {termo.cpfCnpjDepositario && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingBottom: 10,
                        paddingTop: 10,
                        alignItems: "center",
                      }}
                    >
                      {termo.statusAssinaturaDepositario ===
                        "ASSINADO_DIGITALMENTE" && (
                        <svg
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          width="4cm"
                          height="2.5cm"
                          viewBox="0 0 645 600"
                          enableBackground="new 0 0 645 600"
                          xmlSpace="preserve"
                          style={{ borderBottom: "1px solid black" }}
                        >
                          <image
                            id="image0"
                            width="17cm"
                            height="15.875cm"
                            x="0"
                            y="0"
                            href={termo.assinaturaDepositario}
                          />
                        </svg>
                      )}

                      {(termo.statusAssinaturaDepositario === "AUSENTE" ||
                        termo.statusAssinaturaDepositario === "RECUSADO") && (
                        <p
                          style={{
                            borderBottom: "1px solid black",
                            width: "4cm",
                            height: navigator.onLine ? "2.5cm" : "2cm",
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "center",
                            marginBottom: 0,
                          }}
                        >
                          {termo.statusAssinaturaDepositario}
                        </p>
                      )}

                      {termo.statusAssinaturaDepositario ===
                        ASSINATURA_FISICA && (
                        <div
                          style={{
                            borderBottom: "1px solid black",
                            width: "4cm",
                            height: "2.5cm",
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "center",
                            paddingLeft: 5,
                          }}
                        />
                      )}

                      <span style={{ fontSize: 12 }}>Depositário</span>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          minWidth: "4cm",
                        }}
                      >
                        <span style={{ fontSize: 10, width: "100%" }}>
                          Nome: {termo.nomeAssinanteDepositario}
                        </span>
                        <span style={{ fontSize: 10 }}>
                          CPF:{" "}
                          {cpfCnpjMask(termo.cpfAssinanteDepositario || "")}
                        </span>
                        <span style={{ fontSize: 10 }}>
                          Função: {termo.funcaoAssinanteDepositario}
                        </span>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingBottom: 10,
                      paddingTop: 10,
                      alignItems: "center",
                    }}
                  >
                    {termo.statusAssinaturaFiscalizado ===
                      "ASSINADO_DIGITALMENTE" && (
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        width="4cm"
                        height="2.5cm"
                        viewBox="0 0 645 600"
                        enableBackground="new 0 0 645 600"
                        xmlSpace="preserve"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        <image
                          id="image0"
                          width="17cm"
                          height="15.875cm"
                          x="0"
                          y="0"
                          href={termo.assinaturaFiscalizado}
                        />
                      </svg>
                    )}

                    {(termo.statusAssinaturaFiscalizado === "AUSENTE" ||
                      termo.statusAssinaturaFiscalizado === "RECUSADO") && (
                      <p
                        style={{
                          borderBottom: "1px solid black",
                          width: "4cm",
                          height: navigator.onLine ? "2.5cm" : "2cm",
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "center",
                          marginBottom: 0,
                        }}
                      >
                        {termo.statusAssinaturaFiscalizado}
                      </p>
                    )}

                    {termo.statusAssinaturaFiscalizado ===
                      ASSINATURA_FISICA && (
                      <div
                        style={{
                          borderBottom: "1px solid black",
                          width: "4cm",
                          height: "2.5cm",
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "center",
                          paddingLeft: 5,
                        }}
                      />
                    )}

                    <span style={{ fontSize: 12 }}>
                      Inspecionado/Fiscalizado
                    </span>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        minWidth: "4cm",
                      }}
                    >
                      <span style={{ fontSize: 10, width: "100%" }}>
                        Nome: {termo.nomeAssinanteFiscalizado}
                      </span>
                      <span style={{ fontSize: 10 }}>
                        CPF: {cpfCnpjMask(termo.cpfAssinanteFiscalizado || "")}
                      </span>
                      <span style={{ fontSize: 10 }}>
                        Função: {termo.funcaoAssinanteFiscalizado}
                      </span>
                    </div>
                  </div>
                </div>
              </ContainerAssinaturas>
            </div>
          </td>
        </tr>
      </tfoot>
    </>
  );
}
