import { ICategoria } from "@interfaces/ICategoria";
import { categoriaAction } from "@store/actions/categoriaAction";
import { getCategorias } from "@store/feature/categoriaSlice";
import { useDispatch, useSelector } from "react-redux";

export const useCategoriaSemente = () => {
  const listaCategoriaSemente = useSelector(getCategorias);
  const { addCategorias, removeCategorias } = categoriaAction;
  const appDispatch = useDispatch();

  const adicionarCategorias = (
    categoria: ICategoria[],
    persistedAt: number
  ) => {
    appDispatch(addCategorias(categoria, persistedAt));
  };

  const removerCategorias = () => {
    appDispatch(removeCategorias());
  };

  return { listaCategoriaSemente, adicionarCategorias, removerCategorias };
};
