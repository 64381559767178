import { LoadingOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { checkDevice } from "@functions/checkDevice";
import { apiService } from "@services/api";
import { TitleTermo } from "@styles/TitleTermo";
import {
  Checkbox,
  Divider,
  Form,
  List,
  message,
  Pagination,
  Spin,
  Typography,
} from "antd";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useEffect, useState } from "react";
import { useActionsTID } from "../hooks/useActionsTID";
import { ButtonEnviarBackupTID } from "./components/ButtonEnviarBackupTID";
import { FiltroTID } from "./components/FiltroTID";
import { ItemListaFiltroTID } from "./components/ItemListaFiltroTID";
import { ItemListaTID } from "./components/ItemListaTID";
import { OPTIONS_TID_FILTER } from "./constants/OPTIONS_TID_FILTER";
import styles from "./style.module.css";

const PAGE_SIZE = 20;

export function ListagemTID() {
  const [formFiltro] = Form.useForm();
  const { listaTIDs, listaFiltroTID, aplicarFiltro, removerFiltro } =
    useActionsTID();

  const [pageControl, setPageControl] = useState(1);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [optionsFiltroSelected, setOptionsFiltroSelected] = useState<
    CheckboxOptionType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(PAGE_SIZE);

  /**FUNCTIONS */

  //PAGINAÇÃO DA LISTAGEM DE TERMOS
  const handleChangePagination = async (page: number, filtro?: boolean) => {
    const keyMessage = "tid-pagination";
    if (filtro && !navigator.onLine) return;

    // localStorage.setItem("TID:pageSelect", page.toString());

    setPageControl(page);

    if (filtro) {
      const params = new URLSearchParams(listaFiltroTID[0].params);
      params.append("page", (page - 1).toString());
      params.append("size", PAGE_SIZE.toString());
      setLoading(true);
      message.loading({
        key: keyMessage,
        content: "Carregando termos...",
        duration: 0,
      });

      await apiService.tid
        .listar(params.toString())
        .then((data) => {
          // console.log(res);
          aplicarFiltro([
            {
              ...listaFiltroTID[0],
              termos: data.content,
            },
          ]);
        })
        .catch((erro) => console.log(erro));

      message.destroy(keyMessage);
      setLoading(false);
      return;
    }
    setMinIndex((page - 1) * PAGE_SIZE);
    setMaxIndex(page * PAGE_SIZE);
  };

  const handleChangeFilterSelect = async (values: CheckboxValueType[]) => {
    const keyMessage = "tid-reload-filtro";
    const params = new URLSearchParams(listaFiltroTID[0].params);
    const newParams = new URLSearchParams();

    formFiltro.resetFields();

    if (values.length === 0) {
      removerFiltro();
      openNotification({
        type: "info",
        message: "Filtro Removido",
      });
      handleChangePagination(1);
      return;
    }
    values.forEach((value: any) => {
      newParams.append(value, params.get(value)?.toString() || "");
      formFiltro.setFieldValue(value, params.get(value)?.toString());
    });
    newParams.append("size", PAGE_SIZE.toString());

    setCheckedList(values);
    setLoading(true);
    message.loading({
      key: keyMessage,
      content: "Carregando termos...",
      duration: 0,
    });

    await apiService.tid
      .listar(newParams.toString())
      .then((data) => {
        aplicarFiltro([
          {
            filtros: values,
            params: newParams.toString(),
            termos: data.content,
            numberPages: data.totalPages,
          },
        ]);

        setPageControl(1);
        message.destroy(keyMessage);
        setLoading(false);
        openNotification({
          type: "success",
          message: `Filtro Atualizado`,
        });
      })
      .catch((erro) => {
        console.log(erro);
        message.destroy(keyMessage);
        setLoading(false);
        openNotification({
          type: "error",
          message: `Não foi possivel buscar Termo(s) de Interidição/Desinterdição.`,
          description: erro.response.data.detail,
        });
        // window.location.reload();
      });
  };

  useEffect(() => {
    if (listaFiltroTID.length > 0) {
      setCheckedList(listaFiltroTID[0].filtros);

      const optionsFiltro = OPTIONS_TID_FILTER.filter((option) => {
        if (listaFiltroTID[0].filtros.includes(option.value)) {
          return option;
        }

        return null;
      });

      setOptionsFiltroSelected(optionsFiltro);
    }

    // eslint-disable-next-line;
  }, [listaFiltroTID]);

  useEffect(() => {
    let pageSelected = pageControl;

    if (localStorage.getItem("TID:pageSelect")) {
      pageSelected = parseInt(localStorage.getItem("TID:pageSelect") || "");

      localStorage.removeItem("TID:pageSelect");
    }

    handleChangePagination(pageSelected, listaFiltroTID.length > 0);

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <TitleTermo>Termo(s) de Interdição/Desinterdição</TitleTermo>
      <div className={styles["container-button-listagem"]}>
        <FiltroTID formFiltro={formFiltro} setPageControl={setPageControl} />

        <ButtonEnviarBackupTID />
      </div>

      <Divider />
      {listaFiltroTID.length > 0 ? (
        <>
          <div style={{ display: "flex", gap: 10, paddingBottom: 10 }}>
            <Typography>Filtro Aplicado:</Typography>
            <Checkbox.Group
              onChange={handleChangeFilterSelect}
              options={optionsFiltroSelected}
              value={checkedList}
              disabled={loading}
            />
          </div>

          {loading ? (
            <div className={styles["container-loading"]}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </div>
          ) : (
            <div className={styles["heigth-list"]}>
              <List
                style={{
                  boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                }}
                bordered
                dataSource={listaFiltroTID[0].termos}
                renderItem={(item, ind) => {
                  return (
                    <ItemListaFiltroTID
                      key={ind}
                      item={item}
                      pageControl={pageControl}
                    />
                  );
                }}
              />
            </div>
          )}
          <Pagination
            size={checkDevice() ? "small" : "default"}
            showSizeChanger={false}
            current={pageControl}
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
            pageSize={PAGE_SIZE}
            total={listaFiltroTID[0].numberPages * PAGE_SIZE}
            onChange={(page) => handleChangePagination(page, true)}
          />
        </>
      ) : (
        <>
          <div className={styles["heigth-list"]}>
            <List
              style={{
                boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
              }}
              bordered
              dataSource={listaTIDs.slice(minIndex, maxIndex)}
              renderItem={(item, ind) => {
                return (
                  <ItemListaTID
                    key={ind}
                    termo={item}
                    pageControl={pageControl}
                  />
                );
              }}
            />
          </div>

          <Pagination
            size={checkDevice() ? "small" : "default"}
            showSizeChanger={false}
            current={pageControl}
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
            pageSize={PAGE_SIZE}
            total={listaTIDs.length}
            onChange={(page) => handleChangePagination(page)}
          />
        </>
      )}
    </Fragment>
  );
}
