import "antd/dist/antd.css";
import ErrorBoundary from "errorBoundary";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { oidcConfig } from "./services/authConfig";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ErrorBoundary>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </ErrorBoundary>
);

const configuration = {
  onUpdate: (registration: any) => {
    if (registration && registration.waiting) {
      if (
        window.confirm(
          "Nova versão disponivel!  Deseja atualizar o aplicativo?"
        )
      ) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        window.location.reload();
      }
    }
  },
};

serviceWorkerRegistration.register(configuration);

reportWebVitals();
