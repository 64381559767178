import { openNotification } from "@components/Notifications";
import { masks } from "@functions/mascaras";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { useMunicipio } from "@hooks/useMunicipio";
import { usePropriedade } from "@hooks/usePropriedade";
import { IAssinante } from "@interfaces/IAssinante";
import { IEstabelecimento } from "@interfaces/IEstabelecimento";
import { IFormValuesTermoColeta } from "@interfaces/IFormValuesTermoColeta";
import { IPropriedade } from "@interfaces/IPropriedade";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import {
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Typography,
} from "antd";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { Fragment, useEffect, useState } from "react";

interface IOrigemProps {
  formColeta: FormInstance<IFormValuesTermoColeta>;
  setAssinaturaFiscalizado: React.Dispatch<React.SetStateAction<IAssinante>>;
  assinaturaFiscalizado: IAssinante;
  termoEdit?: ITermoColetaAmostras;
}

export function OrigemTCA({
  formColeta,
  setAssinaturaFiscalizado,
  assinaturaFiscalizado,
  termoEdit,
}: IOrigemProps) {
  const { cpfCnpjMask } = masks;
  const { buscarEstabelecimentoPeloCnpj } = useEstabelecimento();
  const { buscarPropriedadePeloCpfCnpj } = usePropriedade();
  const { municipiosMt } = useMunicipio();
  const [isPropriedade, setIsPropriedade] = useState(false);
  const [isSearchOrigem, setIsSearchOrigem] = useState(false);
  const [isErrorSearchOrigem, setIsErrorSearchOrigem] = useState(false);
  const [propriedadeOptions, setPropriedadeOptions] = useState<IPropriedade[]>(
    []
  );
  const [estabelecimentoOptions, setEstabelecimentoOptions] = useState<
    IEstabelecimento[]
  >([]);
  const [hasPropEstab, setHasPropEstab] = useState(false);

  const handleChangePropEstab = (value: number) => {
    if (!value) {
      formColeta.resetFields(["municipioColetaId"]);
      return;
    }

    if (isPropriedade) {
      const propSelected = propriedadeOptions.filter(
        (prop) => prop.id === value
      );
      formColeta.setFieldValue(
        "municipioColetaId",
        propSelected[0].municipio.id
      );

      setAssinaturaFiscalizado({
        ...assinaturaFiscalizado,
        nomeAssinante: propSelected[0].produtores[0].nome,
        cpfAssinante: cpfCnpjMask(propSelected[0].produtores[0].cpf),
      });
      return;
    }

    const estabSelected = estabelecimentoOptions.filter(
      (estab) => estab.id === value
    );
    formColeta.setFieldValue(
      "municipioColetaId",
      estabSelected[0].endereco.municipio.id
    );
  };

  const handleChangeCpfCnpjOrigem = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;

    if (
      !cpf.isValid(value.replace(/[^\d]+/g, "")) ||
      !cnpj.isValid(value.replace(/[^\d]+/g, ""))
    ) {
      handleResetOrigem();
      handleResetLocalColeta();
    }

    formColeta.setFieldValue("cpfCnpjOrigem", cpfCnpjMask(value));
  };

  const handleChangeOrigemType = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setIsPropriedade(value === "PROPRIEDADE");
    formColeta.resetFields(["propriedadeId", "estabelecimentoId"]);

    handleResetOrigem();
    handleResetLocalColeta();
  };

  const handleResetOrigem = () => {
    setIsSearchOrigem(false);
    setIsErrorSearchOrigem(false);

    formColeta.resetFields([
      "municipioOrigemId",
      "nomeOrigem",
      "propriedadeId",
      "estabelecimentoId",
    ]);

    setAssinaturaFiscalizado({
      ...assinaturaFiscalizado,
      nomeAssinante: "",
      cpfAssinante: "",
      funcaoAssinante: "",
    });
  };

  const handleResetLocalColeta = () => {
    formColeta.setFieldValue("municipioColetaId", "");
  };

  const handleSearch = (cpfCnpj: string) => {
    if (!formColeta.getFieldValue("caracteristicaOrigem")) {
      openNotification({
        type: "error",
        message: "selecione um tipo de origem",
      });
      return;
    }

    //VERIFICANDO SE O FORMATO DE DOCUMENTO DIGITADO É UM CPF OU CNPJ
    if (!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) {
      openNotification({
        type: "error",
        message: "Digite um CPF ou CNPJ válido",
      });
      setIsErrorSearchOrigem(true);
      return;
    }

    //CASO ESTABELECIMENTO VERIFICA SE FOI DIGITADO UM CNPJ
    if (
      formColeta.getFieldValue("caracteristicaOrigem") === "ESTABELECIMENTO" &&
      cpf.isValid(cpfCnpj)
    ) {
      openNotification({
        type: "error",
        message: "Digite um CNPJ valido",
        description: "Para buscar estabelecimentos é preciso fornecer um CNPJ",
      });
      setIsErrorSearchOrigem(true);
      return;
    }

    setIsSearchOrigem(true);

    if (isPropriedade) {
      buscaPropriedade(cpfCnpj.replace(/[^\d]+/g, ""));
    } else {
      buscaEstabelecimento(cpfCnpj.replace(/[^\d]+/g, ""));
    }
  };

  const buscaEstabelecimento = async (cpfCnpj: string) => {
    try {
      const estabelecimentos = await buscarEstabelecimentoPeloCnpj(cpfCnpj);

      if (estabelecimentos.length > 0) {
        setEstabelecimentoOptions(estabelecimentos);
        setHasPropEstab(true);
        formColeta.setFieldValue("nomeOrigem", estabelecimentos[0].nome);
        formColeta.setFieldValue(
          "municipioOrigemId",
          estabelecimentos[0].endereco.municipio.id
        );
      } else {
        setHasPropEstab(false);
        openNotification({
          type: "info",
          message: "Estabelecimento não encontrado",
        });
      }
    } catch (error) {
      setHasPropEstab(false);
      openNotification({
        type: "error",
        message: "Não foi possivel encontrar Estabelecimentos desse CNPJ",
      });
    }
  };

  const buscaPropriedade = async (cpfCnpj: string) => {
    try {
      const propriedades = await buscarPropriedadePeloCpfCnpj(cpfCnpj);

      if (propriedades.length > 0) {
        setPropriedadeOptions(propriedades);
        setHasPropEstab(true);
        const produtor = propriedades[0].produtores.filter(
          (produtor) => produtor.cpf === cpfCnpj
        )[0];

        formColeta.setFieldsValue({
          nomeOrigem: produtor.nome,
          municipioOrigemId: produtor.endereco.municipio.id,
        });
      } else {
        setHasPropEstab(false);
        openNotification({
          type: "info",
          message: "Propriedade não encontrada",
        });
      }
    } catch (error) {
      setHasPropEstab(false);
      openNotification({
        type: "error",
        message: "Não foi possivel encontrar Propriedades desse CPF/CNPJ",
      });
    }
  };

  const preencherDadosPropriedade = async (
    cpfCnpj: string,
    propriedadeId: number
  ) => {
    await buscaPropriedade(cpfCnpj);
    formColeta.setFieldsValue({
      propriedadeId: propriedadeId,
    });
  };

  const preencherDadosEstabelecimento = async (
    cpfCnpj: string,
    estabelecimentoId: number
  ) => {
    await buscaEstabelecimento(cpfCnpj);
    formColeta.setFieldsValue({
      propriedadeId: estabelecimentoId,
    });
  };

  useEffect(() => {
    if (termoEdit && termoEdit.propriedade) {
      setIsSearchOrigem(true);
      setIsPropriedade(true);
      preencherDadosPropriedade(
        termoEdit.cpfCnpjRequerente,
        termoEdit.propriedade.id
      );
      return;
    }

    if (termoEdit && !termoEdit.propriedade) {
      setIsSearchOrigem(true);
      preencherDadosEstabelecimento(
        termoEdit.cpfCnpjRequerente,
        termoEdit.produtor.id
      );
    }

    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Typography.Title level={3}>Origem</Typography.Title>

      <Form.Item name="caracteristicaOrigem" label="Origem">
        <Radio.Group onChange={handleChangeOrigemType}>
          <Radio value="PROPRIEDADE">Propriedade</Radio>
          <Radio value="ESTABELECIMENTO">Estabelecimento</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="cpfCnpjOrigem"
        label="CPF / CNPJ"
        rules={[{ required: true }]}
      >
        <Input.Search
          maxLength={18}
          placeholder="Digite o CPF/CNPJ"
          onChange={handleChangeCpfCnpjOrigem}
          onSearch={handleSearch}
          enterButton="Buscar"
          status={isErrorSearchOrigem ? "error" : ""}
        />
      </Form.Item>

      <Form.Item name="nomeOrigem" label="Nome">
        <Input disabled={!isSearchOrigem || !hasPropEstab} />
      </Form.Item>

      <Form.Item
        label={isPropriedade ? "Propriedade" : "Estabelecimento"}
        name={isPropriedade ? "propriedadeId" : "estabelecimentoId"}
      >
        <Select
          allowClear
          style={{ minWidth: "190px" }}
          disabled={!isSearchOrigem || !hasPropEstab}
          onChange={handleChangePropEstab}
        >
          {isPropriedade
            ? propriedadeOptions.map((prop) => (
                <Select.Option key={prop.id} value={prop.id}>
                  {`${prop.codigo}-${prop.nome}`}
                </Select.Option>
              ))
            : estabelecimentoOptions.map((estab) => (
                <Select.Option key={estab.id} value={estab.id}>
                  {estab.nome}
                </Select.Option>
              ))}
        </Select>
      </Form.Item>

      <Typography.Title level={3}>Local</Typography.Title>
      <Form.Item
        name="municipioColetaId"
        label="Municipio de Coleta"
        rules={[
          {
            required: true,
            message: "Por favor, selecione um municipio",
          },
        ]}
      >
        <Select
          allowClear
          style={{ minWidth: "190px" }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          disabled={!isSearchOrigem || !hasPropEstab}
        >
          {municipiosMt.map((municipio) => (
            <Select.Option key={municipio.id} value={municipio.id}>
              {`${municipio.nome}-${municipio.uf.sigla}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Fragment>
  );
}
