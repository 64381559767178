import {
  DeleteOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { masks } from "@functions/mascaras";
import { useDadosTif } from "@hooks/useDadosTif";
import { IColumnsServidores } from "@interfaces/IColumnsServidores";
import { IFormAtividades } from "@interfaces/IFormAtividades";
import { IPergunta } from "@interfaces/IPergunta";
import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { IRespostaAtividade } from "@interfaces/IRespostaAtividade";
import { ITabelaAtividadeTIF } from "@interfaces/ITabelaAtividadeTIF";
import { ITif } from "@interfaces/ITif";
import { ITipoAtividade } from "@interfaces/ITipoAtividade";
import { apiService } from "@services/api";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { TitleTermo } from "@styles/TitleTermo";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  List,
  Modal,
  Radio,
  Result,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { ColumnsType } from "antd/lib/table";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { saveAs } from "file-saver";
import moment from "moment";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  FISCALIZACAO_FRONTEIRA,
  TIPOS_ATUADO,
  TIPOS_REPRESENTANTES,
  TIPOS_TERMO,
} from "../../constants";
import { desorganizarListaAtividade } from "../../functions/desorganizarListaAtividade";
import { ordenarPerguntasAtividade } from "../../functions/ordenarPerguntasAtividade";
import { useTif } from "../../hooks/useTif";
import styles from "./style.module.css";

export function ViewTIF() {
  const { tifId } = useParams();
  const navigate = useNavigate();
  const [perguntasForm] = Form.useForm();
  const { cepMask, cpfCnpjMask, coordMask, telefoneMask } = masks;
  const { adicionarAtividade, removerTodasAtividades } = useDadosTif();
  const [motivoNaoAssinatura, setMotivoNaoAssinatura] = useState<
    CheckboxValueType[]
  >([]);
  const [servidoresNaGrid, setServidoresNaGrid] = useState<
    IColumnsServidores[]
  >([]);
  const [atividadesInGrid, setAtividadesInGrid] = useState<
    ITabelaAtividadeTIF[]
  >([]);
  const [atividadeSelected, setAtividadeSelected] =
    useState<ITipoAtividade | null>(null);
  const [isOpenModalQuestionario, setIsOpenModalQuestionario] = useState(false);
  const [perguntas, setPerguntas] = useState<IPerguntaAtividade[]>([]);
  const [anexos, setAnexos] = useState<any[]>([]);
  const { getAnexo } = useTif();

  const { data, status, remove } = useQuery({
    queryFn: () => apiService.tif.byId(parseInt(tifId || "")),
    queryKey: ["VISUALIZAR_TIF"],
    onSuccess: async (data) => {
      const servs = data.servidores.map((serv: any) => {
        return {
          key: serv.servidor.matricula,
          nome: serv.servidor.nome,
          cpfCnpj: serv.servidor.cpfCnpj,
        };
      });

      setServidoresNaGrid(servs);
      //ESTRUTURANDO NOVAMENTE O OBJETO DE ATIVIDADES
      const atividades = data.atividades.map((atv) => {
        const auxText: string[] = [];
        if (atv.coleta) {
          auxText.push("Coleta");
        }

        if (atv.denuncia) {
          auxText.push("Atendimento à denuncia");
        }

        if (atv.desinterdicao) {
          auxText.push("Desinterdição");
        }

        if (atv.interdicao) {
          auxText.push("Interdição");
        }

        if (atv.notificacao) {
          auxText.push("Notificação");
        }

        return {
          key: atv.id,
          programa: atv.areaAtividade.nome,
          atividade: atv.atividade.nome,
          especificacoes: atv.armadilha
            ? atv.armadilha.codigo
            : auxText.join(","),
          prazo: atv.prazoAdequacao || "",
          perguntas: atv.atividade.questoes,
          armadilha: atv.armadilha,
        };
      });

      setAtividadesInGrid(atividades);

      removerTodasAtividades();
      atividades.map((atividade: any) => {
        adicionarAtividade(atividade);
        return atividade;
      });

      setMotivoNaoAssinatura([data.statusAssinatura]);

      if (data.anexosTIF.length > 0) {
        const dadosAnexos = data.anexosTIF.map((anexo) => {
          return {
            nomeUpload: anexo.nomeUpload,
            nomeArquivo: anexo.nomeArquivo,
          };
        });

        const res = await getAnexo(dadosAnexos);

        setAnexos(res);
      }
    },
  });

  const termoInEdit = (form: ITif) => {
    const auxTermo: string[] = [];

    if (form.fiscalizacao) auxTermo.push("fiscalizacao");
    if (form.inspecao) auxTermo.push("inspecao");
    if (form.notificacao) auxTermo.push("notificacao");

    return auxTermo;
  };

  const columnsServidores: ColumnsType<IColumnsServidores> = [
    {
      title: "Matrícula",
      dataIndex: "key",
    },
    {
      title: "CPF / CNPJ",
      dataIndex: "cpfCnpj",
      render: (document: string) => (
        <p>
          {document.length > 14 ? cnpj.format(document) : cpf.format(document)}
        </p>
      ),
    },
    {
      title: "Nome",
      dataIndex: "nome",
    },
  ];

  const columnsAtividade: ColumnsType<ITabelaAtividadeTIF> = [
    {
      title: "Programa",
      dataIndex: "programa",
    },
    {
      title: "Atividade",
      dataIndex: "atividade",
    },
    {
      title: "Especificação",
      dataIndex: "especificacoes",
    },
    {
      title: "Prazo",
      dataIndex: "prazo",
      render: (prazo: string) => (
        <p>{prazo === "" ? "" : moment(prazo).locale("pt-br").format("L")}</p>
      ),
    },
    {
      title: "Ações",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Visualizar Questionário">
            <Button
              disabled={record.perguntas.length === 0}
              onClick={(e) => {
                if (!data) return;
                const atividades = desorganizarListaAtividade(data.atividades);

                handleOpenModal(
                  atividades.find((atv) => atv.key === record.key)
                );
              }}
            >
              <QuestionCircleOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Excluir">
            <Button danger disabled>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleBack = () => {
    removerTodasAtividades();
    remove();
    navigate("/TIF/listagem");
  };

  const handleCloseModal = () => {
    setIsOpenModalQuestionario(false);
    setAtividadeSelected(null);
    setPerguntas([]);
  };

  const handleOpenModal = (info: IFormAtividades | undefined) => {
    if (!data || !info) return;

    //VERIFICANDO SE JA EXISTE RESPOSTAS PARA ESSE QUESTIONARIO
    const respostas = data.respostasAtividades.filter((res) => {
      if (info.armadilha) {
        if (res.armadilha?.id === info.armadilha.id) {
          return res;
        }
        return null;
      } else {
        if (res.atividade.id === info.atividade.id) {
          return res;
        }

        return null;
      }
    });

    let perguntasAtv: IPerguntaAtividade[] = [];

    respostas.forEach((res) => {
      if (res.alternativa) {
        perguntasForm.setFieldValue(
          `resposta_alternativa_${res.pergunta.id}`,
          res.alternativa.id
        );
      }

      res.campos.forEach((campo, ind) => {
        perguntasForm.setFieldValue(
          `resposta_campo_${ind}_${res.pergunta.id}`,
          campo.resposta
        );
      });

      if (res.descricao) {
        perguntasForm.setFieldValue(
          `resposta_descritiva_${res.pergunta.id}`,
          res.descricao
        );
      }
    });

    setAtividadeSelected(info.atividade);
    setIsOpenModalQuestionario(true);

    //PERGUNTAS INDEPENDENTES
    info.atividade.questoes.forEach((perg) => {
      if (perg.pergunta.antecessora === null) {
        perguntasAtv.push({
          ...perg,
          indPergunta: `${perguntasAtv.length + 1}`,
        });
      }
    });

    //VERIFICANDO SE SO POSSUI PERGUNTAS INDEPENDENTES NA LISTAGEM
    if (respostas.length === perguntasAtv.length || respostas.length === 0) {
      setPerguntas(perguntasAtv);
      return;
    }

    let arrayPerg = separarEPreencherArrayPerguntas(perguntasAtv, info);
    perguntasAtv = perguntasAtv.concat(arrayPerg);
    let arraySemDuplicados = filtrarPerguntasParaArray(perguntasAtv, []);

    for (let i = arraySemDuplicados.length; i < respostas.length; i++) {
      //VERIFICANDO SE FOI LISTADAS TODAS AS PERGUNTAS
      if (respostas.length === arraySemDuplicados.length) break;

      arrayPerg = separarEPreencherArrayPerguntas(perguntasAtv, info);
      perguntasAtv = perguntasAtv.concat(arrayPerg);
      arraySemDuplicados = filtrarPerguntasParaArray(
        perguntasAtv,
        arraySemDuplicados
      );
    }

    setPerguntas(arraySemDuplicados);
  };

  const filtrarPerguntasParaArray = (
    perguntasSelected: IPerguntaAtividade[],
    arraySemDuplicados: IPerguntaAtividade[]
  ) => {
    perguntasSelected.forEach((perg) => {
      if (!arraySemDuplicados.some((aux) => aux.id === perg.id)) {
        arraySemDuplicados.push(perg);
      }
    });

    return ordenarPerguntasAtividade(arraySemDuplicados);
  };

  const separarEPreencherArrayPerguntas = (
    perguntasPais: IPerguntaAtividade[],
    form: IFormAtividades
  ) => {
    if (!data) return [];
    let auxPerguntas: IPerguntaAtividade[] = [];

    perguntasPais.forEach((perg) => {
      const resposta = data.respostasAtividades.filter(
        (res) => res.pergunta.id === perg.pergunta.id
      )[0];
      auxPerguntas = auxPerguntas.concat(
        listarArrayPerguntas(perg, resposta, form.atividade.questoes)
      );
    });

    return auxPerguntas;
  };

  const listarArrayPerguntas = (
    perguntaPai: IPerguntaAtividade,
    resposta: IRespostaAtividade,
    todasPerguntas: IPergunta[]
  ) => {
    if (!resposta) return [];

    let perguntasDependentes = todasPerguntas.filter(
      (perg) =>
        perg.pergunta.antecessora?.id === resposta.pergunta.id &&
        perg.pergunta.respostaCondicional?.id === resposta?.alternativa?.id
    );

    perguntasDependentes = perguntasDependentes.map((perg, ind) => {
      return {
        ...perg,
        indPergunta: `${perguntaPai.indPergunta}.${ind + 1}`,
      };
    });

    return perguntasDependentes;
  };

  if (status === "error") {
    return (
      <Fragment>
        <Result
          status="error"
          title="Erro ao Visualizar TIF"
          subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
          extra={
            <Button onClick={() => navigate("/TIF/listagem")}> Voltar</Button>
          }
        />
      </Fragment>
    );
  } else if (
    status === "loading" ||
    status === "idle" ||
    (data.anexosTIF.length > 0 && anexos.length === 0)
  ) {
    return (
      <Fragment>
        <div
          style={{
            minHeight: "80vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <TitleTermo>Termo de Inspeção, Fiscalização e Notificação</TitleTermo>

        <Divider />
        <Form
          id="form-register"
          labelWrap
          labelAlign="left"
          labelCol={{ flex: "200px", span: 20 }}
          wrapperCol={{ flex: 1, span: 14 }}
        >
          <Form.Item label="N° TIF">
            <Input value={data.numero} />
          </Form.Item>

          <Form.Item label="Termo">
            <Checkbox.Group options={TIPOS_TERMO} value={termoInEdit(data)} />
          </Form.Item>

          <Form.Item label="Fiscalização Fronteira">
            <Radio.Group value={data.fiscalizacaoFronteira ? "SIM" : "NAO"}>
              {FISCALIZACAO_FRONTEIRA.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Atuado">
            <Radio.Group value={data.caracteristicaFiscalizado?.toLowerCase()}>
              {TIPOS_ATUADO.map((at, ind) => (
                <Radio key={ind} value={at.value}>
                  {at.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Relação">
            <Radio.Group value={data.tipoRepresentante}>
              {TIPOS_REPRESENTANTES.map((representante, ind) => (
                <Radio key={ind} value={representante.value}>
                  {representante.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item label="CPF/CNPJ do Fiscalizado">
            <Input value={cpfCnpjMask(data.cpfCnpjFiscalizado || "")} />
          </Form.Item>

          <Form.Item label="Nome do Fiscalizado">
            <Input value={data.nomeFiscalizado} />
          </Form.Item>

          <Form.Item label="RG do Fiscalizado">
            <Input value={data.inscricaoEstadualFiscalizado} />
          </Form.Item>

          <Form.Item label="RENASEM do Fiscalizado">
            <Input value={data.renasemFiscalizado} />
          </Form.Item>

          <Form.Item label="Endereço do Fiscalizado">
            <Input value={data.enderecoFiscalizado} />
          </Form.Item>

          <Form.Item label="Município do Fiscalizado">
            <Input
              value={`${data?.municipioFiscalizado?.nome}-${data?.municipioFiscalizado?.uf.sigla}`}
            />
          </Form.Item>

          <Form.Item label="Email do Fiscalizado">
            <Input value={data.emailFiscalizado} />
          </Form.Item>

          <Form.Item label="Telefone do Fiscalizado">
            <Input value={telefoneMask(data.telefoneFiscalizado || "")} />
          </Form.Item>

          <Form.Item label="CEP do Fiscalizado">
            <Input value={cepMask(data.cepFiscalizado || "")} />
          </Form.Item>

          <Form.Item label="Propriedade / Estabelecimento">
            <Input value={data.nomePropriedade} />
          </Form.Item>

          <Form.Item label={`Municipio da Fiscalização`}>
            <Input
              value={
                data.municipioFiscalizacao
                  ? `${data.municipioFiscalizacao.nome}-${data.municipioFiscalizacao.uf.sigla}`
                  : ""
              }
            />
          </Form.Item>

          {data.caracteristicaFiscalizado !== "TRANSITO" &&
            data.caracteristicaFiscalizado !== "PROFISSIONAL" && (
              <Form.Item label={`CEP  Propriedade`}>
                <Input value={data.cepPropriedade} />
              </Form.Item>
            )}

          {data.caracteristicaFiscalizado !== "TRANSITO" &&
            data.caracteristicaFiscalizado !== "PROFISSIONAL" && (
              <Form.Item label={`Endereço  Propriedade`}>
                <Input value={data.enderecoPropriedade} />
              </Form.Item>
            )}

          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "150px",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "150px",
                }}
              >
                Latitude:
              </Typography>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Form.Item
                label="Grau"
                labelAlign="left"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  maxLength={5}
                  value={coordMask(data.latGrauPropriedade?.toString() || "")}
                />
              </Form.Item>

              <Form.Item label="Minutos" style={{ maxWidth: "150px" }}>
                <Input
                  maxLength={5}
                  value={coordMask(data.latMinPropriedade?.toString() || "")}
                />
              </Form.Item>

              <Form.Item label="Segundos" style={{ maxWidth: "150px" }}>
                <Input
                  maxLength={5}
                  value={coordMask(data.latSegPropriedade?.toString() || "")}
                />
              </Form.Item>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "150px",
              }}
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "150px",
                }}
              >
                Longitude:
              </Typography>
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <Form.Item
                label="Grau"
                labelAlign="left"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  maxLength={5}
                  value={coordMask(data.longGrauPropriedade?.toString() || "")}
                />
              </Form.Item>

              <Form.Item
                label="Minutos"
                labelAlign="left"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  maxLength={5}
                  value={coordMask(data.longMinPropriedade?.toString() || "")}
                />
              </Form.Item>

              <Form.Item
                label="Segundos"
                labelAlign="left"
                style={{ maxWidth: "150px" }}
              >
                <Input
                  maxLength={5}
                  value={coordMask(data.longSegPropriedade?.toString() || "")}
                />
              </Form.Item>
            </div>
          </div>

          <Form.Item label="Data de Fiscalização: ">
            <DatePicker
              value={moment(data.dataFiscalizacao)}
              format={"DD/MM/YYYY"}
            />
          </Form.Item>

          <Divider />

          <Form.Item label="Anexos">
            <List
              bordered
              dataSource={anexos}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Sem anexos."
                  />
                ),
              }}
              renderItem={(item, ind) => (
                <List.Item key={ind}>
                  <button
                    className={styles["button-anexos"]}
                    onClick={() => {
                      try {
                        saveAs(item.arquivo, item.nomeArquivo);
                      } catch (error) {
                        console.error(error);
                        openNotification({
                          type: "error",
                          message: "Não é possivel baixar anexos no momento.",
                        });
                      }
                    }}
                  >
                    {item.nomeArquivo}
                  </button>
                </List.Item>
              )}
            />
          </Form.Item>

          <Typography.Title level={4}>Atividades</Typography.Title>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty
                  description={"Sem dados"}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
            }}
            dataSource={atividadesInGrid}
            columns={columnsAtividade}
            style={{
              overflowX: "auto",
            }}
          />

          <Form.Item label="Constatação" style={{ marginTop: 40 }}>
            <Input.TextArea rows={4} value={data.constatacao} />
          </Form.Item>

          <Form.Item label="Providências">
            <Input.TextArea rows={4} value={data.amparoLegal} />
          </Form.Item>
        </Form>

        <Typography.Title level={4}>Servidores</Typography.Title>
        <Col span={24}>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty
                  description={"Sem dados"}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
            }}
            dataSource={servidoresNaGrid}
            columns={columnsServidores}
            style={{
              overflowX: "auto",
              paddingBottom: "20px",
            }}
          />
        </Col>

        <div
          style={{
            display: "flex",
            padding: 15,
          }}
        >
          <Checkbox.Group
            style={{
              display: "flex",
              flexDirection: "row",
              width: 250,
            }}
            value={motivoNaoAssinatura}
          >
            <Checkbox value={ASSINATURA_FISICA}>Assinatura Física</Checkbox>
          </Checkbox.Group>
        </div>

        <AssinaturaContainer>
          {data.servidores.map((serv: any, ind: number) => {
            return (
              <div className="servidor-signature-form" key={ind}>
                <div>
                  <div className="container-img-assinatura">
                    {data.statusAssinatura !== ASSINATURA_FISICA && (
                      <img
                        className={styles["img-assinatura"]}
                        src={serv.assinatura}
                        alt="assinatura servidor"
                      />
                    )}
                  </div>
                  <Typography style={{ textAlign: "center" }}>
                    Assinatura {serv.servidor.nome}
                  </Typography>
                </div>
              </div>
            );
          })}

          <div className="fiscalizado-signature-form">
            <div>
              <div className="container-img-assinatura">
                {data.statusAssinatura === "ASSINADO_DIGITALMENTE" && (
                  <img
                    className={styles["img-assinatura"]}
                    src={data.assinatura}
                    alt="Assinatura fiscalizado"
                  />
                )}
              </div>

              <Typography style={{ textAlign: "center" }}>
                Assinatura Fiscalizado
              </Typography>
              <div
                style={{
                  paddingTop: 5,
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Checkbox.Group
                  value={motivoNaoAssinatura}
                  style={{
                    display: "flex",
                  }}
                >
                  <Checkbox value="AUSENTE">Ausente</Checkbox>
                  <br />
                  <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                </Checkbox.Group>
              </div>

              <div className={styles["container-dados-assinante"]}>
                <div>
                  <span>Nome:</span>
                  <Input value={data.nomeAssinante} />
                </div>

                <div>
                  <span>CPF:</span>
                  <Input value={cpfCnpjMask(data.cpfAssinante || "")} />
                </div>

                <div>
                  <span>Função:</span>
                  <Input value={data.funcaoAssinante} />
                </div>
              </div>
            </div>
          </div>
        </AssinaturaContainer>

        <Form form={perguntasForm}>
          <Modal
            width={1400}
            title={
              <h1 className={styles["titulo-modal"]}>
                Questionário de{" "}
                {atividadeSelected
                  ? atividadeSelected.nome[0] +
                    atividadeSelected.nome.slice(1).toLowerCase()
                  : ""}
              </h1>
            }
            maskClosable={false}
            keyboard={true}
            open={isOpenModalQuestionario}
            onCancel={handleCloseModal}
            footer={[
              <Button key="fechar" onClick={handleCloseModal}>
                Fechar
              </Button>,
            ]}
          >
            <div className={styles["container-perguntas"]}>
              {perguntas.map((perg, ind) => {
                const identacaoEspaco =
                  perg.indPergunta?.split(".").length || 0;

                return (
                  <div
                    key={ind}
                    style={{
                      padding: 5,
                      paddingLeft:
                        identacaoEspaco === 1 ? 0 : identacaoEspaco * 20,
                    }}
                  >
                    <h2 className={styles["titulo-pergunta"]}>
                      {perg.indPergunta}. {perg.pergunta.nome}
                    </h2>

                    {["ALTERNATIVA", "AMBOS"].includes(
                      perg.pergunta.tipoResposta
                    ) && (
                      <>
                        <Form.Item
                          label="Alternativa"
                          labelCol={{
                            span: 0,
                            offset: 0,
                            className: styles["label-alternativa"],
                          }}
                          style={{ marginBottom: 0 }}
                        >
                          <Radio.Group
                            value={perguntasForm.getFieldValue(
                              `resposta_alternativa_${perg.pergunta.id}`
                            )}
                          >
                            {perg.pergunta.alternativas.map((alt) => {
                              if (alt.ativo) {
                                return (
                                  <Radio value={alt.id} key={alt.id}>
                                    {alt.nome}
                                  </Radio>
                                );
                              }

                              return null;
                            })}
                          </Radio.Group>
                        </Form.Item>
                      </>
                    )}

                    {perg.pergunta.campos.map((campo, ind) => {
                      return (
                        <div
                          key={ind}
                          style={{
                            display: "flex",
                            gap: 5,
                            flexDirection: "column",
                          }}
                        >
                          <label>{campo}</label>
                          <Form.Item noStyle={false}>
                            <Input
                              style={{ maxWidth: 400 }}
                              value={perguntasForm.getFieldValue(
                                `resposta_campo_${ind}_${perg.pergunta.id}`
                              )}
                            />
                          </Form.Item>
                        </div>
                      );
                    })}

                    {["DESCRITIVA"].includes(perg.pergunta.tipoResposta) && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            gap: 5,
                            flexDirection: "column",
                          }}
                        >
                          <label>ESPECIFICAR:</label>
                          <Form.Item noStyle={true}>
                            <Input
                              value={perguntasForm.getFieldValue(
                                `resposta_descritiva_${perg.pergunta.id}`
                              )}
                              placeholder={perg.pergunta.orientacao}
                              style={{ maxWidth: 400 }}
                            />
                          </Form.Item>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </Modal>
        </Form>

        <ButtonFooterContainer>
          <Button onClick={handleBack}>Voltar</Button>
        </ButtonFooterContainer>
      </Fragment>
    );
  }
}
