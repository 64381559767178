import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { useDadosTermoApreensaoLiberacao } from "@hooks/useDadosTermoApreensaoLiberacao";
import { Button, Col, Empty, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ITabelaItens } from "../..";

export function TabelaItensTAL({ dataTable }: { dataTable: ITabelaItens[] }) {
  const [modal, contextHolder] = Modal.useModal();
  const { removerItem } = useDadosTermoApreensaoLiberacao();

  const columnItens: ColumnsType<ITabelaItens> = [
    {
      title: "Tipo de Produto",
      dataIndex: "tipoProduto",
      align: "center",
    },
    {
      title: "Produto/especie",
      dataIndex: "nomeProduto",
      align: "center",
    },
    {
      title: "Tipo Embalagem",
      dataIndex: "tipoEmbalagem",
      align: "center",
    },
    {
      title: "Fabricante",
      dataIndex: "fabricante",
      align: "center",
    },
    {
      title: "Renasem",
      dataIndex: "renasem",
      align: "center",
    },
    {
      title: "Cultivar",
      dataIndex: "cultivar",
      align: "center",
    },
    {
      title: "Lote",
      dataIndex: "lote",
      align: "center",
    },
    {
      title: "Validade",
      dataIndex: "validade",
      align: "center",
    },
    {
      title: "Volume",
      dataIndex: "volume",
      align: "center",
    },
    {
      title: "Quantidade",
      dataIndex: "quantidade",
      render: (quantidade: number) => (
        <span>{quantidade.toString().replace(".", ",")}</span>
      ),
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "total",
      align: "center",
    },
    {
      title: "Unidade Medida",
      dataIndex: "unidadeMedida",
      align: "center",
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
    },
    {
      title: "Ações",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            danger
            onClick={(e) => {
              modal.confirm({
                title: "Tem certeza que deseja remover Item?",
                icon: <ExclamationCircleOutlined />,
                okText: "Sim",
                cancelText: "Não",
                onOk: () => {
                  removerItem(record.key);
                  openNotification({
                    type: "success",
                    message: "Item removido com sucesso!",
                  });
                },
              });
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Col span={32}>
        <Table
          pagination={false}
          bordered
          columns={columnItens}
          dataSource={dataTable}
          locale={{
            emptyText: (
              <Empty
                description={"Sem dados"}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }}
          style={{
            overflowX: "auto",
            paddingBottom: "20px",
          }}
        />
      </Col>
    </>
  );
}
