import {
  CloseOutlined,
  FormOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { usePermission } from "@hooks/usePermission";
import {
  IContentListagemAmostra,
  IListagemAmostra,
} from "@interfaces/IListagemAmostra";
import { menuAction } from "@store/actions/menuAction";
import { Button, Empty, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface ITabelaLasgal extends IContentListagemAmostra {
  key: number;
}

const CODIGO_LASGAL = "LASGAL";
const PAGE_SIZE = 20;

export function TabelaLASGAL({
  sampleList,
  variables,
  amostraIdSelected,
  pageControl,
  handleRefuseSample,
  handleChangePagination,
}: {
  sampleList: IListagemAmostra;
  variables?: string;
  amostraIdSelected: number | undefined;
  pageControl: number;
  handleRefuseSample: (sampleId: number | undefined) => void;
  handleChangePagination: (page: number) => void;
}) {
  const navigate = useNavigate();
  const { isAllowed } = usePermission();
  const appDispatch = useDispatch();
  const { updateKeyAnaliseLasgal } = menuAction;

  //ESTRUTURA DE COLUNAS DA TABELA DE ATIVIDADES
  const columns: ColumnsType<ITabelaLasgal> = [
    {
      title: "Nº Termo de coleta",
      dataIndex: "termoColetaAmostra",
      render: (text, record) => (
        <span
          style={{
            color:
              record.id === amostraIdSelected
                ? "#ff4d4f"
                : "rgba(0, 0, 0, 0.85)",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Data coleta",
      dataIndex: "dataColeta",
      render: (text, record) => (
        <span
          style={{
            color:
              record.id === amostraIdSelected
                ? "#ff4d4f"
                : "rgba(0, 0, 0, 0.85)",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Especie",
      dataIndex: "especie",
      render: (text, record) => (
        <span
          style={{
            color:
              record.id === amostraIdSelected
                ? "#ff4d4f"
                : "rgba(0, 0, 0, 0.85)",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Lote",
      dataIndex: "lote",
      render: (text, record) => (
        <span
          style={{
            color:
              record.id === amostraIdSelected
                ? "#ff4d4f"
                : "rgba(0, 0, 0, 0.85)",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Data recebimento",
      dataIndex: "dataRecebimentoAmostraLasgal",
      render: (data, record) => (
        <span
          style={{
            color:
              record.id === amostraIdSelected
                ? "#ff4d4f"
                : "rgba(0, 0, 0, 0.85)",
          }}
        >
          {data ? moment(data).format("L") : ""}
        </span>
      ),
    },
    {
      title: "Nº amostra LASGAL",
      dataIndex: "numeroAmostraLasgal",
      render: (text, record) => (
        <span
          style={{
            color:
              record.id === amostraIdSelected
                ? "#ff4d4f"
                : "rgba(0, 0, 0, 0.85)",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Situação",
      dataIndex: "situacaoLasgal",
      render: (text, record) => (
        <span
          style={{
            color:
              record.id === amostraIdSelected
                ? "#ff4d4f"
                : "rgba(0, 0, 0, 0.85)",
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Ações",
      key: "action",
      align: "center",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          {record.situacaoLasgal === "RECEBIDA" && (
            <Tooltip title="Informar resultado da análise">
              <Button
                disabled={
                  record.recusadaLasgal || !isAllowed("editar", CODIGO_LASGAL)
                }
                onClick={() => {
                  localStorage.setItem("LASGAL:page", pageControl.toString());

                  if (
                    variables?.includes("municipioColeta") ||
                    variables?.includes("numeroAmostraLasgal") ||
                    variables?.includes("numeroTermoColeta")
                  ) {
                    localStorage.setItem("LASGAL:params", variables);
                  }

                  appDispatch(updateKeyAnaliseLasgal(["PUREZA"]));
                  navigate(`/LASGAL/teste/${record.key}`);
                }}
              >
                <FormOutlined />
              </Button>
            </Tooltip>
          )}

          {record.situacaoLasgal === "COLETADA" && (
            <Tooltip title="Cadastrar recebimento de amostra">
              <Button
                disabled={
                  record.recusadaLasgal ||
                  !isAllowed("adicionar", CODIGO_LASGAL)
                }
                onClick={() => handleChangeRegisterSample(record.key)}
              >
                <PlusOutlined
                  style={{
                    color: record.recusadaLasgal ? "#BFBFBF" : "#52c41a",
                  }}
                />
              </Button>
            </Tooltip>
          )}

          {record.situacaoLasgal === "ANALISADA" && (
            <Tooltip title="Imprimir Boletim">
              <Button
                disabled={record.recusadaLasgal}
                onClick={() => handleChangePrintBoletim(record.key)}
              >
                <PrinterOutlined />
              </Button>
            </Tooltip>
          )}

          <Tooltip title="Recusar amostra">
            <Button
              disabled={
                record.recusadaLasgal || !isAllowed("excluir", CODIGO_LASGAL)
              }
              danger
              onClick={() => handleRefuseSample(record.key)}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleChangeRegisterSample = (sampleId: number) => {
    localStorage.setItem("LASGAL:page", pageControl.toString());

    if (
      variables?.includes("municipioColeta") ||
      variables?.includes("numeroAmostraLasgal") ||
      variables?.includes("numeroTermoColeta")
    ) {
      localStorage.setItem("LASGAL:params", variables);
    }

    navigate(`/LASGAL/novo/${sampleId}`);
  };

  const handleChangePrintBoletim = (sampleId: number) =>
    navigate(`/LASGAL/impressao/${sampleId}`);

  return (
    <Table
      bordered
      locale={{
        emptyText: (
          <Empty
            description={"Sem dados"}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ),
      }}
      dataSource={sampleList.content.map((amostra) => {
        return {
          key: amostra.id,
          ...amostra,
        };
      })}
      columns={columns}
      pagination={{
        pageSize: PAGE_SIZE,
        total: sampleList.totalPages * PAGE_SIZE,
        onChange: handleChangePagination,
        current: pageControl,
        showSizeChanger: false,
      }}
      scroll={{ x: 1500 }}
      size="large"
    />
  );
}
