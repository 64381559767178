import { IServidorForm } from "@interfaces/IServidorForm";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IServidorState {
  items: IServidorForm[];
}

const initialState: IServidorState = {
  items: [],
};

const gridServidorSlice = createSlice({
  name: "GRID_SERVIDORES",
  initialState,
  reducers: {
    addGridServidor: (state, action) => {
      const servidor = action.payload;
      state.items.push(servidor);
    },
    removeServidorInGrid: (state, action) => {
      const matriculaServidor = action.payload;
      const itemsFilter = state.items.filter(
        (item) => item.servidor.matricula !== matriculaServidor
      );
      state.items = itemsFilter;
    },
    removeAllServidoresInGrid: (state) => {
      state.items = [];
    },
  },
});

export default gridServidorSlice.reducer;

export const getServidoresInGrid = (state: RootState) =>
  state.gridServidores.items;
