import { openNotification } from "@components/Notifications";
import { TIPO_IMAGENS } from "@constants/TIPO_IMAGENS";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { useMunicipio } from "@hooks/useMunicipio";
import { useServidores } from "@hooks/useServidores";
import { IAnexos } from "@interfaces/IAnexos";
import { IAssinante } from "@interfaces/IAssinante";
import { IFormValuesTID } from "@interfaces/IFormValuesTID";
import { ITID } from "@interfaces/ITid";
import { ITif } from "@interfaces/ITif";
import { Button, FormInstance, UploadFile } from "antd";
import moment from "moment";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useFunctionsTID } from "../../hooks/useFunctionsTID";
import { BodyImpressaoTID } from "../../listagemTID/ViewImpressaoTID/components/BodyImpressaoTID";
import { FooterImpressaoTID } from "../../listagemTID/ViewImpressaoTID/components/FooterImpressaoTID";
import { HeaderImpressaoTID } from "../../listagemTID/ViewImpressaoTID/components/HeaderImpressaoTID";
import { ImagesImpressaoTID } from "../../listagemTID/ViewImpressaoTID/components/ImagesImpressaoTID";

export function PreviaTID({
  form,
  tifSelected,
  fiscalizadoSignature,
  anexos,
}: {
  form: FormInstance<IFormValuesTID>;
  tifSelected: Partial<ITif> | null;
  fiscalizadoSignature: IAssinante;
  anexos: UploadFile[];
}) {
  const [printedTermo, setPrintedTermo] = useState<ITID | null>(null);

  const { getMunicipioPorId } = useMunicipio();
  const { servidoresSelecionados } = useServidores();
  const { buscarTermoInterdicao } = useFunctionsTID();

  const termoRef = useRef(null);

  const handleAfterPrint = useCallback(() => {
    setPrintedTermo(null);
    // tslint:disable-line no-console
  }, []);

  const handlePrint = useReactToPrint({
    contentRef: termoRef,
    documentTitle: `Termo de ${
      printedTermo?.tipoTermo === "INTERDICAO" ? "Interdição" : "Desinterdição"
    } ${printedTermo?.numero}`,
    suppressErrors: true,
    onAfterPrint: handleAfterPrint,
    onPrintError: (errorLocation, error) => {
      openNotification({
        type: "error",
        message: `Não foi possivel fazer impressão desse Termo de ${
          printedTermo?.tipoTermo === "INTERDICAO"
            ? "Interdição"
            : "Desinterdição"
        }`,
      });
    },
  });

  const handlePreview = async () => {
    const values = form.getFieldsValue();
    const termoInterdicaoSelected = await buscarTermoInterdicao(
      values.numTermoInterdicao
    );

    if (!tifSelected) {
      openNotification({
        type: "error",
        message: "Erro ao gerar Prévia do Termo.",
        description:
          "Termine de preencher os campos obrigatorios para gerar prévia do termo.",
      });

      return;
    }

    const atividadesSelected = tifSelected?.atividades
      ?.filter((atv) => values.atividadesId.includes(atv.id))
      .map((atv) => {
        return {
          areaAtividade: atv.areaAtividade,
          atividade: {
            id: atv.atividade.id,
            nome: atv.atividade.nome,
          },
          id: atv.id,
        };
      });

    //PREENCHER DADOS DO TERMO DE APREENSAO/LIBERACAO
    let formValue: ITID = {
      anexos: [],
      atividades: atividadesSelected || [],
      atividadesInterditadas: values.amparoLegal || "",
      assinaturaFiscalizado: "",
      cpfAssinanteFiscalizado: fiscalizadoSignature.cpfAssinante,
      cepFiscalizado:
        form.getFieldValue("cepFiscalizado")?.replace(/[^\d]+/g, "") || "",
      emailFiscalizado: form.getFieldValue("emailFiscalizado") || "",
      cpfCnpjFiscalizado:
        form.getFieldValue("cpfCnpjFiscalizado")?.replace(/[^\d]+/g, "") || "",
      nomeFiscalizado: form.getFieldValue("nomeFiscalizado") || "",
      inscricaoEstadualFiscalizado: form.getFieldValue("rgFiscalizado") || "",
      renasemFiscalizado: form.getFieldValue("renasemFiscalizado") || "",
      enderecoFiscalizado: form.getFieldValue("enderecoFiscalizado") || "",
      municipioFiscalizacao: getMunicipioPorId(
        form.getFieldValue("municipioFiscalizacaoId") || ""
      ),
      municipioFiscalizado: getMunicipioPorId(
        form.getFieldValue("municipioFiscalizadoId") || ""
      ),
      tipoRepresentante:
        form.getFieldValue("tipoRepresentante") !== "TRANSPORTADOR"
          ? form.getFieldValue("tipoRepresentante")
          : "",
      telefoneFiscalizado:
        form.getFieldValue("telefoneFiscalizado")?.replace(/[^\d]+/g, "") || "",
      dataFiscalizacao: moment(form.getFieldValue("dataTIF")).format(
        "DD/MM/YYYY"
      ),
      dataCadastro: moment().format(),
      funcaoAssinanteFiscalizado: fiscalizadoSignature.funcaoAssinante,
      nomeAssinanteFiscalizado: fiscalizadoSignature.nomeAssinante,
      numero: "",
      servidores: [],
      statusAssinaturaFiscalizado: "ASSINADO_FISICAMENTE",
      termoInterdicao: null,
      tif: tifSelected,
      tipoTermo: values.tipoTermo,
    };

    const servs = servidoresSelecionados.map((servidor) => {
      return {
        assinatura: "",
        servidor: {
          cpfCnpj: servidor.servidor.cpfCnpj,
          id: servidor.servidor.id,
          inscricaoEstadual: servidor.servidor.inscricaoEstadual,
          matricula: servidor.servidor.matricula,
          nome: servidor.servidor.nome,
        },
      };
    });

    if (termoInterdicaoSelected) {
      formValue = {
        ...formValue,
        termoInterdicao: {
          dataFiscalizacao: termoInterdicaoSelected.dataFiscalizacao,
          id: termoInterdicaoSelected.id || 0,
          municipioFiscalizacao: termoInterdicaoSelected.municipioFiscalizacao,
          nomeFiscalizado: termoInterdicaoSelected.nomeFiscalizado,
          numero: termoInterdicaoSelected.numero,
          tipoTermo: termoInterdicaoSelected.tipoTermo,
          statusAssinaturaFiscalizado:
            termoInterdicaoSelected.statusAssinaturaFiscalizado,
        },
      };
    }

    formValue = { ...formValue, servidores: servs };

    if (anexos.length > 0) {
      let anexosImage: IAnexos[] = [];

      await getAnexosFormatadosBase64(anexos).then((res) => {
        anexosImage = res;
      });

      formValue = {
        ...formValue,
        anexos: anexosImage.filter((anexo) =>
          TIPO_IMAGENS.includes(
            anexo.nomeArquivo.split(".")[
              anexo.nomeArquivo.split(".").length - 1
            ]
          )
        ),
      };
    }

    setPrintedTermo(formValue);
  };

  useEffect(() => {
    if (printedTermo) {
      handlePrint();
    }
    //eslint-disable-next-line
  }, [printedTermo]);

  return (
    <Fragment>
      <Button onClick={handlePreview}>Gerar prévia PDF</Button>

      {printedTermo && (
        <div style={{ display: "none" }}>
          {printedTermo && (
            <div ref={termoRef}>
              <div className="marcadagua">MINUTA</div>
              <table
                style={{ width: 780 }}
                className="termo-apreensao-liberacao-impressao-font"
              >
                <HeaderImpressaoTID isFirstPage tid={printedTermo} />
                <BodyImpressaoTID tid={printedTermo} />
                <FooterImpressaoTID tid={printedTermo} />
              </table>

              {printedTermo.anexos.map((anexo, ind) => {
                return (
                  <ImagesImpressaoTID
                    anexo={anexo}
                    termo={printedTermo}
                    key={ind}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
