import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { getPayloadUser } from "./functions/getPayloadUser";
import "./globalStyle.css";
import { Login } from "./pages/login";
import { Routers } from "./routes";
import { persistor, store } from "./store";
import "./styles/assinatura.css";
import "./styles/listagem.css";
import "./styles/print.css";

function App() {
  const auth = useAuth();
  const queryClient = new QueryClient();
  const user = getPayloadUser(localStorage.getItem("access_token"));

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (
        window.location.href.includes("novo") ||
        window.location.href.includes("edit")
      ) {
        const confirmationMessage = "Você realmente deseja sair?";
        event.preventDefault();
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };

    //eslint-disable-next-line
  }, [window.location.href]);

  if (user && !localStorage.getItem("error_autenticacao")) {
    return (
      <>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <Routers />
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </>
    );
  }

  return (
    <Login
      singIn={() => {
        if (localStorage.getItem("error_autenticacao")) {
          auth.signoutSilent();
          localStorage.removeItem("error_autenticacao");
          return;
        }

        if (user) {
          window.location.href = process.env.REACT_REDIRECT_URI + "/home";
          return;
        }

        auth.signinRedirect();
      }}
    />
  );
}
export default App;
