import { IAmostra } from "@interfaces/IAmostra";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IAmostraSlice {
  items: IAmostra[];
}

const initialState: IAmostraSlice = {
  items: [],
};

const amostraSlice = createSlice({
  name: "AMOSTRAS",
  initialState,
  reducers: {
    addAmostra: (state, action) => {
      const { amostra } = action.payload;
      state.items.push(amostra);
    },
    removeAmostra: (state, action) => {
      const { idAmostra } = action.payload;

      const itemsFilter = state.items.filter(
        (amostra) => amostra.identificacao !== idAmostra
      );
      state.items = itemsFilter;
    },
    removeAllAmostras: (state) => {
      state.items = [];
    },
  },
});

export default amostraSlice.reducer;

export const getAmostras = (state: RootState) => state.amostras.items;
