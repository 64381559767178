import { IMunicipio } from "@interfaces/IMunicipio";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface ICidadeState {
  items: IMunicipio[] | [];
  persistedAt: number;
}

const initialState: ICidadeState = {
  items: [],
  persistedAt: 0,
};

const downloadMunicipioSlice = createSlice({
  name: "downloadMunicipios",
  initialState,
  reducers: {
    addMunicipios: (state, action) => {
      const { municipios, dateTimeUpdate } = action.payload;
      try {
        if (!municipios) {
          state.items = [];
          return;
        }
        state.items = municipios;
        state.persistedAt = dateTimeUpdate;
      } catch (error) {
        console.log(error);
      }
    },
    removeMunicipio: (state, action) => {
      const idMunicipio = action.payload;
      const municipioFilter = state.items.filter(
        (municipio) => municipio.id !== idMunicipio
      );
      state.items = municipioFilter;
    },
  },
});

export const getDownloadMunicipios = (state: RootState) => {
  return state.downloadMunicipios.items;
};

export const getExpireDownloadMunicipio = (state: RootState) =>
  state.downloadMunicipios.persistedAt;

export default downloadMunicipioSlice.reducer;
