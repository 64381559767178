import { openNotification } from "@components/Notifications";
import { IArmadilha } from "@interfaces/IArmadilha";
import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { IRespostaAtividade } from "@interfaces/IRespostaAtividade";
import { ITipoAtividade } from "@interfaces/ITipoAtividade";
import { atividadeAction } from "@store/actions/atividadeAction";
import {
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
} from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useDispatch } from "react-redux";
import { ordenarPerguntasAtividade } from "../../../../functions/ordenarPerguntasAtividade";
import styles from "./style.module.css";

interface IModalPerguntaAtividadeProps {
  isOpen: boolean;
  atividadeSelected: ITipoAtividade | null;
  perguntas: IPerguntaAtividade[];
  formPerguntas: FormInstance;
  handleClose: () => void;
  setPerguntas: React.Dispatch<React.SetStateAction<IPerguntaAtividade[]>>;
  armadilhaSelected: IArmadilha | null;
}

export function ModalPerguntaAtividade({
  isOpen,
  formPerguntas,
  atividadeSelected,
  perguntas,
  handleClose,
  setPerguntas,
  armadilhaSelected,
}: IModalPerguntaAtividadeProps) {
  const appDispatch = useDispatch();
  const { addRespostasAtividade } = atividadeAction;

  const handleChangePerguntaDependente = (
    event: RadioChangeEvent,
    pergunta: IPerguntaAtividade
  ) => {
    const { value } = event.target;
    const perguntasDependentes = getPerguntasDependentes(
      pergunta.pergunta.id,
      value
    );

    const filterPerguntas = filtrarPerguntas(pergunta.pergunta.id, value);
    let auxPerguntas: IPerguntaAtividade[] = [];

    if (perguntasDependentes.length > 0) {
      auxPerguntas = filterPerguntas.concat(
        perguntasDependentes.map((perg, indice) => {
          if (perg.pergunta.tipoResposta === "ALTERNATIVA") {
            formPerguntas.setFieldValue(
              `resposta_alternativa_${perg.pergunta.id}`,
              ""
            );
          }

          if (perg.pergunta.tipoResposta === "DESCRITIVA") {
            formPerguntas.setFieldValue(
              `resposta_descritiva_${perg.pergunta.id}`,
              ""
            );
          }

          return {
            ...perg,
            indPergunta: `${pergunta.indPergunta}.${indice + 1}`,
          };
        })
      );

      auxPerguntas = ordenarPerguntasAtividade(auxPerguntas);
    } else {
      auxPerguntas = filterPerguntas;
    }

    setPerguntas(verificarPerguntasListadas(auxPerguntas));
  };

  const getPerguntasDependentes = (perguntaId: number, respostaId: number) => {
    if (!atividadeSelected) return [];

    let perguntasFilhas = atividadeSelected.questoes.filter(
      (perg) =>
        perg.pergunta.antecessora?.id === perguntaId &&
        perg.pergunta.respostaCondicional?.id === respostaId
    );

    //verificando se pergunta ja foi mostrada
    perguntasFilhas = perguntasFilhas.filter((perg) => {
      return perguntas.findIndex((aux) => aux.id === perg.pergunta.id) === -1;
    });

    return perguntasFilhas;
  };

  const filtrarPerguntas = (perguntaId: number, respostaId: number) => {
    const auxPerguntas = perguntas.filter((perg) => {
      if (
        perg.pergunta.antecessora?.id === perguntaId &&
        perg.pergunta.respostaCondicional.id !== respostaId
      ) {
        formPerguntas.setFieldValue(`resposta_alternativa_${perg.id}`, "");

        return null;
      }

      return perg;
    });

    return auxPerguntas;
  };

  const verificarPerguntasListadas = (arrayPerguntas: IPerguntaAtividade[]) => {
    const auxperguntas: IPerguntaAtividade[] = [];

    arrayPerguntas.forEach((perg, ind, todasPerguntas) => {
      if (perg.pergunta.antecessora) {
        if (
          todasPerguntas.some(
            (aux) => aux.pergunta.id === perg.pergunta.antecessora.id
          )
        ) {
          auxperguntas.push(perg);
        } else {
          formPerguntas.setFieldValue(
            `resposta_alternativa_${perg.pergunta.id}`,
            ""
          );
        }
      } else {
        auxperguntas.push(perg);
      }
    });

    return auxperguntas;
  };

  const handleSubmit = (values: any) => {
    if (!atividadeSelected) return;

    const resp: IRespostaAtividade[] = [];

    for (let i = 0; i < perguntas.length; i++) {
      delete perguntas[i].indPergunta;

      if (perguntas[i].pergunta.tipoResposta === "ALTERNATIVA") {
        resp.push({
          armadilha: armadilhaSelected,
          atividade: {
            nome: atividadeSelected?.nome,
            id: atividadeSelected?.id,
          },
          pergunta: {
            id: perguntas[i].pergunta.id,
            nome: perguntas[i].pergunta.nome,
            tipoResposta: perguntas[i].pergunta.tipoResposta,
            visivel: perguntas[i].pergunta.visivel,
          },
          descricao: "",
          alternativa: perguntas[i].pergunta.alternativas.filter(
            (alt) =>
              alt.id ===
              values[`resposta_alternativa_${perguntas[i].pergunta.id}`]
          )[0],
          campos: perguntas[i].pergunta.campos.map((campo, ind) => {
            return {
              campo: campo,
              resposta:
                values[`resposta_campo_${ind}_${perguntas[i].pergunta.id}`],
            };
          }),
          // order: perguntas[i].order,
        });
      } else if (perguntas[i].pergunta.tipoResposta === "DESCRITIVA") {
        resp.push({
          armadilha: armadilhaSelected,
          atividade: {
            nome: atividadeSelected?.nome,
            id: atividadeSelected?.id,
          },
          pergunta: {
            id: perguntas[i].pergunta.id,
            nome: perguntas[i].pergunta.nome,
            tipoResposta: perguntas[i].pergunta.tipoResposta,
            visivel: perguntas[i].pergunta.visivel,
          },
          descricao: values[`resposta_descritiva_${perguntas[i].pergunta.id}`],
          alternativa: null,
          campos: perguntas[i].pergunta.campos.map((campo, ind) => {
            return {
              campo: campo,
              resposta:
                values[`resposta_campo_${ind}_${perguntas[i].pergunta.id}`],
            };
          }),
          // order: perguntas[i].order,
        });
      } else {
        resp.push({
          armadilha: armadilhaSelected,
          atividade: {
            nome: atividadeSelected?.nome,
            id: atividadeSelected?.id,
          },
          pergunta: {
            id: perguntas[i].pergunta.id,
            nome: perguntas[i].pergunta.nome,
            tipoResposta: perguntas[i].pergunta.tipoResposta,
            visivel: perguntas[i].pergunta.visivel,
          },
          descricao: "",
          alternativa: perguntas[i].pergunta.alternativas.filter(
            (alt) =>
              alt.id ===
              values[`resposta_alternativa_${perguntas[i].pergunta.id}`]
          )[0],
          campos: perguntas[i].pergunta.campos.map((campo, ind) => {
            return {
              campo: campo,
              resposta:
                values[`resposta_campo_${ind}_${perguntas[i].pergunta.id}`],
            };
          }),
          // order: perguntas[i].order,
        });
      }
    }

    // console.log(resp);
    appDispatch(addRespostasAtividade(resp));

    openNotification({
      type: "success",
      message: "Respostas do questionário salvas com sucesso!",
    });

    handleClose();
  };

  const errorSubmit = (errorInfo: ValidateErrorEntity<any>) => {
    openNotification({
      type: "error",
      message: "Não foi possivel salvar respostas",
      description:
        "Termine de responder todas as perguntas pendentes no questionário.",
    });
  };

  return (
    <Form
      form={formPerguntas}
      id="perguntas-form"
      onFinish={handleSubmit}
      onFinishFailed={errorSubmit}
    >
      <Modal
        title={
          <h1 className={styles["titulo-modal"]}>
            QUESTIONÁRIO DE {atividadeSelected ? atividadeSelected.nome : ""}
          </h1>
        }
        width={1400}
        maskClosable={false}
        keyboard={true}
        open={isOpen}
        onCancel={handleClose}
        footer={[
          <Button key="cancelar" onClick={handleClose}>
            Cancelar
          </Button>,
          <Button
            key="salvar"
            type="primary"
            htmlType="submit"
            form="perguntas-form"
          >
            Salvar Respostas
          </Button>,
        ]}
      >
        <div className={styles["container-perguntas"]}>
          {perguntas.map((perg, ind) => {
            const identacaoEspaco = perg.indPergunta?.split(".").length || 0;

            return (
              <div
                key={ind}
                style={{
                  padding: 5,
                  paddingLeft: identacaoEspaco === 1 ? 0 : identacaoEspaco * 20,
                }}
              >
                <h2 className={styles["titulo-pergunta"]}>
                  {perg.indPergunta}. {perg.pergunta.nome}
                </h2>
                {["ALTERNATIVA", "AMBOS"].includes(
                  perg.pergunta.tipoResposta
                ) && (
                  <>
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{ marginBottom: 0 }}
                      label="Alternativa"
                      name={`resposta_alternativa_${perg.pergunta.id}`}
                      labelCol={{
                        className: styles["label-alternativa"],
                        span: 0,
                        offset: 0,
                      }}
                    >
                      <Radio.Group
                        onChange={(e) =>
                          handleChangePerguntaDependente(e, perg)
                        }
                      >
                        {perg.pergunta.alternativas.map((alt) => {
                          if (alt.ativo) {
                            return (
                              <Radio value={alt.id} key={alt.id}>
                                {alt.nome}
                              </Radio>
                            );
                          }

                          return null;
                        })}
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}

                {perg.pergunta.campos.map((campo, ind) => {
                  return (
                    <div
                      key={ind}
                      style={{
                        display: "flex",
                        gap: 5,
                        flexDirection: "column",
                      }}
                    >
                      <label className={styles["label-campo"]}>{campo}</label>
                      <Form.Item
                        name={`resposta_campo_${ind}_${perg.pergunta.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Pro favor, insira uma resposta",
                          },
                        ]}
                        noStyle={false}
                      >
                        <Input style={{ maxWidth: 400 }} />
                      </Form.Item>
                    </div>
                  );
                })}

                {["DESCRITIVA"].includes(perg.pergunta.tipoResposta) && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: 5,
                        flexDirection: "column",
                      }}
                    >
                      <label className={styles["label-campo"]}>
                        ESPECIFICAR:
                      </label>
                      <Form.Item
                        name={`resposta_descritiva_${perg.pergunta.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Pro favor, insira uma resposta",
                          },
                        ]}
                        noStyle={false}
                      >
                        <Input
                          placeholder={perg.pergunta.orientacao}
                          style={{ maxWidth: 400 }}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </Modal>
    </Form>
  );
}
