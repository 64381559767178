import { IAtividadeInterdicao } from "@interfaces/IAtividadeInterdicao";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IAtividadeInterditadaSlice {
  items: IAtividadeInterdicao[];
  persistedAt: number;
}

const initialState: IAtividadeInterditadaSlice = {
  items: [],
  persistedAt: 0,
};

const atividadeInterditadaSlice = createSlice({
  name: "ATIVIDADE_INTERDITADA",
  initialState,
  reducers: {
    addAtividades: (
      state,
      action: PayloadAction<{
        atividades: IAtividadeInterdicao[];
      }>
    ) => {
      const { atividades } = action.payload;
      state.items = atividades;
    },
    removeAtividades: (state) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default atividadeInterditadaSlice.reducer;

export const getAtividadesInterditadas = (state: RootState) =>
  state.atividadesInterditadas.items;
