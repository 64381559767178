//estilizacao
import {
  ExclamationCircleOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { menuAction } from "@store/actions/menuAction";
import { userControllerAction } from "@store/actions/userControllerAction";
import { Button, Dropdown, Layout } from "antd";
import confirm from "antd/lib/modal/confirm";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { MenuSystem } from "../MenuSystem";
import { NotificacaoGeral } from "./components/NotificacaoGeral";
import styles from "./style.module.css";

interface IHeaderFormProps {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

export function HeaderForm({ collapsed, setCollapsed }: IHeaderFormProps) {
  const appDispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useAuth();
  const { removeUserController } = userControllerAction;
  const { updateKey } = menuAction;

  const handleLogout = () => {
    confirm({
      title: `Tem certeza que deseja Sair do SISDEV-Mobile? essa ação impedirá o uso do modo offline.`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        appDispatch(updateKey(["5"]));
        localStorage.removeItem("access_token");
        appDispatch(removeUserController());
        auth.removeUser();
        auth.signoutSilent();
        navigate("/");
      },
    });
  };

  return (
    <Layout.Header className={styles["header-form"]}>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: `${styles["menu-pc"]} ${styles.trigger}`,
        onClick: () => setCollapsed(!collapsed),
      })}

      <Dropdown
        overlay={<MenuSystem />}
        placement="bottom"
        trigger={["click"]}
        className={styles["menu-mobile"]}
        destroyPopupOnHide
      >
        <Button>
          <MenuOutlined />
        </Button>
      </Dropdown>

      <div className={styles["container-notification-button-leave"]}>
        <NotificacaoGeral />

        <Button type="primary" danger onClick={handleLogout}>
          Sair
        </Button>
      </div>
    </Layout.Header>
  );
}
