import { IOficialAmostrasPDF } from "@interfaces/IOficialAmosrasPDF";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { Fragment } from "react";
import { ContainerAmostraPDF } from "../../../styles/ContainerAmostraPDF";

export function TabelaAmostrasOficialImpressao({
  listaAmostras,
  termo,
}: {
  listaAmostras: IOficialAmostrasPDF[];
  termo: ITermoColetaAmostras;
}) {
  return (
    <>
      <tbody>
        <tr>
          <td>
            <div
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontSize: 21,
                  fontWeight: "bold",
                  paddingBottom: 10,
                  borderBottom: "1px solid black",
                }}
              >
                <span> Tabela de Amostras</span>
              </div>
              <div
                style={{
                  borderBottom: "1px solid black",
                }}
              >
                {/**TITULO */}
                <div
                  className="oficial-impressao-termo-background"
                  style={{ display: "flex" }}
                >
                  <div
                    style={{
                      width: "50%",
                      borderRight: "1px solid black",
                      borderBottom: "1px solid black",
                      paddingLeft: 5,
                    }}
                  >
                    Termo de Coleta de Amostra N.º :
                  </div>
                  <div
                    style={{
                      width: "50%",
                      paddingLeft: 5,
                      borderBottom: "1px solid black",
                    }}
                  >
                    Termo de Fiscalização N.º :
                  </div>
                </div>
                {/**CONTEUDO */}
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "50%",
                      borderRight: "1px solid black",
                      paddingLeft: 5,
                    }}
                  >
                    {termo?.termoColetaAmostra}
                  </div>
                  <div
                    style={{
                      width: "50%",
                      paddingLeft: 5,
                    }}
                  >
                    {termo?.termoFiscalizacao}
                  </div>
                </div>
              </div>
              {/**TABELA */}
              <div
                className="oficial-impressao-termo-background"
                style={{
                  display: "flex",
                  textAlign: "center",
                  borderBottom: "1px solid black",
                  height: 80,
                }}
              >
                <ContainerAmostraPDF width="5%" hasBorder>
                  Amostra N°:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Cultivar:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Categoria:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Lote N°:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="15%" hasBorder>
                  <div
                    style={{ borderBottom: "1px solid black", width: "100%" }}
                  >
                    <span>Representatividade do Lote</span>
                  </div>

                  <div style={{ display: "flex", height: 58 }}>
                    <ContainerAmostraPDF width="50%" hasBorder>
                      Nº de Embalagens
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="50%">
                      Peso liquido
                    </ContainerAmostraPDF>
                  </div>
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Valor unitário:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Validade do teste de germinação:
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="30%">
                  <div
                    style={{ borderBottom: "1px solid black", width: "100%" }}
                  >
                    <span>Garantias expressas na embalagem</span>
                  </div>

                  <div style={{ display: "flex", height: 58 }}>
                    <ContainerAmostraPDF width="20%" hasBorder>
                      Pureza (%)
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="20%" hasBorder>
                      Germinação (%)
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="20%" hasBorder>
                      Tetrazólio (%)
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="20%" hasBorder>
                      Sementes Duras (%)
                    </ContainerAmostraPDF>

                    <ContainerAmostraPDF width="20%">
                      Outros fatores
                    </ContainerAmostraPDF>
                  </div>
                </ContainerAmostraPDF>
              </div>
              <div style={{ minHeight: 125 }}>
                {listaAmostras?.map((amostra, ind, todasAmostras) => {
                  return (
                    <Fragment key={ind}>
                      <div
                        style={{
                          display: "flex",
                          textAlign: "center",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <ContainerAmostraPDF width="5%" hasBorder>
                          {11 + ind}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.cultivar}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.categoria}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.lote}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="7.5%" hasBorder>
                          {amostra.qtdEmbalagens?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="7.5%" hasBorder>
                          {amostra.pesoEmbalagens?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.valorEmbalagens
                            ?.toString()
                            .replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.validadeTesteGerminacao}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%" hasBorder>
                          {amostra.pureza?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%" hasBorder>
                          {amostra.germinacao?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%" hasBorder>
                          {amostra.tetrazolio}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%" hasBorder>
                          {amostra.sementesDuras?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="6%">
                          {amostra.outrosFatores?.toString().replace(".", ",")}
                        </ContainerAmostraPDF>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}
