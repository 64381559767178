import { ITabelaTesteLasgal } from "@interfaces/ITabelaTesteLasgal";
import { Button, FormInstance } from "antd";
import { VALOR_PADRAO } from "../../constants";

export function ButtonConfirmaPureza({
  hasPureza,
  formPureza,
  dataTable,
  setDataTable,
  setHasPureza,
  resetarTabelaPureza,
}: {
  hasPureza: boolean;
  formPureza: FormInstance;
  dataTable: ITabelaTesteLasgal[];
  setDataTable: React.Dispatch<React.SetStateAction<ITabelaTesteLasgal[]>>;
  setHasPureza: React.Dispatch<React.SetStateAction<boolean>>;
  resetarTabelaPureza: () => void;
}) {
  const onChangeStatusPureza = () => {
    const auxDataTable = [
      dataTable[0],
      {
        ...dataTable[1],
        pureza: hasPureza ? VALOR_PADRAO : "",
        materialInerte: hasPureza ? VALOR_PADRAO : "",
        outraSementes: hasPureza ? VALOR_PADRAO : "",
      },
    ];

    if (hasPureza) {
      formPureza.resetFields();
    }
    setDataTable(auxDataTable);
    resetarTabelaPureza();
    setHasPureza(!hasPureza);
  };

  return (
    <Button
      type={hasPureza ? "primary" : "default"}
      style={{ width: 250 }}
      onClick={onChangeStatusPureza}
    >
      {hasPureza ? "Não fez Pureza" : "Fez Pureza"}
    </Button>
  );
}
