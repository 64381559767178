import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

export const getStatusAssinatura = (motivo: CheckboxValueType[]) => {
  if (motivo.length === 0) return "ASSINADO_DIGITALMENTE";

  if (motivo[0].toString() === "AUSENTE") return "AUSENTE";

  if (motivo[0].toString() === "RECUSADO") return "RECUSADO";

  if (motivo[0] === ASSINATURA_FISICA) return ASSINATURA_FISICA;

  return "ASSINADO_DIGITALMENTE";
};
