import {
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
  MenuOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";
import { openNotification } from "@components/Notifications";
import { usePermission } from "@hooks/usePermission";
import { IContentListagemTAL } from "@interfaces/IListagemTermoApreensaoLiberacao";
import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import { apiService } from "@services/api";
import { Button, Dropdown, List, Menu, message, Tooltip } from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useActionsTAL } from "../../../hooks/useActionsTAL";
import { useTAL } from "../../../hooks/useTAL";
import styles from "../../style.module.css";

const CODIGO_TERMO = "TermoApreensaoLiberacao";

export function ItemListaFiltroTermoApreensaoLiberacao({
  item,
  pageControl,
}: {
  item: IContentListagemTAL;
  pageControl: number;
}) {
  const tipoTermoFormatado =
    item.tipoTermo === "APREENSAO" ? "Apreensão" : "Liberação";
  const navigate = useNavigate();
  const { isAllowed } = usePermission();
  const { getAnexo } = useTAL();
  const {
    adicionarTermoByFiltro,
    listaFiltroTermos,
    listaTermos,
    aplicarFiltro,
    removerTermo,
  } = useActionsTAL();
  const [termoSelected, setTermoSelected] =
    useState<ITermoApreensaoLiberacao | null>(null);
  const [isDownload, setIsDownload] = useState(false);

  // VISUALIZAR TERMO
  const handleViewTermo = () => {
    if (!item.id) return;
    navigate(`/termo-apreensao-liberacao/listagem/visualizar/${item.id}`);
    localStorage.setItem(
      "termoApreensaoLiberacao:pageSelect",
      pageControl.toString()
    );
  };

  //CANCELAR TERMO
  const handleCancelTermo = async () => {
    if (!item.id) return;

    await apiService.termoApreensaoLiberacao.byId(item.id).then((data) => {
      setTermoSelected(data);
    });
  };

  //BAIXAR TERMO
  const handleDownloadTermo = async (termoId: number) => {
    const termo = listaTermos.find((auxTermo) => auxTermo.id === termoId);
    const keyMessage = "download-tal";

    if (termo) {
      openNotification({
        type: "info",
        message: `Não foi possivel baixar Termo de ${tipoTermoFormatado}`,
        description: "Termo já adicionado no SISDEV-Moblie",
      });
      return;
    }

    message.loading({
      key: keyMessage,
      duration: 0,
      content: `Baixando Termo de ${tipoTermoFormatado}...`,
    });
    setIsDownload(true);

    try {
      const responseTermoApreensaoLiberacao =
        await apiService.termoApreensaoLiberacao.byId(termoId);

      const dadosAnexos = responseTermoApreensaoLiberacao.anexos.map(
        (anexo) => {
          return {
            nomeUpload: anexo.nomeUpload,
            nomeArquivo: anexo.nomeArquivo,
          };
        }
      );

      if (dadosAnexos.length <= 0) {
        adicionarTermoByFiltro({
          ...responseTermoApreensaoLiberacao,
          anexos: [],
        });
      } else {
        const anexos = await getAnexo(dadosAnexos);
        // console.log(anexos);

        adicionarTermoByFiltro({
          ...responseTermoApreensaoLiberacao,
          anexos: anexos,
        });
      }

      openNotification({
        type: "success",
        message: `Termo de ${tipoTermoFormatado} ${responseTermoApreensaoLiberacao.numero} foi baixado com sucesso!`,
      });

      setIsDownload(false);
      message.destroy(keyMessage);
    } catch (error: any) {
      console.log(error);

      openNotification({
        type: "error",
        message: `Não foi possivel baixar Termo de ${tipoTermoFormatado}`,
        description: error.response.data.detail,
      });
      setIsDownload(false);
      message.destroy(keyMessage);
    }
  };

  const handlePrintedTermo = async () => {
    navigate(`/termo-apreensao-liberacao/listagem/impressao/${item.id}`);
    localStorage.setItem(
      "termoApreensaoLiberacao:pageSelect",
      pageControl.toString()
    );
    return;
  };

  const recarregarFiltro = async () => {
    const params = new URLSearchParams(listaFiltroTermos[0].params);

    params.append("page", (pageControl - 1).toString());

    await apiService.termoApreensaoLiberacao
      .listar(params.toString())
      .then((data) => {
        if (data.content.length > 0) {
          aplicarFiltro([
            {
              filtros: listaFiltroTermos[0].filtros,
              params: listaFiltroTermos[0].params.toString(),
              termos: data.content,
              numberPages: data.totalPages,
            },
          ]);
        }
      })
      .catch((erro) => {
        console.log(erro);
        openNotification({
          type: "error",
          message: "Não foi possivel atualizar o filtro",
        });
      });

    localStorage.setItem(
      "termoApreensaoLiberacao:pageSelect",
      pageControl.toString()
    );
  };

  return (
    <>
      <List.Item
        className="lista-item"
        style={{
          border: termoSelected ? "1px solid#ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{
                color: termoSelected ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              Termo de {tipoTermoFormatado} {item.numero}{" "}
              {item.ativo ? "" : "(Cancelado)"}
            </span>
          }
          description={
            <span className="subtitulo-card">
              Data Fiscalização:
              {moment(item.dataFiscalizacao || "").format("DD/MM/YYYY")} ID:
              {item.id ? item.id : ""}
            </span>
          }
        />

        <div className={styles["pc-menu-lista-termos"]}>
          <Tooltip title="Baixar para uso offline" destroyTooltipOnHide>
            <Button
              disabled={
                !item.ativo ||
                !navigator.onLine ||
                !item.statusAssinaturaFiscalizado ||
                isDownload
              }
              onClick={() => handleDownloadTermo(item.id)}
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Imprimir" destroyTooltipOnHide>
            <Button
              disabled={
                !navigator.onLine ||
                !item.statusAssinaturaFiscalizado ||
                isDownload
              }
              onClick={(e) => handlePrintedTermo()}
            >
              <PrinterOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Visualizar">
            <Button
              disabled={
                !item.ativo ||
                !navigator.onLine ||
                !isAllowed("visualizar", CODIGO_TERMO) ||
                !item.statusAssinaturaFiscalizado ||
                isDownload
              }
              onClick={() => handleViewTermo()}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>

          <Tooltip title="Cancelar" destroyTooltipOnHide>
            <Button
              disabled={
                !item.ativo ||
                !navigator.onLine ||
                !isAllowed("excluir", CODIGO_TERMO) ||
                !item.statusAssinaturaFiscalizado ||
                isDownload
              }
              danger
              onClick={() => handleCancelTermo()}
            >
              <CloseOutlined />
            </Button>
          </Tooltip>
        </div>

        <div className={styles["mobile-menu-lista-termos"]}>
          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={
              <Menu
                items={[
                  {
                    key: 0,
                    disabled:
                      !navigator.onLine ||
                      !item.statusAssinaturaFiscalizado ||
                      isDownload,
                    label: "Baixar",
                    icon: <DownloadOutlined />,
                    onClick: (e) => handleDownloadTermo(item.id),
                  },
                  {
                    key: 1,
                    label: "Imprimir",
                    disabled:
                      !navigator.onLine ||
                      !item.statusAssinaturaFiscalizado ||
                      isDownload,
                    icon: <PrinterOutlined />,
                    onClick: (e) => handlePrintedTermo(),
                  },
                  {
                    key: 2,
                    label: "Visualizar",
                    disabled:
                      !navigator.onLine ||
                      !isAllowed("visualizar", CODIGO_TERMO) ||
                      !item.statusAssinaturaFiscalizado ||
                      isDownload,
                    icon: <EyeOutlined />,
                    onClick: (e) => handleViewTermo(),
                  },
                  {
                    key: 3,
                    label: "Cancelar",
                    disabled:
                      !navigator.onLine ||
                      !isAllowed("excluir", CODIGO_TERMO) ||
                      !item.statusAssinaturaFiscalizado ||
                      isDownload,
                    icon: <CloseOutlined />,
                    onClick: () => handleCancelTermo(),
                  },
                ]}
              />
            }
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      {termoSelected && (
        <ModalCancelarTermo
          isOpen={!!termoSelected}
          setIsOpen={() => setTermoSelected(null)}
          numTermo={termoSelected?.numero}
          apiName="termoApreensaoLiberacao"
          idTermo={termoSelected.id}
          onRemoveTermo={removerTermo}
          titleTermo={`Termo de ${tipoTermoFormatado}`}
          reloadFilter={recarregarFiltro}
          isFilterApplied
        />
      )}
    </>
  );
}
