import { IEmbalagem } from "@interfaces/IEmbalagem";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IEmbalagemSlice {
  items: IEmbalagem[];
  persistedAt: number;
}

const initialState: IEmbalagemSlice = {
  items: [],
  persistedAt: 0,
};

const embalagemSlice = createSlice({
  name: "EMBALAGEM",
  initialState,
  reducers: {
    addEmbalagens: (state, action) => {
      const { embalagens, createdAt } = action.payload;

      state.items = embalagens;
      state.persistedAt = createdAt;
    },
    removeEmbalagens: (state, action) => {
      state.items = [];
      state.persistedAt = 0;
    },
  },
});

export default embalagemSlice.reducer;

export const getEmbalagens = (state: RootState) => state.embalagens.items;
