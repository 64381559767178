import { IFitoAmostrasPDF } from "@interfaces/IFitoAmostrasPDF";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import moment from "moment";
import { Fragment } from "react";
import { ContainerAmostraPDF } from "../../../styles/ContainerAmostraPDF";
import styles from "./style.module.css";

export function BodyFitoImpressao({
  listaAmostras,
  termo,
}: {
  listaAmostras: IFitoAmostrasPDF[];
  termo: ITermoColetaAmostras;
}) {
  return (
    <>
      <tbody>
        <tr>
          <td>
            <div
              style={{
                borderRight: "1px solid black",
                borderLeft: "1px solid black",
              }}
            >
              {/**TITULO */}
              <div className={styles.background} style={{ display: "flex" }}>
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  Ficha de Coleta de Amostras Nº (Nº/ULE/Ano)
                </div>

                <div
                  style={{
                    width: "50%",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  Número do Termo de Inspeção/Fiscalização/Notificação:
                </div>
              </div>
              {/**CONTEUDO */}
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  {termo?.termoColetaAmostra}
                </div>

                <div
                  style={{
                    width: "50%",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  {termo?.termoFiscalizacao}
                </div>
              </div>
              {/**TITULO */}
              <div className={styles.background} style={{ display: "flex" }}>
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  Produtor:
                </div>

                <div
                  style={{
                    width: "50%",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  Propriedade:
                </div>
              </div>
              {/**CONTEUDO */}
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "50%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  {termo?.produtor?.nome}
                </div>

                <div
                  style={{
                    width: "50%",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  {termo?.propriedade?.nome}
                </div>
              </div>
              {/**TITULO */}

              <div className={styles.background} style={{ display: "flex" }}>
                <div
                  style={{
                    width: "40%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  Município:
                </div>
                <div
                  style={{
                    width: "10%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  U.F.:
                </div>
                <div
                  style={{
                    width: "20%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  Cultura:
                </div>
                <div
                  style={{
                    width: "15%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  Ano/Safra
                </div>
                <div
                  style={{
                    width: "15%",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  Data da Coleta:
                </div>
              </div>
              {/**CONTEUDO */}
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    width: "40%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  {termo?.municipioColeta?.nome}
                </div>
                <div
                  style={{
                    width: "10%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  {termo?.municipioColeta?.uf.sigla}
                </div>
                <div
                  style={{
                    width: "20%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  {termo.especie?.nome}
                </div>
                <div
                  style={{
                    width: "15%",
                    borderRight: "1px solid black",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  {termo?.safra}
                </div>
                <div
                  style={{
                    width: "15%",
                    paddingLeft: 5,
                    borderBottom: "1px solid black",
                  }}
                >
                  {termo.dataColeta?.includes("-")
                    ? moment(termo.dataColeta).format("DD/MM/YYYY")
                    : termo.dataColeta}
                </div>
              </div>

              {/**TITULO DOS ELEMENTOS DA TABELA DE AMOSTRAS */}
              <div
                className={styles.background}
                style={{
                  display: "flex",
                  textAlign: "center",
                  height: 50,
                  borderBottom: "1px solid black",
                }}
              >
                <ContainerAmostraPDF width="10%" hasBorder>
                  Amostra nº
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="15%" hasBorder>
                  Latitude
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="15%" hasBorder>
                  Longitude
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Data de Plantio
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Cultivar
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Idade/Estádio Fenológico
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Quantidade coletada
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%" hasBorder>
                  Material Coletado
                </ContainerAmostraPDF>

                <ContainerAmostraPDF width="10%">Praga(s)</ContainerAmostraPDF>
              </div>

              {/**ELEMENTOS DA TABELA */}
              <div style={{ minHeight: 100, borderBottom: "1px solid black" }}>
                {listaAmostras?.map((amostra, ind) => {
                  const pragas = termo.especie?.pragasLsv
                    ?.filter(
                      (praga) => praga.parte.nome === amostra.partePlanta
                    )
                    .map((praga) => {
                      return praga.praga.nomeComum;
                    })
                    .join(", ");

                  return (
                    <Fragment key={ind}>
                      <div
                        style={{
                          display: "flex",
                          borderBottom: "1px solid black",
                        }}
                      >
                        <ContainerAmostraPDF width="10%" hasBorder>
                          {ind + 1}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="15%" hasBorder>
                          {amostra.latitude}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="15%" hasBorder>
                          {amostra.longitude}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.dataPlantio?.includes("-")
                            ? moment(amostra.dataPlantio).format("DD/MM/YYYY")
                            : amostra.dataPlantio}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.cultivar}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.estadio}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.qtdColetada}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%" hasBorder>
                          {amostra.partePlanta}
                        </ContainerAmostraPDF>

                        <ContainerAmostraPDF width="10%">
                          {pragas}
                        </ContainerAmostraPDF>
                      </div>
                    </Fragment>
                  );
                })}
              </div>

              <div
                className={styles.background}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "1px solid black",
                }}
              >
                <span>
                  Dados de Aplicações de Agrotóxicos (produtos e número de
                  aplicações)
                </span>
              </div>

              <div
                style={{
                  padding: 5,
                  borderBottom: "1px solid black",
                  textAlign: "justify",
                  minHeight: "15vh",
                }}
              >
                {termo?.dadosTratamentoSementes}
              </div>

              <div
                className={styles.background}
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  borderBottom: "1px solid black",
                }}
              >
                <span>
                  Observações (Dados relevantes para diagnóstico da doença e/ou
                  patógeno)
                </span>
              </div>

              <div
                style={{
                  padding: 5,
                  borderBottom: "1px solid black",
                  textAlign: "justify",
                  minHeight: "12vh",
                }}
              >
                {termo?.observacao}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}
