import { IArmadilha } from "@interfaces/IArmadilha";
import { apiService } from "@services/api";
import { armadilhaAction } from "@store/actions/armadilhaAction";
import { getArmadilha } from "@store/feature/armadilhaSlice";
import { useDispatch, useSelector } from "react-redux";

export const useArmadilha = () => {
  const appDispatch = useDispatch();
  const listaArmadilhasSincronizadas = useSelector(getArmadilha);
  const { addArmadilha, removeArmadilha } = armadilhaAction;

  const getArmadilhaByMunicipio = async (municipioId?: number) => {
    if (!municipioId) return [];

    if (navigator.onLine) {
      try {
        const arms = await apiService.armadilha(municipioId);

        return arms;
      } catch (error) {
        console.log(error);
        return [];
      }
    } else {
      const armadilhas = listaArmadilhasSincronizadas.find(
        (item) => item.municipioId === municipioId
      );

      return armadilhas?.armadilhas ?? [];
    }
  };

  const adicionarArmadilhas = (
    armadilhas: IArmadilha[],
    municipioId: number,
    dateTimeCreate: number
  ) => {
    appDispatch(addArmadilha(armadilhas, municipioId, dateTimeCreate));
  };

  const removerArmadilhaByMunicipio = (municipioId: number) => {
    appDispatch(removeArmadilha(municipioId));
  };

  return {
    listaArmadilhasSincronizadas,
    getArmadilhaByMunicipio,
    adicionarArmadilhas,
    removerArmadilhaByMunicipio,
  };
};
