import { ITabelaContaminantesInformados } from "@interfaces/ITabelaContaminantesInformados";
import { Form, Input, InputRef } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { TabelaDOSNContext } from "../TabelaDOSNContext";
import styles from "./style.module.css";

interface EdicaoCelulaTabelaDOSNProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof ITabelaContaminantesInformados;
  record: ITabelaContaminantesInformados;
  handleSave: (record: any) => void;
}

export const EdicaoCelulaTabelaDOSN: React.FC<EdicaoCelulaTabelaDOSNProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(TabelaDOSNContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();

      if (form.getFieldValue(dataIndex) === "0") {
        form.setFieldValue(dataIndex, "");
      }
    }

    //eslint-disable-next-line
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();

      if (!values[dataIndex]) {
        handleSave({ ...record, [dataIndex]: "0" });
        return;
      }

      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          style={{ minWidth: 50 }}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          maxLength={5}
          onChange={(e) => {
            const { value } = e.target;

            form.setFieldValue(dataIndex, value.replace(/[^0-9,]/g, ""));
          }}
        />
      </Form.Item>
    ) : (
      <div
        className={styles["editable-cell-value-wrap"]}
        onClick={toggleEdit}
        style={{ minWidth: 50 }}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
