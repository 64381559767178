import { readerBlobToFileReader } from "@functions/readerBlobToFileReader";
import { apiService } from "@services/api";
import { getTIFs } from "@store/feature/tifSlice";
import { useSelector } from "react-redux";

export const useTif = () => {
  const listaTifs = useSelector(getTIFs);

  const getAnexo = async (
    dadosAnexos: {
      nomeUpload: string;
      nomeArquivo: string;
    }[]
  ) => {
    let dataUrls: Blob[] = [];
    let response: any[] = [];

    await Promise.allSettled(
      dadosAnexos.map((anexo) => apiService.tif.getAnexo(anexo.nomeUpload))
    ).then((data) => {
      data.forEach((infoAnexo) => {
        if (infoAnexo.status === "fulfilled") {
          dataUrls.push(infoAnexo.value.data);
        }
      });
    });

    await Promise.allSettled(
      dataUrls.map((data) => readerBlobToFileReader(data))
    ).then((res) => {
      res.forEach((infoAnexo, ind) => {
        if (infoAnexo.status === "fulfilled") {
          // console.log(infoAnexo.value.result);
          response.push({
            ...dadosAnexos[ind],
            arquivo: infoAnexo.value.result,
          });
        }
      });
    });

    return response;
  };

  const getTifByNumero = async (numero: string) => {
    if (navigator.onLine) {
      const tif = listaTifs.find((tif) => tif.numero === numero);

      if (tif) {
        return tif;
      }

      const params = new URLSearchParams();
      params.append("numero", numero);

      try {
        const responseTif = await apiService.tif.getByNumero(params.toString());
        return responseTif ?? null;
      } catch (error) {
        return null;
      }
    } else {
      const tif = listaTifs.find((tif) => tif.numero === numero);

      return tif ?? null;
    }
  };

  return { getAnexo, getTifByNumero };
};
