import { openNotification } from "@components/Notifications";
import { useServidores } from "@hooks/useServidores";
import { apiService } from "@services/api";
import { Form, FormInstance, Input, Typography } from "antd";
import { Fragment, useState } from "react";

interface IServidorVistoriaProps {
  setIsServidorSelected: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance;
}

export function ServidorVistoria({
  form,
  setIsServidorSelected,
}: IServidorVistoriaProps) {
  const { getServidorPorMatricula } = useServidores();

  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (value: string) => {
    value = value.replace(/\D/g, "");
    //validdar se o documento esta valido
    if (!value) {
      openNotification({
        type: "error",
        message: "Informe a Matrícula do servidor",
      });
      setIsServidorSelected(false);
      return;
    }

    const servidorOff = getServidorPorMatricula(value);

    if (servidorOff) {
      form.setFieldValue("nomeServidor", servidorOff.pessoa.nome);
      openNotification({
        type: "success",
        message: "Servidor encontrado com sucesso!",
      });
      setIsServidorSelected(true);
      return;
    }

    if (navigator.onLine) {
      setIsLoading(true);
      const servidor = await apiService.servidores.byMatricula(value);
      setIsLoading(false);
      if (servidor) {
        form.setFieldValue("nomeServidor", servidor.pessoa.nome);
        openNotification({
          type: "success",
          message: "Servidor encontrado com sucesso!",
        });
        setIsServidorSelected(true);
      }
    }

    openNotification({
      type: "error",
      message: "Erro ao buscar Servidor",
      description: "Servidor não encontrado.",
    });
    setIsServidorSelected(false);
    //setIsServidorCadastrado(false);
    return;
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: matricula } = e.target;
    const servidorOff = getServidorPorMatricula(matricula);

    form.setFieldValue("matriculaServidor", matricula.replace(/\D/g, ""));

    if (servidorOff) {
      form.setFieldValue("nomeServidor", servidorOff.pessoa.nome);
      return;
    }

    if (navigator.onLine) {
      setIsLoading(true);
      const servidorOnline = await apiService.servidores.byMatricula(matricula);
      setIsLoading(false);

      if (servidorOnline) {
        form.setFieldValue("nomeServidor", servidorOnline.pessoa.nome);
      } else {
        form.setFieldValue("nomeServidor", "");
        setIsServidorSelected(false);
      }

      if (form.getFieldValue("nomeServidor")) return;
    }

    form.setFieldValue("nomeServidor", "");
    setIsServidorSelected(false);
    return;
  };
  return (
    <Fragment>
      <Typography.Title level={3}>Servidor</Typography.Title>

      <Form.Item
        name="matriculaServidor"
        label="Matrícula"
        labelCol={{ flex: "100px", span: 20 }}
        rules={[
          {
            required: true,
            message: "Digite a Matricula do Servidor",
          },
        ]}
      >
        <Input.Search
          maxLength={18}
          type="primary"
          enterButton="Buscar"
          onChange={handleChange}
          onSearch={handleSearch}
          loading={isLoading}
        />
      </Form.Item>

      <Form.Item
        name="nomeServidor"
        label="Nome"
        rules={[{ required: true }]}
        labelCol={{ flex: "100px", span: 20 }}
      >
        <Input disabled={true} />
      </Form.Item>
    </Fragment>
  );
}
