import { LoadingOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { checkDevice } from "@functions/checkDevice";
import { apiService } from "@services/api";
import { TitleTermo } from "@styles/TitleTermo";
import {
  Checkbox,
  Divider,
  Form,
  List,
  message,
  Pagination,
  Spin,
  Typography,
} from "antd";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useEffect, useState } from "react";
import { useActionsTif } from "../hooks/useActionsTif";
import { ButtonEnviarBackupTIF } from "./components/ButtonEnviarBackupTIF";
import { FiltroTIF } from "./components/FiltroTIF";
import { ItemListaFiltroTIF } from "./components/ItemListaFiltroTIF";
import { ItemListaTIF } from "./components/ItemListaTIF";
import { TIF_OPTIONS_FILTER } from "./constants";
import styles from "./style.module.css";

const PAGE_SIZE = 20;

export function ListagemTIF() {
  const [formFiltro] = Form.useForm();
  const { removerFiltro, aplicarFiltro, listaTermoFiltro, listaTif } =
    useActionsTif();

  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(PAGE_SIZE);
  const [pageControl, setPageControl] = useState(1);
  const [loading, setLoading] = useState(false);
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
  const [optionsFiltroSelected, setOptionsFiltroSelected] = useState<
    CheckboxOptionType[]
  >([]);

  //MUDAR PAGINA DA LISTA DE TIFS (COM E SEM FILTRO APLICADO)
  const handleChangePagination = async (page: number, filtro?: boolean) => {
    const keyMessage = "tif-pagination";
    if (filtro && !navigator.onLine) return;

    // localStorage.setItem("TIF:pageSelect", page.toString());
    setPageControl(page);

    if (filtro) {
      const params = new URLSearchParams(listaTermoFiltro[0].params);
      params.append("page", (page - 1).toString());
      params.append("size", PAGE_SIZE.toString());

      setLoading(true);
      message.loading({
        key: keyMessage,
        content: "Carregando termos...",
        duration: 0,
      });

      await apiService.tif
        .listar(params.toString())
        .then((data) => {
          aplicarFiltro([
            {
              ...listaTermoFiltro[0],
              formularios: data.content,
            },
          ]);
        })
        .catch((erro) => console.log(erro));

      setLoading(false);
      message.destroy(keyMessage);
      return;
    }

    setMinIndex((page - 1) * PAGE_SIZE);
    setMaxIndex(page * PAGE_SIZE);
  };

  //MUDAR LISTA DE TIFS DE ACORDO COM FILTRO SELECIONADO
  const handleChangeFilterSelect = async (values: CheckboxValueType[]) => {
    const keyMessage = "tif-reload-filter";
    const params = new URLSearchParams(listaTermoFiltro[0].params);
    const newParams = new URLSearchParams();

    formFiltro.resetFields();

    if (values.length === 0) {
      removerFiltro();
      openNotification({
        type: "info",
        message: "Filtro Removido",
      });

      handleChangePagination(1);

      return;
    }

    values.forEach((value: any) => {
      newParams.append(value, params.get(value)?.toString() || "");
      formFiltro.setFieldValue(value, params.get(value)?.toString());
    });

    newParams.append("size", PAGE_SIZE.toString());

    setCheckedList(values);
    setLoading(true);
    message.loading({
      key: keyMessage,
      content: "Carregando termos...",
      duration: 0,
    });

    await apiService.tif
      .listar(newParams.toString())
      .then((data) => {
        aplicarFiltro([
          {
            filtros: values,
            params: newParams.toString(),
            formularios: data.content,
            numberPages: data.totalPages,
          },
        ]);

        setPageControl(1);
        message.destroy(keyMessage);
        setLoading(false);
        openNotification({
          type: "success",
          message: `Filtro Atualizado`,
        });
      })
      .catch((erro) => {
        console.log(erro);
        message.destroy(keyMessage);
        setLoading(false);
        openNotification({
          type: "error",
          message: `Não foi possivel buscar TIF(s).`,
          description: erro.response.data.detail,
        });
        // window.location.reload();
      });
  };

  //USE EFFECTS
  useEffect(() => {
    let pageSelected = pageControl;

    if (localStorage.getItem("TIF:pageSelect")) {
      pageSelected = parseInt(localStorage.getItem("TIF:pageSelect") || "");

      localStorage.removeItem("TIF:pageSelect");
    }
    handleChangePagination(pageSelected, listaTermoFiltro.length > 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (listaTermoFiltro.length > 0) {
      setCheckedList(listaTermoFiltro[0].filtros);

      const optionsFiltro = TIF_OPTIONS_FILTER.filter((option) => {
        if (listaTermoFiltro[0].filtros.includes(option.value)) {
          return option;
        }

        return null;
      });

      setOptionsFiltroSelected(optionsFiltro);
    }

    // eslint-disable-next-line
  }, [listaTermoFiltro]);

  return (
    <Fragment>
      <TitleTermo>TIF(s)</TitleTermo>

      <div className={styles["container-button-listagem"]}>
        <FiltroTIF formFiltro={formFiltro} setPageControl={setPageControl} />
        <ButtonEnviarBackupTIF />
      </div>

      <Divider />
      {listaTermoFiltro.length > 0 ? (
        <>
          <div className={styles["container-filtro"]}>
            <Typography>Filtro Aplicado:</Typography>
            <Checkbox.Group
              onChange={handleChangeFilterSelect}
              options={optionsFiltroSelected}
              value={checkedList}
              disabled={loading}
            />
          </div>
          {loading ? (
            <div className={styles["container-loading"]}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
              />
            </div>
          ) : (
            <div className={styles["heigth-list"]}>
              <List
                style={{
                  boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                }}
                bordered
                dataSource={listaTermoFiltro[0].formularios}
                renderItem={(item, ind) => {
                  return (
                    <ItemListaFiltroTIF
                      key={ind}
                      tif={item}
                      pageControl={pageControl}
                    />
                  );
                }}
              />
            </div>
          )}
          <Pagination
            size={checkDevice() ? "small" : "default"}
            showSizeChanger={false}
            current={pageControl}
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
            pageSize={PAGE_SIZE}
            total={listaTermoFiltro[0].numberPages * PAGE_SIZE}
            onChange={(page: number) => handleChangePagination(page, true)}
          />
        </>
      ) : (
        <>
          <div className={styles["heigth-list"]}>
            <List
              style={{
                boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
              }}
              bordered
              dataSource={listaTif.slice(minIndex, maxIndex)}
              renderItem={(item, ind) => {
                return (
                  <ItemListaTIF
                    key={ind}
                    tif={item}
                    pageControl={pageControl}
                  />
                );
              }}
            />
          </div>

          <Pagination
            showSizeChanger={false}
            current={pageControl}
            style={{
              paddingTop: 20,
              display: "flex",
              justifyContent: "end",
            }}
            pageSize={PAGE_SIZE}
            total={listaTif.length}
            onChange={(page) => handleChangePagination(page)}
            size={checkDevice() ? "small" : "default"}
          />
        </>
      )}
    </Fragment>
  );
}
