import { LoadingOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { IRecebimentoAmostraLasgal } from "@interfaces/IRecebimentoAmostraLasgal";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { TitleTermo } from "@styles/TitleTermo";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Result,
  Spin,
} from "antd";
import moment from "moment";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./style.module.css";

export function NovoRecebimentoLasgal() {
  const { amostraId } = useParams();
  const navigate = useNavigate();
  const [formLasgal] = Form.useForm();
  const [hasNumAmostra, setHasNumAmostra] = useState(false);

  const { data, status } = useQuery({
    queryKey: "TESTES_ANALISE",
    queryFn: () => apiService.testesAnalise(),
  });

  const handleChangeAmostra = (e: RadioChangeEvent) => {
    const { value } = e.target;

    if (value === "SIM") {
      setHasNumAmostra(true);
    } else {
      setHasNumAmostra(false);
    }
  };

  const handleChangeNumAmostra = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{4})(\d)/, "$1/$2");
    formLasgal.setFieldValue("numAmostra", value);
  };

  const handleSubmit = async (values: any) => {
    const formValues: IRecebimentoAmostraLasgal = {
      dataRecebimentoAmostra: `${moment(values.dataRecebimento).format(
        "YYYY-MM-DD[T]HH:mm:ss"
      )}Z`,
      finalidadeAmostra: values.finalidadeAmostra || "",
      numeroAmostra: values.numAmostra || "",
      testesAnalises: values.testesAnalises || [],
    };

    // console.log(formValues);

    if (navigator.onLine) {
      await apiService.amostraSemente
        .receberAmostraLasgal(parseInt(amostraId || ""), formValues)
        .then((data) => {
          openNotification({
            type: "success",
            message: "Recebimento de amostra cadastrado com sucesso!",
          });

          handleReset();
        })
        .catch((erro) => {
          openNotification({
            type: "error",
            message: "Não foi possivel salvar recebimento da amostra",
            description: `${erro.response.data.detail}`,
          });
        });
    }
  };

  const handleReset = () => {
    formLasgal.resetFields();
    navigate("/LASGAL/listagem");
  };

  const handleErrorSubmit = () => {
    openNotification({
      type: "error",
      message: "Campos em branco",
      description: "Termine de preencher todos os campos obrigatórios",
    });
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  if (status === "error") {
    return (
      <>
        <TitleTermo>Novo Recebimento de amostras</TitleTermo>
        <Divider />
        <Result
          status="error"
          title="Erro ao carregar lista de testes"
          subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
        />
      </>
    );
  } else if (status === "loading" || status === "idle") {
    return (
      <>
        <TitleTermo>Novo Recebimento de amostras</TitleTermo>
        <Divider />
        <div className={styles["container-loading"]}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <TitleTermo>Novo Recebimento de amostras</TitleTermo>

        <Divider />

        <Form
          id="form-lasgal"
          form={formLasgal}
          labelWrap
          labelAlign="left"
          labelCol={{ flex: "200px", span: 20 }}
          wrapperCol={{ flex: 1, span: 14 }}
          style={{ minHeight: 600 }}
          onFinish={handleSubmit}
          onFinishFailed={handleErrorSubmit}
          onReset={handleReset}
        >
          <Form.Item label="Data de recebimento" name="dataRecebimento">
            <DatePicker
              format={"DD/MM/YYYY"}
              disabledDate={(date) => {
                if (date.valueOf() >= Date.now()) {
                  return true;
                }
                return false;
              }}
            />
          </Form.Item>

          <Form.Item
            label="Deseja informar o Nº da amostra?"
            name="hasNumAmostra"
          >
            <Radio.Group onChange={handleChangeAmostra}>
              <Radio value="SIM">Sim</Radio>
              <Radio value="NAO">Não</Radio>
            </Radio.Group>
          </Form.Item>

          {hasNumAmostra && (
            <Form.Item
              label="Nº da amostra"
              name="numAmostra"
              rules={[{ required: true }]}
            >
              <Input maxLength={9} onChange={handleChangeNumAmostra} />
            </Form.Item>
          )}

          <Form.Item label="Tipo" name="finalidadeAmostra">
            <Radio.Group>
              <Radio value="ANALISE">Análise</Radio>
              <Radio value="RE_ANALISE">Re-análise</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Análises a serem realizadas"
            name="testesAnalises"
            labelCol={{ span: 3 }}
            rules={[{ required: true }]}
          >
            <Checkbox.Group
              style={{
                display: "flex",
                gap: 20,
                flexWrap: "wrap",
              }}
            >
              {data.map((opt, ind) => {
                return (
                  <Fragment key={ind}>
                    <Checkbox value={opt.id}>{opt.nome}</Checkbox>

                    {ind % 2 === 0 && <br />}
                  </Fragment>
                );
              })}
            </Checkbox.Group>
          </Form.Item>
        </Form>

        <ButtonFooterContainer>
          <Button htmlType="reset" form="form-lasgal">
            Cancelar
          </Button>

          <Button htmlType="submit" form="form-lasgal" type="primary">
            Salvar
          </Button>
        </ButtonFooterContainer>
      </>
    );
  }
}
