import { LoadingOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { formatarData } from "@functions/formatarData";
import { useForms } from "@hooks/useForms";
import { IBoletimAmostra } from "@interfaces/IBoletimAmostra";
import { ITabelaContaminantesInformados } from "@interfaces/ITabelaContaminantesInformados";
import { ITabelaTesteLasgalPureza } from "@interfaces/ITabelaTesteLasgalPureza";
import { apiService } from "@services/api";
import { getMenuSelectAnaliseLasgal } from "@store/feature/menuSlice";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import { transformStringToNumber } from "@utils/transformStringToNumber";
import { Button, Divider, Form, Modal, Result, Spin } from "antd";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonConfirmaGerminacao } from "./components/ButtonConfirmaGerminacao";
import { ButtonConfirmaNocivas } from "./components/ButtonConfirmaNocivas";
import { ButtonConfirmaOutraCultivadaSilvestre } from "./components/ButtonConfirmaOutraCultivadaSilvestre";
import { ButtonConfirmaOutrasCultivares } from "./components/ButtonConfirmaOutrasCultivares";
import { ButtonConfirmaPureza } from "./components/ButtonConfirmaPureza";
import { DOSN } from "./components/DOSN";
import { FormDadosAmostraLASGAL } from "./components/FormDadosAmostraLASGAL";
import { Germinacao } from "./components/Germinacao";
import { MenuTesteLASGAL } from "./components/MenuTesteLASGAL";
import { ModalAssinaturaBoletimLASGAL } from "./components/ModalAssinaturaBoletimLASGAL";
import Pureza from "./components/Pureza";
import { TabelaTesteLasgal } from "./components/TabelaAnaliseTesteLasgal";
import {
  INITIAL_VALUE_GERMINACAO,
  INITIAL_VALUE_TESTES,
  VALOR_PADRAO,
} from "./constants";
import styles from "./style.module.css";

export function AnaliseTesteLasgal() {
  const { amostraId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formGerminacao] = Form.useForm();
  const [formPureza] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const menuSelected = useSelector(getMenuSelectAnaliseLasgal);
  const { gerarNumeroTermo } = useForms();
  const [hasPureza, setHasPureza] = useState(true);
  const [hasOutraCultivares, setHasOutraCultivares] = useState(true);
  const [hasGerminacao, setHasGerminacao] = useState(true);
  const [hasNocivas, setHasNocivas] = useState(true);
  const [hasOutraCultivadaSilvestre, setHasOutraCultivadaSilvestre] =
    useState(true);
  const [boletimIdCreated, setBoletimIdCreated] = useState<number>();
  const [dataTable, setDataTable] = useState(INITIAL_VALUE_TESTES);
  const [dataPureza, setDataPureza] = useState<ITabelaTesteLasgalPureza[]>([]);
  const [dataGerminacao, setDataGerminacao] = useState([
    INITIAL_VALUE_GERMINACAO,
  ]);
  const [listaContaminantes, setListaContaminantes] = useState<
    ITabelaContaminantesInformados[]
  >([]);
  const [roloSelected, setRoloSelected] = useState("");

  const { data: amostra, status } = useQuery({
    queryKey: ["AMOSTRA"],
    queryFn: () => apiService.amostraSemente.byId(parseInt(amostraId || "")),
  });

  const listaMaterialInerte = useQuery({
    queryKey: ["LISTA_MATERIAL_INERTE"],
    queryFn: () => apiService.materialInerteBoletim(),
  });

  const handleBack = () => {
    navigate("/LASGAL/listagem");
  };

  const abrirModalSalvarBoletim = async () => {
    //FAZER VALIDACOES AQUI
    const itensTabela = Object.entries(dataTable[1]).filter(
      (item) => item[0] !== "key"
    );

    const isNotTabelaCompleta = itensTabela.some((item) => !item[1]);

    if (isNotTabelaCompleta) {
      openNotification({
        type: "error",
        message: "Não foi possivel salvar Boletim.",
        description: "Favor, informar todos os itens da tabela",
      });
      return;
    }

    const boletim = getBoletim();

    if (boletim) {
      try {
        const boletimResponse = await apiService.boletimAmostra.enviar(boletim);
        // console.log(boletimResponse);

        openNotification({
          type: "success",
          message: "Boletim informado com sucesso!",
        });

        //ABRIR MODAL
        modal.confirm({
          title: "Deseja assinar Boletim?",
          cancelText: "Não",
          okText: "Sim",
          onOk: () => {
            setBoletimIdCreated(boletimResponse.data.id);
          },
          onCancel: () => {
            handleBack();
          },
        });
      } catch (error: any) {
        openNotification({
          type: "error",
          message: "Não foi possivel salvar Boletim.",
          description: error.response.data.detail,
        });
        console.log(error);
      }
    }
  };

  const getBoletim = () => {
    if (!amostra) return;

    const dataTableValueSchemaRequest = Object.entries(dataTable[1]).map(
      (item) => {
        if (item[1] === VALOR_PADRAO) return [item[0], "0"];

        return item;
      }
    );

    const map = Object.fromEntries(dataTableValueSchemaRequest);

    const contaminanteBoletim = listaContaminantes.map((item) => {
      return {
        contaminante: item.dadosContaminante,
        quantidade: transformStringToNumber(item.quantidade),
      };
    });

    // const numeroBoletim = gerarNumeroTermo(0);

    const boletim: IBoletimAmostra = {
      numero: "",
      amostra: amostra,
      amostraContaminantes: contaminanteBoletim,
      anormais: transformStringToNumber(map.anormais),
      dormentes: transformStringToNumber(map.dormentes),
      normais: transformStringToNumber(map.normais),
      mortas: transformStringToNumber(map.mortas),
      duras: transformStringToNumber(map.duras),
      pureza: transformStringToNumber(map.pureza),
      outrasCultivadas: transformStringToNumber(map.outraCultivadas),
      outrasCultivares: transformStringToNumber(map.outraCultivares),
      proibidas: transformStringToNumber(map.proibidas),
      materialInertePureza: transformStringToNumber(map.materialInerte),
      vigna: transformStringToNumber(map.vigna),
      vazio: transformStringToNumber(map.vazio),
      pms: transformStringToNumber(map.pms),
      silvestres: transformStringToNumber(map.silvestres),
      tetrazolio: transformStringToNumber(map.tetrazolio),
      toleradas: transformStringToNumber(map.toleradas),
      outrasSementesBoletimAmostra: transformStringToNumber(map.outraSementes),
      assinaturaServidor: "",
      balancaUsada:
        dataPureza.length > 0 ? dataPureza[0]?.balancaUsada || 0 : 0,
      dataTestePureza:
        dataPureza.length > 0
          ? moment(formatarData(dataPureza[0].data)).format(
              "YYYY-MM-DDTHH:mm:ss[Z]"
            )
          : moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      materiaisInertes:
        dataPureza.length > 0
          ? listaMaterialInerte.data?.filter((material) =>
              dataPureza[0].listaMaterialInerte.includes(material.id)
            ) || []
          : [],
      materialInerteBoletimAmostra:
        dataPureza.length > 0
          ? transformStringToNumber(dataPureza[0]?.materialInerte)
          : 0,
      statusAssinaturaServidor: "ASSINADO_DIGITALMENTE",
      outrasSementesPureza:
        dataPureza.length > 0
          ? transformStringToNumber(dataPureza[0].outraSementes) || 0
          : 0,
      sementePura:
        dataPureza.length > 0
          ? transformStringToNumber(dataPureza[0]?.pureza)
          : 0,
      rolo2: roloSelected === "rolo2" ? roloSelected : "",
      rolo4: roloSelected === "rolo4" ? roloSelected : "",
      rolo8: roloSelected === "rolo8" ? roloSelected : "",
      rolo16: roloSelected === "rolo16" ? roloSelected : "",
      tratamentoEspecial:
        formGerminacao.getFieldValue("tratamentoEspecial") || "",
      temperatura: formGerminacao.getFieldValue("temperatura") || "",
      germinador: formGerminacao.getFieldValue("germinador") || "",
      observacao: formGerminacao.getFieldValue("observacao") || "",
      substrato: formGerminacao.getFieldValue("substrato") || "",
      dataInicialTesteGerminacao: formGerminacao.getFieldValue("inicioTeste")
        ? `${formGerminacao
            .getFieldValue("inicioTeste")
            .format()
            .slice(0, 19)}Z`
        : "",
      dataFinalTesteGerminacao: formGerminacao.getFieldValue("conclusaoTeste")
        ? `${formGerminacao
            .getFieldValue("conclusaoTeste")
            .format()
            .slice(0, 19)}Z`
        : "",
      dataGerminacao:
        dataGerminacao.length > 0
          ? moment(formatarData(dataGerminacao[0].data)).format(
              "YYYY-MM-DDTHH:mm:ss[Z]"
            )
          : moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      validade: "OK",
      pesoInicial: form.getFieldValue("pesoInicial") || 0,
      dataEntrada: amostra.dataRecebimentoAmostraLasgal,
      operador: dataGerminacao[0].operador,
    };

    // console.log(boletim);

    return boletim;
  };

  if (status === "error") {
    return (
      <Result
        status="error"
        title="Erro ao buscar dados da amostra"
        subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
      />
    );
  } else if (status === "loading" || status === "idle") {
    return (
      <div className={styles["container-loading"]}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  } else {
    return (
      <>
        {contextHolder}
        <FormDadosAmostraLASGAL
          form={form}
          amostra={amostra}
          formPureza={formPureza}
          dataTable={dataTable}
          setDataTable={setDataTable}
          mudarStatusOutrasCultivares={(value) => setHasOutraCultivares(value)}
        />

        <div className={styles["container-button"]}>
          <ButtonConfirmaPureza
            dataTable={dataTable}
            formPureza={formPureza}
            hasPureza={hasPureza}
            resetarTabelaPureza={() => setDataPureza([])}
            setDataTable={setDataTable}
            setHasPureza={setHasPureza}
          />

          <ButtonConfirmaOutrasCultivares
            dataTable={dataTable}
            hasOutrasCultivares={hasOutraCultivares}
            armazenarDadosOutrasCultivares={(value) => {
              setDataTable(value);
              setHasOutraCultivares(!hasOutraCultivares);
              form.setFieldValue("outraCultivares", "");
            }}
          />

          <ButtonConfirmaGerminacao
            dataTable={dataTable}
            hasGerminacao={hasGerminacao}
            armazenarDadosGerminacao={(value) => {
              setDataTable(value);
              setDataGerminacao([INITIAL_VALUE_GERMINACAO]);
              setHasGerminacao(!hasGerminacao);
            }}
          />

          <ButtonConfirmaOutraCultivadaSilvestre
            dataTable={dataTable}
            hasOutrasCultivadaSilvestre={hasOutraCultivadaSilvestre}
            listaContaminanteSelecionados={listaContaminantes}
            armazenarDados={(dadosTabela, dadosContaminante) => {
              setListaContaminantes(dadosContaminante);
              setDataTable(dadosTabela);
              setHasOutraCultivadaSilvestre(!hasOutraCultivadaSilvestre);
            }}
          />

          <ButtonConfirmaNocivas
            dataTable={dataTable}
            hasNocivas={hasNocivas}
            listaContaminanteSelecionados={listaContaminantes}
            armazenarDados={(dadosTabela, contaminantes) => {
              setListaContaminantes(contaminantes);
              setDataTable(dadosTabela);
              setHasNocivas(!hasNocivas);
            }}
          />
        </div>

        <TabelaTesteLasgal
          dataSource={dataTable}
          setDataSource={setDataTable}
          hasPureza={hasPureza}
          hasOutraCultivares={hasOutraCultivares}
          hasOutraCultivadaSilvestre={hasOutraCultivadaSilvestre}
          hasNocivas={hasNocivas}
          hasGerminacao={hasGerminacao}
        />

        <MenuTesteLASGAL />

        <div style={{ minHeight: "70vh" }}>
          {menuSelected[0] === "PUREZA" && (
            <Pureza
              setDataTable={setDataTable}
              setHasPureza={setHasPureza}
              dataTable={dataTable}
              dataPureza={dataPureza}
              setDataPureza={setDataPureza}
              form={formPureza}
            />
          )}

          {menuSelected[0] === "GERMINACAO" && (
            <Germinacao
              setDataTable={setDataTable}
              dataTable={dataTable}
              form={formGerminacao}
              dataSource={dataGerminacao}
              setDataSource={setDataGerminacao}
              roloSelected={roloSelected}
              setRoloSelected={setRoloSelected}
            />
          )}

          {menuSelected[0] === "DOSN" && (
            <DOSN
              contaminantesInformados={listaContaminantes}
              setContaminantesInformados={setListaContaminantes}
              setDataTable={setDataTable}
              dataTable={dataTable}
            />
          )}
        </div>
        <Divider />
        <ButtonFooterContainer style={{ marginTop: 20 }}>
          <Button onClick={handleBack}>Voltar</Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={abrirModalSalvarBoletim}
          >
            Salvar
          </Button>
        </ButtonFooterContainer>

        <ModalAssinaturaBoletimLASGAL
          isOpen={!!boletimIdCreated}
          onClose={() => {
            setBoletimIdCreated(undefined);
            handleBack();
          }}
          boletimId={boletimIdCreated}
        />
      </>
    );
  }
}
